import {Box, Typography} from "@mui/material"
import React from "react"
import {AppButton} from "@app-core/AppButton"
import AlertVectorImage from "assets/images/alertvector.svg"

interface EligibilityDeniedProps {
  onOkClick: () => void
}

export const EligibilityDenied: React.FC<EligibilityDeniedProps> = (
  props: EligibilityDeniedProps
) => {
  const {onOkClick} = props

  return (
    <Box minHeight="600px" padding={4} display={"flex"} alignItems="center">
      <Box>
        <Box
          marginBottom={3}
          display={"flex"}
          alignItems="center"
          justifyContent={"center"}
        >
          <Box>
            <img src={AlertVectorImage} alt="Not Eligible" />
          </Box>
        </Box>
        <Box paddingX={6}>
          <Typography
            fontWeight="bold"
            variant="h4"
            gutterBottom
            component="div"
            fontSize="24px"
          >
            “Thank you for completing the pre-screen questions. Based on your
            answers to the pre-screen questions, it is likely that you are not
            eligible for the DTC based on the Canada Revenue Agency criteria for
            eligibility. However, if you feel that your impairments do meet the
            criteria, please contact your medical practitioner for further
            consultation.”
          </Typography>
        </Box>
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent="center"
          marginTop={4}
          padding={4}
        >
          <AppButton
            onClick={onOkClick}
            sx={{
              backgroundColor: "#6A75B9",
              minWidth: "225px",
              minHeight: "44px"
            }}
            variant="contained"
          >
            Ok
          </AppButton>
        </Box>
      </Box>
    </Box>
  )
}

export default EligibilityDenied
