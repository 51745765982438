import ArrowLeftAltIcon from "@mui/icons-material/KeyboardBackspace"
import {Button} from "@mui/material"
import React from "react"
import {Link} from "react-router-dom"
import GuideVideoList from "./GuideVideoList"

export const GuidesView: React.FC = () => {
  const vids = [
    {
      name: "Welcome to Benefits2!",
      url: "https://www.youtube.com/watch?v=a7EFWMEHO1Q"
    },
    {
      name: "About Us",
      url: "https://www.youtube.com/watch?v=XdcYy8zNEIE"
    },
    {
      name: "No Doctor, No Problem!",
      url: "https://www.youtube.com/watch?v=JnqvXUQscPk"
    },
    {
      name: "Virtual Events and Seminars",
      url: "https://www.youtube.com/watch?v=CQFHlmPgZHo"
    },
    {
      name: "Interested In A Partnership?",
      url: "https://www.youtube.com/watch?v=O2gexilirXU"
    },
    {
      name: "What is an RDSP?",
      url: "https://www.youtube.com/watch?v=K96RfBZR3IM"
    }
  ]
  return (
    <div className="flex flex-col items-center p-24 sm:p-40">
      <div className="flex flex-col w-full max-w-4xl">
        <div className="sm:mt-32">
          <Button
            component={Link}
            to="/help-center"
            color="secondary"
            startIcon={<ArrowLeftAltIcon fontSize="small" color="secondary" />}
          >
            Back to Help Center
          </Button>
        </div>
        <div className="mt-8 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight">
          Guides & Resources
        </div>

        <div>
          <GuideVideoList guideVideos={vids} />
        </div>
      </div>
    </div>
  )
}

export default GuidesView
