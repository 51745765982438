import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import HelpIcon from "@mui/icons-material/Help"
import {AppButton} from "@app-core/AppButton"
import {
  Container,
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Divider,
  Stack,
  Tooltip,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText
} from "@mui/material"
import {YearDateRangePicker} from "components/YearDateRangePicker"
import Autocomplete from "@mui/material/Autocomplete"
import {canadaProvinces} from "data/constants/canadaProvinces"
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs"
import {relationships} from "data/constants/relationships"
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker"
import {useMessages} from "hooks/useMessages"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {IFlier} from "models/IFlier"
import dayjs from "dayjs"
import {IPatient} from "models/IPatient"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import React, {useState} from "react"

dayjs.extend(isSameOrAfter)
interface ApplicationIndividualFormProps {
  onNext: (patient: IPatient, flier: IFlier) => void
  patient: IPatient | undefined
  flier: IFlier | undefined
}

const defaultPatientInfo: IPatient = {
  firstName: "",
  lastName: "",
  sex: "",
  mailingAddress: "",
  city: "",
  province: "",
  postalCode: "",
  dateOfBirth: "",
  isPatientClaimingTax: false,
  personWithDisabilityLegalRepOrGuardian: true,
  adjustPreviousTaxReturn: false
}

const defaultFlier: IFlier = {
  isFamilyMember: false,
  firstName: "",
  lastName: "",
  relationship: "",
  disablePersonLivesWithMe: false,
  province: "",
  basicNecessities: {
    food: "",
    shelter: "",
    clothing: ""
  },
  supportDescription: ""
}

export const ApplicationIndividualForm: React.FC<
  ApplicationIndividualFormProps
> = (props: ApplicationIndividualFormProps) => {
  const {onNext, patient, flier} = props
  const {addMessage} = useMessages()
  const [patientErrorObj, setPatientErrorObj] = useState<{
    [key: string]: string
  }>({})
  const [flierErrorObj, setFlierErrorObj] = useState<{
    [key: string]: string
  }>({})

  const [patientInfo, setPatientInfo] = useState<IPatient>(
    patient || defaultPatientInfo
  )

  const [flierInfo, setFlierInfo] = useState<IFlier>(flier || defaultFlier)
  const [providedFood, setProvidedFood] = useState(
    flierInfo.basicNecessities.food !== ""
  )
  const [providedShelter, setProvidedShelter] = useState(false)
  const [providedClothing, setProvidedClothing] = useState(false)
  const onPatientInfoChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setPatientInfo({
        ...patientInfo,
        [field]: event.target.value
      })
    }

  const onPersonClaimingInfoChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setFlierInfo({
        ...flierInfo,
        [field]: event.target.value
      })
    }

  const onPatientDobChange = (newValue: Date | null) => {
    setPatientInfo({
      ...patientInfo,
      dateOfBirth: dayjs(newValue).format("YYYY-MM-DD")
    })
  }

  const onIsPatientClaimingTaxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPatientInfo({
      ...patientInfo,
      isPatientClaimingTax: event.target.checked
    })
  }

  const validatePatientInfo = (setErrors = true) => {
    const errorObj: {[key: string]: string} = {}
    let isValid = true

    if (!patientInfo.firstName || patientInfo.firstName.trim() === "") {
      errorObj.firstName = "Patient first name is required"
      isValid = false
    }

    if (!patientInfo.lastName || patientInfo.lastName.trim() === "") {
      errorObj.lastName = "Patient last name is required"
      isValid = false
    }

    if (!patientInfo.sex || patientInfo.sex.trim() === "") {
      errorObj.sex = "Patient sex is required"
      isValid = false
    }

    if (
      !patientInfo.mailingAddress ||
      patientInfo.mailingAddress.trim() === ""
    ) {
      errorObj.mailingAddress = "Patient mailing address is required"
      isValid = false
    }

    if (!patientInfo.city || patientInfo.city.trim() === "") {
      errorObj.city = "Patient city is required"
      isValid = false
    }

    if (!patientInfo.province || patientInfo.province.trim() === "") {
      errorObj.province = "Patient province is required"
      isValid = false
    }

    // ca postal code regex /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
    if (!patientInfo.postalCode || patientInfo.postalCode.trim() === "") {
      errorObj.postalCode = "Patient postal code is required"
      isValid = false
    }

    if (
      patientInfo.postalCode &&
      !patientInfo.postalCode.match(
        /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
      )
    ) {
      errorObj.postalCode = "Patient postal code format is not valid"
      isValid = false
    }
    if (!patientInfo.dateOfBirth || patientInfo.dateOfBirth.trim() === "") {
      errorObj.dateOfBirth = "Patient date of birth is required"
      isValid = false
    } else if (dayjs(patientInfo.dateOfBirth).isSameOrAfter(dayjs())) {
      errorObj.dateOfBirth =
        "Patient date of birth can not the same or after today's date"
      isValid = false
    }

    if (Object.keys(errorObj).length > 0 && setErrors) {
      setPatientErrorObj(errorObj)
    }

    return isValid
  }

  const validateFilerInfo = (setErrors = true) => {
    const errorObj: {[key: string]: string} = {}
    let isValid = true

    if (patientInfo.isPatientClaimingTax) {
      return isValid
    }

    if (!flierInfo.firstName || flierInfo.firstName.trim() === "") {
      errorObj.firstName = "Flier first name is required"
      isValid = false
    }

    if (!flierInfo.lastName || flierInfo.lastName.trim() === "") {
      errorObj.lastName = "Flier last name is required"
      isValid = false
    }

    if (!flierInfo.relationship || flierInfo.relationship.trim() === "") {
      errorObj.relationship = "Flier relationship is required"
      isValid = false
    }

    if (
      !flierInfo.supportDescription ||
      flierInfo.supportDescription.trim() === ""
    ) {
      errorObj.supportDescription = "Flier description of support is required"
      isValid = false
    }

    if (providedFood) {
      if (flierInfo.basicNecessities.food === "") {
        errorObj.providedFood =
          "Year range you provided food support is required"
        isValid = false
      }
    }

    if (Object.keys(errorObj).length > 0 && setErrors) {
      setFlierErrorObj(errorObj)
    }
    return isValid
  }

  const validate = (setErrors = true) => {
    return validatePatientInfo(setErrors) && validateFilerInfo(setErrors)
  }

  const save = () => {
    const isValid = validate()

    if (!isValid) {
      return
    }

    patientInfo.postalCode = patientInfo.postalCode
      .replaceAll("-", "")
      .replaceAll(" ", "")
      .trim()

    if (!providedFood) {
      flierInfo.basicNecessities.food = ""
    }

    if (!providedShelter) {
      flierInfo.basicNecessities.shelter = ""
    }

    if (!providedClothing) {
      flierInfo.basicNecessities.clothing = ""
    }

    onNext(patientInfo, flierInfo)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container maxWidth="md">
        <Box padding={4}>
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"center"}
            flexDirection="column"
            marginTop={4}
          >
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Tell us about the person with the disability
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      value={patientInfo.firstName}
                      onChange={onPatientInfoChange("firstName")}
                      error={patientErrorObj["firstName"] ? true : false}
                      helperText={patientErrorObj["firstName"] || undefined}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="lastName"
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      value={patientInfo.lastName}
                      onChange={onPatientInfoChange("lastName")}
                      error={patientErrorObj["lastName"] ? true : false}
                      helperText={patientErrorObj["lastName"] || undefined}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      error={patientErrorObj["sex"] ? true : false}
                    >
                      <InputLabel id="patient-sex-select-label">Sex</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={patientInfo.sex}
                        label="Sex"
                        onChange={(event) => {
                          setPatientInfo({
                            ...patientInfo,
                            sex: event.target.value
                          })
                        }}
                      >
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                      {patientErrorObj["sex"] && (
                        <FormHelperText>Without label</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="mailingAddress"
                      required
                      fullWidth
                      id="mailingAddress"
                      label="Mailing Address"
                      value={patientInfo.mailingAddress}
                      onChange={onPatientInfoChange("mailingAddress")}
                      error={patientErrorObj["mailingAddress"] ? true : false}
                      helperText={
                        patientErrorObj["mailingAddress"] || undefined
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="city"
                      required
                      fullWidth
                      id="city"
                      label="City"
                      value={patientInfo.city}
                      onChange={onPatientInfoChange("city")}
                      error={patientErrorObj["city"] ? true : false}
                      helperText={patientErrorObj["city"] || undefined}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      disablePortal
                      id="province"
                      options={canadaProvinces}
                      value={patientInfo.province || null}
                      onChange={(event: any, newValue: string | null) => {
                        setPatientInfo({
                          ...patientInfo,
                          province: newValue || ""
                        })
                      }}
                      openOnFocus={false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="province"
                          required
                          fullWidth
                          label="Province or territory"
                          error={patientErrorObj["province"] ? true : false}
                          helperText={patientErrorObj["province"] || undefined}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="postalCode"
                      required
                      fullWidth
                      id="postalCode"
                      label="Postal Code"
                      value={patientInfo.postalCode}
                      onChange={onPatientInfoChange("postalCode")}
                      error={patientErrorObj["postalCode"] ? true : false}
                      helperText={patientErrorObj["postalCode"] || undefined}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DesktopDatePicker
                      label="Date Of Birth"
                      inputFormat="MM/DD/YYYY"
                      value={patientInfo.dateOfBirth || null}
                      onChange={onPatientDobChange}
                      disableFuture
                      onError={(reason, value) => {
                        setPatientErrorObj({
                          ...patientErrorObj,
                          dateOfBirth: reason || ""
                        })
                      }}
                      renderInput={(params) => (
                        <TextField
                          id="dateOfBirth"
                          fullWidth
                          required
                          name="dateOfBirth"
                          error={patientErrorObj["dateOfBirth"] ? true : false}
                          helperText={
                            patientErrorObj["dateOfBirth"] || undefined
                          }
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true} sx={{width: "100%"}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                  Tell us about the person claiming the disability amount
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={patientInfo.isPatientClaimingTax}
                        onChange={onIsPatientClaimingTaxChange}
                      />
                    }
                    label={
                      <Box sx={{display: "flex", alignItems: "center"}}>
                        <Typography>
                          The person with the disability is claiming the
                          disability amount
                        </Typography>
                        <Tooltip title="If the person with the disability is NOT claiming the disability amount please remove the checkmark and fill out the required information.">
                          <IconButton>
                            <HelpIcon sx={{color: "deepskyblue"}} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    }
                  />
                </FormGroup>
                <Divider sx={{marginBottom: "10px"}} />
                {!patientInfo.isPatientClaimingTax && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={flierInfo.isFamilyMember}
                              onChange={(e) => {
                                setFlierInfo({
                                  ...flierInfo,
                                  isFamilyMember: e.target.checked
                                })
                              }}
                            />
                          }
                          label="A supporting family member is claiming the disability amount (the spouse or common-law partner of the person with the disability,
                            or a parent, grandparent, child, grandchild, brother, sister, uncle, aunt, nephew, or niece of that person or their spouse or
                            common-law partner)."
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="firstName"
                        required
                        fullWidth
                        id="firstName"
                        label="First Name"
                        value={flierInfo.firstName}
                        onChange={onPersonClaimingInfoChange("firstName")}
                        error={flierErrorObj["firstName"] ? true : false}
                        helperText={flierErrorObj["firstName"] || undefined}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="lastName"
                        required
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        value={flierInfo.lastName}
                        onChange={onPersonClaimingInfoChange("lastName")}
                        error={flierErrorObj["lastName"] ? true : false}
                        helperText={flierErrorObj["lastName"] || undefined}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        disablePortal
                        id="relationship"
                        options={relationships}
                        value={flierInfo.relationship || null}
                        onChange={(event: any, newValue: string | null) => {
                          setFlierInfo({
                            ...flierInfo,
                            relationship: newValue || ""
                          })
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="relationship"
                            required
                            fullWidth
                            label="Relationship"
                            error={flierErrorObj["relationship"] ? true : false}
                            helperText={
                              flierErrorObj["relationship"] || undefined
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl>
                        <FormLabel id="disablePersonLivesWithMe-radio-buttons-group">
                          Does the person with the disability live with you?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="disablePersonLivesWithMe-radio-buttons-group"
                          name="disablePersonLivesWithMe-radio-buttons-group"
                          value={flierInfo.disablePersonLivesWithMe}
                          row
                          onChange={(e) => {
                            setFlierInfo({
                              ...flierInfo,
                              disablePersonLivesWithMe:
                                e.target.value === "true"
                            })
                          }}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        Indicate which of the basic necessities of life have
                        been regularly and consistently provided to the person
                        with the disability, and the years for which it was
                        provided:
                      </Typography>
                      <Stack
                        direction="column"
                        justifyContent="space-evenly"
                        alignItems="center"
                        spacing={2}
                        my={4}
                      >
                        <Box>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={providedFood}
                                  onChange={(e) => {
                                    setProvidedFood(e.target.checked)
                                  }}
                                />
                              }
                              label="Food"
                            />
                          </FormGroup>
                          <YearDateRangePicker
                            years={flierInfo.basicNecessities.food.toString()}
                            disabled={!providedFood}
                            onChange={(year) =>
                              setFlierInfo({
                                ...flierInfo,
                                basicNecessities: {
                                  ...flierInfo.basicNecessities,
                                  food: year
                                }
                              })
                            }
                            error={flierErrorObj["providedFood"] ? true : false}
                            errorText={
                              flierErrorObj["providedFood"] || undefined
                            }
                          />
                        </Box>
                        <Box>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={providedShelter}
                                  onChange={(e) => {
                                    setProvidedShelter(e.target.checked)
                                  }}
                                />
                              }
                              label="Shelter"
                            />
                          </FormGroup>
                          <YearDateRangePicker
                            years={flierInfo.basicNecessities.shelter.toString()}
                            disabled={!providedShelter}
                            onChange={(year) =>
                              setFlierInfo({
                                ...flierInfo,
                                basicNecessities: {
                                  ...flierInfo.basicNecessities,
                                  shelter: year
                                }
                              })
                            }
                            error={
                              flierErrorObj["providedShelter"] ? true : false
                            }
                            errorText={
                              flierErrorObj["providedShelter"] || undefined
                            }
                          />
                        </Box>
                        <Box>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={providedClothing}
                                  onChange={(e) => {
                                    setProvidedClothing(e.target.checked)
                                  }}
                                />
                              }
                              label="Clothing"
                            />
                          </FormGroup>
                          <YearDateRangePicker
                            years={flierInfo.basicNecessities.clothing.toString()}
                            disabled={!providedClothing}
                            onChange={(year) =>
                              setFlierInfo({
                                ...flierInfo,
                                basicNecessities: {
                                  ...flierInfo.basicNecessities,
                                  clothing: year
                                }
                              })
                            }
                            error={
                              flierErrorObj["providedClothing"] ? true : false
                            }
                            errorText={
                              flierErrorObj["providedClothing"] || undefined
                            }
                          />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        Provide details regarding the support you provide to the
                        person with the disability (regularity of the support,
                        proof of dependency, if the person lives with you,
                        etc.):
                      </Typography>
                      <TextField
                        name="supportDescription"
                        required
                        fullWidth
                        id="supportDescription"
                        label="Description"
                        rows={6}
                        value={flierInfo.supportDescription}
                        sx={{marginTop: "10px"}}
                        onChange={onPersonClaimingInfoChange(
                          "supportDescription"
                        )}
                        error={
                          flierErrorObj["supportDescription"] ? true : false
                        }
                        helperText={
                          flierErrorObj["supportDescription"] || undefined
                        }
                      />
                    </Grid>
                  </Grid>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography>Previous tax return adjustments</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel id="personWithDisabilityLegalRepOrGuardian-radio-buttons-group">
                      Are you the person with the disability or their legal
                      representative, or if the person is under 18, their legal
                      guardian?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="personWithDisabilityLegalRepOrGuardian-radio-buttons-group"
                      name="personWithDisabilityLegalRepOrGuardian-radio-buttons-group"
                      value={patientInfo.personWithDisabilityLegalRepOrGuardian}
                      row
                      onChange={(e) => {
                        setPatientInfo({
                          ...patientInfo,
                          personWithDisabilityLegalRepOrGuardian:
                            e.target.value === "true"
                        })
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  {patientInfo.personWithDisabilityLegalRepOrGuardian && (
                    <FormControl>
                      <FormLabel id="adjustPreviousTaxReturn-radio-buttons-group">
                        If eligibility for the disability tax credit is
                        approved, would you like the CRA to apply the credit to
                        your previous tax returns?
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="adjustPreviousTaxReturn-radio-buttons-group"
                        name="adjustPreviousTaxReturn-radio-buttons-group"
                        value={patientInfo.adjustPreviousTaxReturn}
                        onChange={(e) => {
                          setPatientInfo({
                            ...patientInfo,
                            adjustPreviousTaxReturn: e.target.value === "true"
                          })
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Yes, adjust my previous tax returns for all applicable years"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No, do not adjust my previous tax returns at this time"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent="flex-end"
          marginTop={4}
          marginBottom={4}
        >
          {/* <Button onClick={save}>Back</Button> */}
          <AppButton
            onClick={save}
            sx={{
              backgroundColor: "#6A75B9",
              minWidth: "150px",
              minHeight: "44px"
            }}
            variant="contained"
            // disabled={
            //   Object.keys(patientErrorObj).length > 0 ||
            //   Object.keys(flierErrorObj).length > 0
            // }
            disabled={!validate(false)}
          >
            Next
          </AppButton>
        </Box>
      </Container>
    </LocalizationProvider>
  )
}

export default ApplicationIndividualForm
