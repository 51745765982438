import {CardNumberElement} from "@stripe/react-stripe-js"
import React from "react"
import {StripeTextFieldProps, StripeTextField} from "./StripeTextField"

export const StripeTextFieldNumber = (
  props: StripeTextFieldProps<typeof CardNumberElement>
) => {
  return (
    <StripeTextField
      label="Credit Card Number"
      stripeElement={CardNumberElement}
      {...props}
    />
  )
}
