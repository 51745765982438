import ApiClient, {IApiClient} from "./ApiClient"
import {IProduct} from "models/IProduct"

const urlSubPath = "/products"
export default class ProductApi {
  private apiClient: IApiClient

  public constructor() {
    this.apiClient = new ApiClient(process.env.REACT_APP_API_BASE_URL || "")
  }

  public getProducts = async (): Promise<IProduct[]> => {
    const data = await this.apiClient.get<IProduct[]>(`${urlSubPath}`)
    return data
  }
}
