import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import PrintIcon from "@mui/icons-material/Print"
import {Container, Box, Typography, IconButton} from "@mui/material"
import React from "react"
import {AppButton} from "@app-core/AppButton"
import PayAccessImage from "assets/images/payaccess.svg"
import {useMessages} from "hooks/useMessages"

interface PaymentCompletedProps {
  code: string
  plan: string
  onCompleteSession: () => void
  onPrint: () => void
  onSignOut: () => void
}

export const PaymentCompleted: React.FC<PaymentCompletedProps> = (
  props: PaymentCompletedProps
) => {
  const {code, onCompleteSession, onPrint, plan, onSignOut} = props
  const {addMessage} = useMessages()

  const copyTextToClipboard = async () => {
    try {
      if ("clipboard" in navigator) {
        await navigator.clipboard.writeText(code)
      } else {
        const d = new window.Clipboard()
        d.writeText(code)
      }
      addMessage({
        content: "Code Copied!",
        severity: "success"
      })
    } catch {
      addMessage({
        content: "Error copying code",
        severity: "error"
      })
    }
  }

  const paymentCompleteMsg = () => {
    switch (plan) {
      case "AssistanceRequired":
        return "Payment was successful and an email will be sent to you shortly to schedule an appointment for a guided walkthrough."
      case "MedicalPractitionerAssist":
        return `Payment was successful and an email will be sent to you shortly to schedule an appointment with a medical practitioner.

 

        <b>Note:</b> An additional fee will be charged by the medical practitioner to walk you through the application and sign-off on the application.`

      default:
        return `Keep your code to re-complete the data or visit the doctor to
        obtain the service. Information on how to access your code at a
        later time will be emailed to you at the end of the application
        process.`
    }
  }

  return (
    <Container maxWidth="md">
      <Box padding={4}>
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"center"}
          flexDirection="column"
          marginTop={4}
        >
          <Box>
            <img src={PayAccessImage} alt={"Payment completed successfully"} />
          </Box>
          <Box>
            <Typography variant="h6" gutterBottom component="div">
              Payment completed successfully
            </Typography>
          </Box>
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"center"}
            flexDirection="column"
            width="100%"
            height="75px"
            sx={{background: "#EDEDED", borderRadius: "21px"}}
          >
            <Typography
              color="#6A75B9"
              fontSize="28px"
              variant="h6"
              component="div"
            >
              <Typography
                color="#191919"
                fontSize="28px"
                variant="h6"
                component="span"
              >
                {plan === "AssistanceRequired"
                  ? "Guided Walkthrough Code:"
                  : "Your Code:"}
              </Typography>{" "}
              {code}
              <IconButton
                sx={{padding: 0, marginLeft: "10px", top: "-2px"}}
                aria-label="copy code"
                onClick={copyTextToClipboard}
              >
                <ContentCopyIcon />
              </IconButton>
            </Typography>
          </Box>
          <Box marginTop="20px" marginBottom="20px">
            <Typography
              fontSize="14px"
              variant="body1"
              gutterBottom
              component="div"
              color="#707070"
            >
              {paymentCompleteMsg()}
            </Typography>
          </Box>
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"center"}
            flexDirection="column"
            marginTop="20px"
          >
            {plan === "DtcApp" && (
              <AppButton
                sx={{backgroundColor: "#6A75B9", width: "350px"}}
                variant="contained"
                onClick={onCompleteSession}
              >
                Begin Application
              </AppButton>
            )}
            <AppButton
              sx={{
                backgroundColor: "#FF9100",
                width: "200px",
                marginTop: "10px"
              }}
              variant="contained"
              onClick={onPrint}
              startIcon={<PrintIcon />}
            >
              Print
            </AppButton>
            {plan === "AssistanceRequired" && (
              <AppButton
                sx={{backgroundColor: "#6A75B9", width: "350px"}}
                variant="contained"
                onClick={onSignOut}
              >
                Sign Out
              </AppButton>
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default PaymentCompleted
