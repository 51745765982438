import {Box, Typography} from "@mui/material"
import React, {useEffect, useState} from "react"
import AppPageCarded from "@app-core/AppPageCarded"
import {useNavigate} from "react-router-dom"
import {ErrorModal} from "@app-core/ErrorModal"
import {LoadingScreen} from "@app-core/LoadingScreen"
import {useAuth} from "auth/AuthContext"
import {ApplicationsTable} from "components/ApplicationsTable"
import {applicationApi, userApi} from "data/api"
import {IDtcApplication} from "models/IDtcApplication"

export const PreviousApplicationsView: React.FC = () => {
  const {user} = useAuth()
  const navigate = useNavigate()
  const [applications, setApplications] = useState<IDtcApplication[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [hasError, setHasError] = useState<boolean>(false)

  useEffect(() => {
    const loadUserApplication = async (id: string) => {
      try {
        const dtcApps = await userApi.getUserApplications(id)
        setApplications(dtcApps)
        setLoading(false)
      } catch (error) {
        setHasError(true)
      }
    }
    if (!user) {
      navigate("/sign-in")
    } else {
      loadUserApplication(user.id || "")
    }
  }, [navigate, user])

  const onEditApplication = (code: string) => {
    navigate(`/application?code=${code}`)
  }

  const onViewPdfApplication = (code: string) => {
    navigate(`/application-viewer/${code}`)
  }

  const onDownloadApplication = async (code: string) => {
    const url = await applicationApi.getApplicationPdfUrl(code)
    const alink = document.createElement("a")
    alink.href = url
    alink.download = `${code}.pdf`
    alink.click()
    alink.remove()
  }

  if (hasError) return <ErrorModal error="Issue Loading DTC Applications" />
  if (loading) return <LoadingScreen isLoading={loading} />

  return (
    <AppPageCarded scroll="page">
      <Box padding={6}>
        <Typography
          component="h4"
          sx={{marginBottom: "10px", fontWeight: "bold"}}
        >
          Previous Applications
        </Typography>
        <ApplicationsTable
          applications={applications}
          onEdit={onEditApplication}
          onViewPdf={onViewPdfApplication}
          onDownloadPdf={onDownloadApplication}
        />
      </Box>
    </AppPageCarded>
  )
}

export default PreviousApplicationsView
