import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import {ButtonProps, Button, Box, Typography, Stack} from "@mui/material"
import {styled} from "@mui/material/styles"
import React from "react"
import {IQuestion} from "models/IQuestion"

interface YesNoGroupsProps {
  question: IQuestion
  answer: string
  onSelectAnswer: (answer: string) => void
}

interface GroupSectionProps {
  title: string
  answer: string
  onSelectAnswer: (answer: string) => void
}

type AnswerButtonProps = ButtonProps & {
  selectedanswer: string
  anscheck: string
}
const AnswerButton = styled(Button)<AnswerButtonProps>(
  ({selectedanswer, anscheck}) => ({
    borderRadius: "17px",
    height: "70px",
    fontSize: "24px",
    color: "#707070",
    justifyContent: "unset",
    border: `${
      selectedanswer === anscheck ? "4px solid #6a75b9" : "1px solid #707070"
    }`,
    ":hover": {
      border: "1px solid #6a75b9"
    }
  })
)

const GroupSection: React.FC<GroupSectionProps> = (
  props: GroupSectionProps
) => {
  const {title, answer, onSelectAnswer} = props
  const onAnswerClicked = (answer: string) => {
    onSelectAnswer(answer)
  }
  return (
    <Box>
      <Typography
        fontSize={"16px"}
        fontWeight="600"
        variant="h6"
        gutterBottom
        component="div"
      >
        {title}
      </Typography>
      <Stack spacing={2}>
        {["Yes", "No"].map((ans, i) => (
          <AnswerButton
            key={`GroupSection_answerOptions_${i}`}
            selectedanswer={answer}
            anscheck={ans}
            onClick={() => onAnswerClicked(ans)}
            fullWidth
            variant="outlined"
            startIcon={
              <CheckCircleIcon
                sx={{
                  fontSize: "38px !important",
                  color: `${answer === ans ? "#66bb6a" : ""}`
                }}
              />
            }
          >
            {ans}
          </AnswerButton>
        ))}
      </Stack>
    </Box>
  )
}

export const YesNoGroups: React.FC<YesNoGroupsProps> = (
  props: YesNoGroupsProps
) => {
  const {question, answer, onSelectAnswer} = props

  const onAnswerClicked = (index: number) => (newAnswer: string) => {
    const ansArray = answer.split(";")

    ansArray[index] = newAnswer

    onSelectAnswer(ansArray.join(";"))
  }

  const getAnswer = (index: number) => {
    return answer.split(";")[index] || ""
  }

  return (
    <Box>
      <Box marginBottom="20px">
        <Typography
          fontSize={"28px"}
          fontWeight="600"
          variant="h6"
          gutterBottom
          component="div"
        >
          {question.text}
        </Typography>
        <Stack spacing={2}>
          {question.answerOptions.map((ans, i) => (
            <GroupSection
              key={`answerOptions_${i}`}
              title={ans}
              answer={getAnswer(i)}
              onSelectAnswer={onAnswerClicked(i)}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  )
}
