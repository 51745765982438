import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@mui/material"
import React from "react"
import {AppButton} from "@app-core/AppButton"
import ArticleImage from "assets/images/article.svg"

interface EligibilityWelcomeMsgProps {
  onNext: () => void
}

export const EligibilityWelcomeMsg: React.FC<EligibilityWelcomeMsgProps> = (
  props: EligibilityWelcomeMsgProps
) => {
  const {onNext} = props
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const onNextClick = () => {
    onNext()
  }

  return (
    <>
      <Box padding={4}>
        <Box padding={3}>
          <Grid paddingTop={5} container spacing={2}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <img src={ArticleImage} alt="Article" />
            </Grid>
            <Grid
              sx={{border: "1px solid #707070", borderRadius: "32px"}}
              item
              xs={12}
              sm={12}
              md={9}
              lg={9}
            >
              <Box sx={{minHeight: "230px"}}>
                <Typography
                  className="text-lg"
                  fontWeight="bold"
                  variant="h4"
                  gutterBottom
                  component="div"
                >
                  This assessment will help you to determine if you are likely
                  to qualify:
                </Typography>
                <List dense>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography
                          className="text-lg"
                          fontWeight="bold"
                          variant="h4"
                          gutterBottom
                          component="div"
                        >
                          Seeing
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography
                          className="text-lg"
                          fontWeight="bold"
                          variant="h4"
                          gutterBottom
                          component="div"
                        >
                          Talking
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography
                          className="text-lg"
                          fontWeight="bold"
                          variant="h4"
                          gutterBottom
                          component="div"
                        >
                          Hearing
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography
                          className="text-lg"
                          fontWeight="bold"
                          variant="h4"
                          gutterBottom
                          component="div"
                        >
                          Walking
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography
                          className="text-lg"
                          fontWeight="bold"
                          variant="h4"
                          gutterBottom
                          component="div"
                        >
                          Using the Bathroom
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography
                          className="text-lg"
                          fontWeight="bold"
                          variant="h4"
                          gutterBottom
                          component="div"
                        >
                          Eating or Preparing a Meal
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography
                          className="text-lg"
                          fontWeight="bold"
                          variant="h4"
                          gutterBottom
                          component="div"
                        >
                          Getting Dressed
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography
                          className="text-lg"
                          fontWeight="bold"
                          variant="h4"
                          gutterBottom
                          component="div"
                        >
                          Performing Mental Tasks
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
                <Typography
                  className="text-lg"
                  fontWeight="bold"
                  variant="h4"
                  gutterBottom
                  component="div"
                >
                  You may also be eligible if you require Life Sustaining
                  Therapy to support a vital function as long as you receive
                  therapy at least 3 times a week and it requires 14 hours a
                  week or more to manage your condition. To learn more about
                  what is included in the calculation of the 14 hours required
                  to manage your condition{" "}
                  <Button
                    size="small"
                    variant="text"
                    disableElevation
                    disableRipple
                    onClick={handleClickOpen}
                  >
                    click here
                  </Button>
                </Typography>
                <Typography
                  className="text-lg"
                  fontWeight="bold"
                  variant="h4"
                  gutterBottom
                  component="div"
                >
                  The DTC is designed to assist individuals with severe and
                  prolonged impairments that exist generally 90% of the time
                  even when taking medication, receiving therapy, or using
                  assistive devices. It is also required that your impairment
                  has lasted or is expected to last for a minimum of one year.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box
        display={"flex"}
        alignItems="center"
        justifyContent="flex-end"
        padding={4}
      >
        <AppButton
          onClick={onNextClick}
          sx={{
            backgroundColor: "#6A75B9",
            minWidth: "150px",
            minHeight: "44px"
          }}
          variant="contained"
        >
          Next
        </AppButton>
        <Dialog fullWidth onClose={handleClose} open={open}>
          <DialogContent>
            <DialogContentText sx={{whiteSpace: "pre-wrap", lineHeight: "2.5"}}>
              <Typography
                className="text-lg"
                fontWeight="bold"
                variant="h4"
                gutterBottom
                component="div"
              >
                Included in the calculation of the required 14 hours are the
                following;
              </Typography>
              <List dense>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        className="text-md"
                        fontWeight="bold"
                        variant="h4"
                        gutterBottom
                        component="div"
                      >
                        Preparing your equipment and yourself for testing
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        className="text-md"
                        fontWeight="bold"
                        variant="h4"
                        gutterBottom
                        component="div"
                      >
                        Monitoring your blood glucose levels
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        className="text-md"
                        fontWeight="bold"
                        variant="h4"
                        gutterBottom
                        component="div"
                      >
                        Adjusting levels
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        className="text-md"
                        fontWeight="bold"
                        variant="h4"
                        gutterBottom
                        component="div"
                      >
                        Cleaning of injection sites
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        className="text-md"
                        fontWeight="bold"
                        variant="h4"
                        gutterBottom
                        component="div"
                      >
                        Administering the therapy (i.e. insulin)
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        className="text-md"
                        fontWeight="bold"
                        variant="h4"
                        gutterBottom
                        component="div"
                      >
                        Maintaining a journal
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        className="text-md"
                        fontWeight="bold"
                        variant="h4"
                        gutterBottom
                        component="div"
                      >
                        Maintaining your equipment (i.e. insulin pump)
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        className="text-md"
                        fontWeight="bold"
                        variant="h4"
                        gutterBottom
                        component="div"
                      >
                        Disposing of sharps
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        className="text-md"
                        fontWeight="bold"
                        variant="h4"
                        gutterBottom
                        component="div"
                      >
                        Managing dietary restrictions and regimes related to
                        therapy requiring daily consumption of a medical food or
                        formula to limit intake of a particular compound
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        className="text-md"
                        fontWeight="bold"
                        variant="h4"
                        gutterBottom
                        component="div"
                      >
                        Please note that if a caregiver is assisting a minor
                        with any of these activities, then the time taken by
                        both people are included
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        className="text-md"
                        fontWeight="bold"
                        variant="h4"
                        gutterBottom
                        component="div"
                      >
                        Receiving dialysis
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        className="text-md"
                        fontWeight="bold"
                        variant="h4"
                        gutterBottom
                        component="div"
                      >
                        Receiving chest therapy for cystic fibrosis
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
              <Typography
                className="text-lg"
                fontWeight="bold"
                variant="h4"
                gutterBottom
                component="div"
              >
                What is{" "}
                <b>
                  <u>not</u>
                </b>{" "}
                included in the 14 hours is:
              </Typography>
              <List dense>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        className="text-md"
                        fontWeight="bold"
                        variant="h4"
                        gutterBottom
                        component="div"
                      >
                        Travel time to receive the therapy (i.e. driving to the
                        hospital to receive dialysis)
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        className="text-md"
                        fontWeight="bold"
                        variant="h4"
                        gutterBottom
                        component="div"
                      >
                        Recuperating after the therapy
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        className="text-md"
                        fontWeight="bold"
                        variant="h4"
                        gutterBottom
                        component="div"
                      >
                        Exercise
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        className="text-md"
                        fontWeight="bold"
                        variant="h4"
                        gutterBottom
                        component="div"
                      >
                        Medical appointments where you do not receive a therapy
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        className="text-md"
                        fontWeight="bold"
                        variant="h4"
                        gutterBottom
                        component="div"
                      >
                        Obtaining medication
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        className="text-md"
                        fontWeight="bold"
                        variant="h4"
                        gutterBottom
                        component="div"
                      >
                        Eating or Preparing a Meal
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        className="text-md"
                        fontWeight="bold"
                        variant="h4"
                        gutterBottom
                        component="div"
                      >
                        Time a portable or implanted device takes to deliver
                        therapy
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  )
}

export default EligibilityWelcomeMsg
