import {yupResolver} from "@hookform/resolvers/yup"
import {Paper, Typography, TextField, Button} from "@mui/material"
import _ from "lodash"
import React, {useEffect, useState} from "react"
import {LoadingScreen} from "@app-core/LoadingScreen"
import {Controller, useForm} from "react-hook-form"
import {useAuth} from "auth/AuthContext"
import {useNavigate} from "react-router-dom"
import * as yup from "yup"
import {applicationApi} from "data/api"
import {useMessages} from "hooks/useMessages"

interface WalkThroughState {
  walkThroughCode: string
}

const defaultValues: WalkThroughState = {
  walkThroughCode: ""
}

const schema = yup.object().shape({
  walkThroughCode: yup.string().required("You must enter the walk-through code")
})

export const WalkThroughView: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const {addMessage} = useMessages()
  const navigate = useNavigate()
  const {isAuthenticated, user} = useAuth()

  useEffect(() => {
    if (!isAuthenticated || !user) {
      navigate("/")
    }

    if (!user?.roles?.includes("WalkThroughAdmin")) {
      navigate("/")
    }
  }, [isAuthenticated, navigate, user])

  const {control, formState, handleSubmit, reset} = useForm<WalkThroughState>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema)
  })

  const {isValid, dirtyFields, errors} = formState

  const onSubmit = async ({walkThroughCode}: WalkThroughState) => {
    try {
      setLoading(true)
      const d = await applicationApi.startWalkThroughApplication(
        walkThroughCode
      )
      setLoading(false)
      navigate(`/application?code=${d.applicationCode}`)
    } catch (error) {
      setLoading(false)
      addMessage({
        content: "Walk-through code does not exist. Please try again",
        severity: "error"
      })
      reset(defaultValues)
    }
  }

  const onImageClick = () => {
    navigate("/")
  }

  if (loading) {
    return <LoadingScreen isLoading={true} />
  }

  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
      <Paper className="w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img
            style={{width: "375px", cursor: "pointer"}}
            src="/static/images/logo.png"
            alt="logo"
            onClick={onImageClick}
          />
          <Typography className="mt-32 text-3xl font-extrabold tracking-tight leading-tight">
            Start Walk-Through Application
          </Typography>
          <div className="flex items-baseline mt-2 font-medium">
            <Typography>
              Fill in the walk through code to access the patient&apos;s
              application
            </Typography>
          </div>

          <form
            name="registerForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="walkThroughCode"
              control={control}
              render={({field}) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Walk-Through Code"
                  type="text"
                  error={!!errors.walkThroughCode}
                  helperText={errors?.walkThroughCode?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <Button
              variant="contained"
              color="secondary"
              className=" w-full mt-4"
              aria-label="Register"
              disabled={_.isEmpty(dirtyFields) || !isValid}
              type="submit"
              size="large"
            >
              Start
            </Button>
          </form>
        </div>
      </Paper>
    </div>
  )
}

export default WalkThroughView
