import ApiClient, {IApiClient} from "./ApiClient"
import {IEligibilityQuestion} from "models/IEligibilityQuestion"

const urlSubPath = "/eligibilityQuestions"
export default class EligibilityQuestionApi {
  private apiClient: IApiClient

  public constructor() {
    this.apiClient = new ApiClient(process.env.REACT_APP_API_BASE_URL || "")
  }

  public fetchEligibilityQuestions = async (): Promise<
    IEligibilityQuestion[]
  > => {
    const data = await this.apiClient.get<IEligibilityQuestion[]>(
      `${urlSubPath}`
    )
    return data
  }
}
