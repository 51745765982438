import {
  Box,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  TextField,
  AutocompleteChangeReason,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  createFilterOptions
} from "@mui/material"
import React, {useState} from "react"
import {IQuestion} from "models/IQuestion"

interface SelectAllThatApplyProps {
  question: IQuestion
  answer: string
  onSelectAnswer: (answer: string) => void
  withFreeText?: boolean
}

interface OtherOptionType {
  inputValue?: string
  title: string
}

const filter = createFilterOptions<OtherOptionType>()

const getOtherItems = (selectedAnswers: string[], answerOptions: string[]) => {
  const others = selectedAnswers.filter((a) => !answerOptions.includes(a))
  return others
}
export const SelectAllThatApply: React.FC<SelectAllThatApplyProps> = (
  props: SelectAllThatApplyProps
) => {
  const {question, answer, onSelectAnswer, withFreeText = false} = props
  const selectedAnswers = answer
    .split(", ")
    .filter((i) => i)
    .map((a) => a.trim())
  const otherItems = getOtherItems(selectedAnswers, question.answerOptions)
  const [hasOtherItems, setHasOtherItems] = useState<boolean>(
    otherItems.length > 0
  )

  const options: OtherOptionType[] = otherItems.map((d) => ({
    title: d
  }))
  const [open, toggleOpen] = React.useState(false)
  const [dialogValue, setDialogValue] = React.useState({
    item: ""
  })

  const handleClose = () => {
    setDialogValue({
      item: ""
    })
    toggleOpen(false)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const ans = selectedAnswers
    if (ans.some((sa) => sa === dialogValue.item)) {
      onSelectAnswer(ans.filter((a) => a !== dialogValue.item).join(", "))
    } else {
      ans.push(dialogValue.item)
      onSelectAnswer(ans.join(", "))
    }
    // onSelectAnswer([...selectedAnswers, dialogValue.item].join(", "))
    handleClose()
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value
    const ans = selectedAnswers
    if (ans.some((sa) => sa === val)) {
      onSelectAnswer(ans.filter((a) => a !== val).join(", "))
    } else {
      ans.push(val)
      onSelectAnswer(ans.join(", "))
    }
  }

  const handleOtherChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setHasOtherItems(checked)
    } else {
      const updated = selectedAnswers.filter((a) =>
        question.answerOptions.includes(a)
      )
      onSelectAnswer(updated.join(", "))
      setHasOtherItems(checked)
    }
  }

  const handleOtherItemsChange = (
    newValue: string | string[],
    reason: AutocompleteChangeReason,
    details: {option: {title: string}}
    // AutocompleteChangeDetails<string[]> | undefined
  ) => {
    const ans = selectedAnswers
    if (typeof newValue === "string") {
      if (ans.some((sa) => sa === newValue)) {
        onSelectAnswer(ans.filter((a) => a !== newValue).join(", "))
      } else {
        ans.push(newValue)
        onSelectAnswer(ans.join(", "))
      }
    } else {
      if (reason === "removeOption") {
        onSelectAnswer(ans.filter((a) => a !== details.option.title).join(", "))
      } else {
        const update = [...new Set(ans.concat(newValue as string[]))]
        onSelectAnswer(update.join(", "))
      }
    }
  }

  return (
    <Box>
      <Box marginBottom="20px">
        <Typography
          fontSize={"28px"}
          fontWeight="600"
          variant="h6"
          gutterBottom
          component="div"
        >
          {question.text}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {question.answerOptions.map((ans, i) => (
          <Grid key={`answerOptions_${i}`} item xs={12} sm={12} md={3} lg={3}>
            <FormControlLabel
              control={
                <Checkbox
                  value={ans}
                  checked={selectedAnswers.some((sa) => sa === ans)}
                  onChange={handleChange}
                />
              }
              label={ans}
            />
          </Grid>
        ))}
        {withFreeText && (
          <Grid key={`answerOptions_others`} item xs={12} sm={12} md={3} lg={3}>
            <FormControlLabel
              control={
                <Checkbox
                  value={"Other"}
                  checked={hasOtherItems}
                  onChange={handleOtherChange}
                />
              }
              label={"Other"}
            />
          </Grid>
        )}
      </Grid>
      {hasOtherItems && (
        <Box>
          <Autocomplete
            multiple
            id="other-items"
            options={[]}
            getOptionLabel={(option) => option.title}
            fullWidth
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            value={options}
            onChange={(event: any, newValue, reason, details) => {
              if (
                reason === "selectOption" &&
                details &&
                details.option.inputValue
              ) {
                toggleOpen(true)
                setDialogValue({
                  item: details.option.inputValue
                })
              } else {
                if (typeof newValue === "string") {
                  handleOtherItemsChange(
                    newValue,
                    reason,
                    details as unknown as {option: {title: string}}
                  )
                } else {
                  handleOtherItemsChange(
                    newValue.map((o) => o.title) as string[],
                    reason,
                    details as unknown as {option: {title: string}}
                  )
                }
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params)

              if (params.inputValue !== "") {
                filtered.push({
                  inputValue: params.inputValue,
                  title: `Add "${params.inputValue}"`
                })
              }
              return filtered
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Others"
                placeholder="Other"
              />
            )}
          />
          <Dialog open={open} onClose={handleClose}>
            <form onSubmit={handleSubmit}>
              <DialogTitle>Add an item</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  value={dialogValue.item}
                  onChange={(event) =>
                    setDialogValue({
                      ...dialogValue,
                      item: event.target.value
                    })
                  }
                  type="text"
                  variant="outlined"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Add</Button>
              </DialogActions>
            </form>
          </Dialog>
        </Box>
      )}
    </Box>
  )
}
