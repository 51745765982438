import {Container} from "@mui/material"
import React, {useState, useRef} from "react"
import {Document, Page, pdfjs} from "react-pdf"
import {LoadingScreen} from "@app-core/LoadingScreen"

interface PdfViewerProps {
  fileUrl: string
}

export const PdfViewer: React.FC<PdfViewerProps> = (props: PdfViewerProps) => {
  const {fileUrl} = props
  const [numPages, setNumPages] = useState<number>(0)
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  const ref = useRef<HTMLDivElement>(null)

  /* To Prevent right click on screen */
  //   document.addEventListener('contextmenu', (event) => {
  //     event.preventDefault();
  //   });

  const onDocumentLoadSuccess = (pages: number) => {
    setNumPages(pages)
    const textLayers = document.querySelectorAll(
      ".react-pdf__Page__textContent"
    )
    textLayers.forEach((layer) => {
      const {style} = layer as HTMLElement
      style.top = "0"
      style.left = "0"
      style.transform = ""
    })
  }

  return (
    <Container fixed>
      <div ref={ref}>
        <Document
          options={{
            standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts/`
          }}
          loading={<LoadingScreen isLoading={true} />}
          file={{
            url: fileUrl
          }}
          renderMode="canvas"
          onLoadSuccess={(pdf) => onDocumentLoadSuccess(pdf.numPages)}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={
                ref.current?.offsetWidth
                  ? Math.min(ref.current?.offsetWidth * 0.9)
                  : undefined
              }
            />
          ))}
        </Document>
      </div>
    </Container>
  )
}
