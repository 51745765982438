import {Box, Button} from "@mui/material"
import React, {useState} from "react"
import {AppButton} from "@app-core/AppButton"
import {IQuestion} from "models/IQuestion"
import ApplicationSectionStartView from "./ApplicationSectionStartView"
import {IQuestionAnswer} from "models/IQuestionAnswer"
import {NumberTextBoxes} from "./NumberTextBoxes"
import {IQuestionType} from "models/IQuestionType"
import {SelectAllDiagnosesThatApply} from "./SelectAllDiagnosesThatApply"
import {SelectAllThatApply} from "./SelectAllThatApply"
import {SelectOne} from "./SelectOne"
import {SelectOneWithSelectAll} from "./SelectOneWithSelectAll"
import {SelectYearDropdown} from "./SelectYearDropdown"
import VisualAcuity from "./VisualAcuity"
import {YesNoGroups} from "./YesNoGroups"

interface ApplicationQuestionsProps {
  questions: IQuestion[]
  questionAnswers: IQuestionAnswer[]
  onNext: (answers: IQuestionAnswer[]) => void
  onBack: () => void
  onPrevSection: () => void
  onNextSection: () => void
  canPrevSection: boolean
  canNextSection: boolean
}

export const ApplicationQuestions: React.FC<ApplicationQuestionsProps> = (
  props: ApplicationQuestionsProps
) => {
  const {
    questions,
    questionAnswers,
    onNext,
    onBack,
    onPrevSection,
    onNextSection,
    canPrevSection,
    canNextSection
  } = props
  const [answers, setAnswers] = useState<IQuestionAnswer[]>(questionAnswers)
  const [currentQuestion, setCurrentQuestion] = useState<IQuestion>(
    questions[0]
  )

  const [showWelcomeMsg, setShowWelcomeMsg] = useState<boolean>(true)

  const onWelcomeMsgNextClick = () => {
    setShowWelcomeMsg(false)
  }

  const onWelcomeMsgCancelClick = () => {
    onBack()
  }

  const onSave = () => {
    onNext(answers)
  }

  const mentalFunctionsQ2Check = () => {
    const answerIndex = answers.findIndex(
      (q) => q.question.questionMapTag === "MentalFunctionsQ2"
    )

    if (answerIndex < 0) {
      return false
    }

    if (answers[answerIndex].answer === "") {
      return false
    }

    if (answers[answerIndex].answer !== "Yes") {
      return false
    }

    return true
  }

  const updateCurrentQuestion = (index: number, back = false) => {
    if (index < 0) {
      setShowWelcomeMsg(true)
      return
    }
    if (index < 0) {
      onBack()
      return
    }

    const nq = questions[index]
    if (
      (nq.questionMapTag === "MentalFunctionsQ2Supervise" ||
        nq.questionMapTag === "MentalFunctionsQ2Medication") &&
      !mentalFunctionsQ2Check()
    ) {
      if (back) {
        updateCurrentQuestion(index - 1, back)
      } else {
        updateCurrentQuestion(index + 1, back)
      }
      return
    }

    setCurrentQuestion(nq)
  }

  const onNextClick = () => {
    if (!currentQuestion) {
      return
    }
    if (getAnswer() === "") {
      onAnswerSelected("")
    }
    const currentIndex = questions.findIndex((q) => q.id === currentQuestion.id)
    updateCurrentQuestion(currentIndex + 1)
  }

  const onBackClick = () => {
    if (!currentQuestion) {
      return
    }
    if (getAnswer() === "") {
      onAnswerSelected("")
    }
    const currentIndex = questions.findIndex((q) => q.id === currentQuestion.id)
    updateCurrentQuestion(currentIndex - 1, true)
  }

  const isVisualAcuityValid = (answer: string) => {
    const answerArr = answer.split(";")

    if (answerArr.length > 0) {
      const left = answerArr[0]
      const right = answerArr[1]

      if (left && right) {
        const leftArr = left.split(":")
        const rightArr = right.split(":")

        if (leftArr[0] && leftArr[1] && rightArr[0] && rightArr[1]) {
          return true
        }
      }
    }
    return false
  }

  const onAnswerSelected = (answer: string, subAnswer = "", year = "") => {
    if (!currentQuestion) {
      return
    }

    // if (
    //   currentQuestion.type === IQuestionType.VisualAcuity &&
    //   !isVisualAcuityValid(answer)
    // ) {
    //   return
    // }
    const selectedAnswers = answers

    const answerIndex = selectedAnswers.findIndex(
      (q) => q.question.id === currentQuestion.id
    )

    if (answerIndex < 0) {
      selectedAnswers.push({
        question: currentQuestion,
        answer,
        subAnswer,
        year
      })
    } else {
      selectedAnswers[answerIndex].answer = answer
      selectedAnswers[answerIndex].subAnswer = subAnswer
      selectedAnswers[answerIndex].year = year
    }

    setAnswers([...selectedAnswers])
  }

  const getAnswer = (): string => {
    if (!currentQuestion) {
      return ""
    }
    const answerIndex = answers.findIndex(
      (q) => q.question.id === currentQuestion.id
    )

    if (answerIndex < 0) {
      return ""
    } else {
      return answers[answerIndex].answer
    }
  }

  const getYear = (): string => {
    if (!currentQuestion) {
      return ""
    }
    const answerIndex = answers.findIndex(
      (q) => q.question.id === currentQuestion.id
    )

    if (answerIndex < 0) {
      return ""
    } else {
      return answers[answerIndex].year || ""
    }
  }

  const getSubAnswer = (): string => {
    if (!currentQuestion) {
      return ""
    }
    const answerIndex = answers.findIndex(
      (q) => q.question.id === currentQuestion.id
    )

    if (answerIndex < 0) {
      return ""
    } else {
      return answers[answerIndex].subAnswer || ""
    }
  }

  const canMoveOn = (): boolean => {
    if (!currentQuestion) {
      return false
    }

    if (!currentQuestion.required) {
      return true
    }

    const answerIndex = answers.findIndex(
      (q) => q.question.id === currentQuestion.id
    )

    if (answerIndex < 0) {
      return false
    } else {
      if (
        currentQuestion.type === IQuestionType.VisualAcuity &&
        !isVisualAcuityValid(answers[answerIndex].answer)
      ) {
        return false
      }

      if (answers[answerIndex].answer === "") {
        return false
      }

      // Check if year is required
      if (currentQuestion.yearOption) {
        if (
          currentQuestion.yearOption.conditionalShow &&
          currentQuestion.yearOption.conditionalAnswer.includes(
            answers[answerIndex].answer
          ) &&
          answers[answerIndex].year === ""
        ) {
          return false
        }
        if (
          currentQuestion.yearOption.required &&
          !currentQuestion.yearOption.conditionalShow &&
          answers[answerIndex].year === ""
        ) {
          return false
        }
      }
      return true
    }
  }

  const isLastQuestion = () => {
    if (!currentQuestion) {
      return false
    }
    const currentIndex = questions.findIndex((q) => q.id === currentQuestion.id)
    return currentIndex + 1 === questions.length
  }

  const QuestionContent = () => {
    switch (currentQuestion.type) {
      case IQuestionType.SelectOne:
        return (
          <SelectOne
            question={currentQuestion}
            answer={getAnswer()}
            year={getYear()}
            onSelectAnswer={onAnswerSelected}
          />
        )
      case IQuestionType.SelectAllThatApply:
        return (
          <SelectAllThatApply
            question={currentQuestion}
            answer={getAnswer()}
            onSelectAnswer={onAnswerSelected}
          />
        )
      case IQuestionType.SelectAllThatApplyWithFreeText:
        return (
          <SelectAllThatApply
            question={currentQuestion}
            answer={getAnswer()}
            onSelectAnswer={onAnswerSelected}
            withFreeText={true}
          />
        )
      case IQuestionType.SelectAllDiagnosesThatApply:
        return (
          <SelectAllDiagnosesThatApply
            question={currentQuestion}
            answer={getAnswer()}
            onSelectAnswer={onAnswerSelected}
          />
        )
      case IQuestionType.YesNoGroups:
        return (
          <YesNoGroups
            question={currentQuestion}
            answer={getAnswer()}
            onSelectAnswer={onAnswerSelected}
          />
        )
      case IQuestionType.VisualAcuity:
        return (
          <VisualAcuity
            question={currentQuestion}
            answer={getAnswer()}
            onSelectAnswer={onAnswerSelected}
          />
        )
      case IQuestionType.NumberTextBoxes:
        return (
          <NumberTextBoxes
            question={currentQuestion}
            answer={getAnswer()}
            onSelectAnswer={onAnswerSelected}
          />
        )
      case IQuestionType.SelectOneWithSelectAll:
        return (
          <SelectOneWithSelectAll
            question={currentQuestion}
            answer={getAnswer()}
            subAnswer={getSubAnswer()}
            onSelectAnswer={onAnswerSelected}
          />
        )
      case IQuestionType.SelectYear:
        return (
          <SelectYearDropdown
            question={currentQuestion}
            answer={getAnswer()}
            onSelectAnswer={onAnswerSelected}
          />
        )
      default:
        return <></>
    }
  }

  const questionNum = currentQuestion
    ? questions.findIndex((q) => q.id === currentQuestion.id) + 1
    : 0
  return (
    <>
      {showWelcomeMsg && (
        <ApplicationSectionStartView
          onStart={onWelcomeMsgNextClick}
          onCancel={onWelcomeMsgCancelClick}
          section={currentQuestion.section}
          numQuestions={questions.length}
        />
      )}
      {!showWelcomeMsg && (
        <Box padding={4}>
          <Box sx={{display: "flex", flexDirection: "row", pt: 2}}>
            <Button
              sx={{
                backgroundColor: "#6A75B9",
                minWidth: "150px",
                minHeight: "44px"
              }}
              variant="contained"
              disabled={!canPrevSection}
              onClick={onPrevSection}
            >
              Previous Section
            </Button>
            <Box sx={{flex: "1 1 auto"}} />
            <Button
              sx={{
                backgroundColor: "#6A75B9",
                minWidth: "150px",
                minHeight: "44px"
              }}
              variant="contained"
              disabled={!canNextSection}
              onClick={onNextSection}
            >
              Next Section
            </Button>
          </Box>
          <Box display={"flex"} alignItems="center" justifyContent="flex-start">
            <Box
              className="text-xl"
              fontWeight={900}
              sx={{
                height: "40px",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                color: "#6A75B9"
              }}
            >
              {`Question ${questionNum}/${questions.length}`}
            </Box>
          </Box>
          <Box padding={3}>
            <QuestionContent />
          </Box>
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent="flex-end"
            marginTop={4}
          >
            {isLastQuestion() ? (
              <>
                <Button onClick={onBackClick}>Back</Button>
                <AppButton
                  onClick={onSave}
                  sx={{
                    backgroundColor: "#6A75B9",
                    minWidth: "150px",
                    minHeight: "44px"
                  }}
                  variant="contained"
                  disabled={!canMoveOn()}
                >
                  Next Section
                </AppButton>
              </>
            ) : (
              <>
                <Button onClick={onBackClick}>Back</Button>
                <AppButton
                  onClick={onNextClick}
                  sx={{
                    backgroundColor: "#6A75B9",
                    minWidth: "150px",
                    minHeight: "44px"
                  }}
                  variant="contained"
                  disabled={!canMoveOn()}
                >
                  Next
                </AppButton>
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}

export default ApplicationQuestions
