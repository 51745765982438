import GlobalStyles from "@mui/material/GlobalStyles"
import {styled} from "@mui/material/styles"
import {MUIStyledCommonProps, Theme} from "@mui/system"
import clsx from "clsx"
import React, {forwardRef, memo, useImperativeHandle, useRef} from "react"
import AppScrollbars from "@app-core/AppScrollbars/AppScrollbars"

interface IAppPageCardedProps {
  scroll: "normal" | "page" | "content"
  children: React.ReactNode
  className?: string
}

interface RootProps extends MUIStyledCommonProps<Theme> {
  scroll: string
}

const Root = styled("div")<RootProps>(({theme, ...props}) => ({
  display: "flex",
  flexDirection: "column",
  minWidth: 0,
  minHeight: "100%",
  position: "relative",
  flex: "1 1 auto",
  width: "100%",
  height: "auto",
  backgroundColor: "#F0F0F0",

  "& .DtcPageCarded-scroll-content": {
    height: "100%"
  },

  "& .DtcPageCarded-wrapper": {
    display: "flex",
    flexDirection: "row",
    flex: "1 1 auto",
    zIndex: 2,
    maxWidth: "100%",
    minWidth: 0,
    height: "auto",
    backgroundColor: theme.palette.background.paper,

    ...(props.scroll === "content" && {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      overflow: "hidden"
    })
  },

  "& .DtcPageCarded-header": {
    display: "flex",
    flex: "0 0 auto"
  },

  "& .DtcPageCarded-contentWrapper": {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    zIndex: 9999
  },

  "& .DtcPageCarded-content": {
    flex: "1 0 auto"
  },
  "& .DtcPageCarded-backdrop": {
    position: "absolute"
  }
}))

// eslint-disable-next-line react/display-name
const AppPageCarded = forwardRef((props: IAppPageCardedProps, ref) => {
  const rootRef = useRef(null)

  useImperativeHandle(ref, () => ({
    rootRef
  }))

  return (
    <>
      <GlobalStyles
        styles={(theme) => ({
          ...(props.scroll !== "page" && {
            "#dtc-toolbar": {
              position: "static"
            },
            "#dtc-footer": {
              position: "static"
            }
          }),
          ...(props.scroll === "page" && {
            "#dtc-toolbar": {
              position: "sticky",
              top: 0
            },
            "#dtc-footer": {
              position: "sticky",
              bottom: 0
            }
          })
        })}
      />
      <Root
        className={clsx(
          "DtcPageCarded-root",
          `DtcPageCarded-scroll-${props.scroll}`,
          props.className
        )}
        ref={rootRef}
        scroll={props.scroll}
      >
        <div className="flex flex-auto flex-col container z-10 h-full shadow-1 rounded-3xl shadow-2xl relative overflow-hidden">
          <div className="DtcPageCarded-wrapper">
            <AppScrollbars
              className="DtcPageCarded-contentWrapper"
              enable={props.scroll === "content"}
            >
              <div className={clsx("DtcPageCarded-content")}>
                {props.children}
              </div>
            </AppScrollbars>
          </div>
        </div>
      </Root>
    </>
  )
})
// rounded-md
export default memo(styled(AppPageCarded)``)
