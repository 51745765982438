import {Alert, AlertColor, Snackbar, SnackbarCloseReason} from "@mui/material"
import React, {useState, useEffect, useContext} from "react"

export interface IMessage {
  key?: number
  severity: AlertColor
  content: string
}

export interface IMessagesContext {
  message: IMessage | undefined
  isOpen: boolean
  addMessage: (message: IMessage) => void
  closeMessage: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => void
  clearMessage: (node: HTMLElement) => void
}

export type IMessagesProvider = {
  children: React.ReactNode
}

const MessagesContext = React.createContext<IMessagesContext>(
  {} as IMessagesContext
)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const MessagesProvider = ({children}: IMessagesProvider) => {
  const [messages, setMessages] = useState<IMessage[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const [activeMessage, setActiveMessage] = useState<IMessage | undefined>(
    undefined
  )

  useEffect(() => {
    if (messages.length && !activeMessage) {
      // Set a new message when we don't have an active one
      setActiveMessage({...messages[0]})
      setMessages((prev) => prev.slice(1))
      setIsOpen(true)
    } else if (messages.length && activeMessage && isOpen) {
      // Close an active snack when a new one is added
      setIsOpen(false)
    }
  }, [messages, activeMessage, isOpen])

  const addMessage = (message: IMessage) => {
    setMessages((prev) => [...prev, {...message, key: new Date().getTime()}])
  }

  const closeMessage = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return
    }

    setIsOpen(false)
  }

  const clearMessage = () => {
    setActiveMessage(undefined)
  }

  const value: IMessagesContext = {
    message: activeMessage,
    isOpen,
    addMessage,
    closeMessage,
    clearMessage
  }

  return (
    <MessagesContext.Provider value={value}>
      {activeMessage && (
        <Snackbar
          key={activeMessage ? activeMessage.key : undefined}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={isOpen}
          autoHideDuration={6000}
          onClose={closeMessage}
          TransitionProps={{
            onExited: clearMessage
          }}
        >
          <Alert severity={activeMessage?.severity} onClose={closeMessage}>
            {activeMessage ? activeMessage.content : undefined}
          </Alert>
        </Snackbar>
      )}
      {children}
    </MessagesContext.Provider>
  )
}

export const useMessages = (): IMessagesContext => useContext(MessagesContext)
