import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import {
  ButtonProps,
  Button,
  Box,
  Typography,
  Stack,
  Checkbox,
  FormControlLabel,
  Grid
} from "@mui/material"
import {styled} from "@mui/material/styles"
import React from "react"
import {IQuestion} from "models/IQuestion"

interface SelectOneWithSelectAllProps {
  question: IQuestion
  answer: string
  subAnswer: string
  onSelectAnswer: (answer: string, subAnswer?: string) => void
}

type AnswerButtonProps = ButtonProps & {
  selectedanswer: string
  anscheck: string
}
const AnswerButton = styled(Button)<AnswerButtonProps>(
  ({selectedanswer, anscheck}) => ({
    borderRadius: "17px",
    height: "70px",
    fontSize: "24px",
    color: "#707070",
    justifyContent: "unset",
    border: `${
      selectedanswer === anscheck ? "4px solid #6a75b9" : "1px solid #707070"
    }`,
    ":hover": {
      border: "1px solid #6a75b9"
    }
  })
)

export const SelectOneWithSelectAll: React.FC<SelectOneWithSelectAllProps> = (
  props: SelectOneWithSelectAllProps
) => {
  const {question, answer, subAnswer, onSelectAnswer} = props
  const selectedAnswers = subAnswer.split(";").map((a) => a.trim())
  const subOptions = question.subOptions || []

  const onAnswerClicked = (answer: string) => {
    onSelectAnswer(answer, selectedAnswers.join("; "))
  }

  const handleSubAnsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value
    const ans = selectedAnswers
    if (ans.some((sa) => sa === val)) {
      onSelectAnswer(answer, ans.filter((a) => a !== val).join("; "))
    } else {
      ans.push(val)
      onSelectAnswer(answer, ans.join("; "))
    }
  }

  const checkIfDisabled = (selectedAnswers: string[], ans: string) => {
    if (selectedAnswers.length <= 3) {
      return false
    }
    if (selectedAnswers.some((sa) => sa === ans)) {
      return false
    }

    return true
  }
  return (
    <Box>
      <Box marginBottom="20px">
        <Typography
          fontSize={"28px"}
          fontWeight="600"
          variant="h6"
          gutterBottom
          component="div"
        >
          {question.text}
        </Typography>
      </Box>
      <Stack direction="column" spacing={2}>
        {question.answerOptions.map((ans, i) => (
          <AnswerButton
            key={`answerOptions_${i}`}
            selectedanswer={answer}
            anscheck={ans}
            onClick={() => onAnswerClicked(ans)}
            fullWidth
            variant="outlined"
            startIcon={
              <CheckCircleIcon
                sx={{
                  fontSize: "38px !important",
                  color: `${answer === ans ? "#66bb6a" : ""}`
                }}
              />
            }
          >
            {ans}
          </AnswerButton>
        ))}
      </Stack>
      <Box marginTop="20px">
        <Typography
          fontSize={"28px"}
          fontWeight="600"
          variant="h6"
          gutterBottom
          component="div"
        >
          Select all that apply (max of 3):
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {subOptions.map((ans, i) => (
          <Grid
            key={`subAnswerOptions_${i}`}
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
          >
            <FormControlLabel
              control={
                <Checkbox
                  value={ans}
                  checked={selectedAnswers.some((sa) => sa === ans)}
                  onChange={handleSubAnsChange}
                  disabled={checkIfDisabled(selectedAnswers, ans)}
                />
              }
              label={ans}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
