import {authApi} from "data/api"
import {AuthLoginRequest} from "data/types/AuthLoginRequest"
import {AuthSignUpRequest} from "data/types/AuthSignUpRequest"
import {IAppUser} from "models/IAppUser"
import AppEventEmitter from "utils/AppEventEmitter"
import {getItem, removeItem, setItem} from "utils/localStorageService"

class JwtService extends AppEventEmitter {
  public init = () => {
    this.handleAuthentication()
  }

  private handleAuthentication = () => {
    // const access_token = this.getAccessToken()

    // if (!access_token) {

    // }

    this.emit("onNoAccessToken")
    return

    // this.setSession(access_token)
    // this.emit("onAutoLogin", true)
  }

  private getAccessToken = () => {
    return getItem("dtc_access_token")
  }

  private setSession = (access_token: string | null) => {
    if (access_token) {
      setItem("dtc_access_token", access_token)
    } else {
      removeItem("dtc_access_token")
    }
  }

  public signUp = async (
    signUpProps: AuthSignUpRequest,
    redirectUrl?: string
  ): Promise<IAppUser> => {
    const userCreds = await authApi.signUp(signUpProps)
    const user = userCreds.user
    this.setSession(userCreds.accessToken)
    this.emit("onLogin", user, redirectUrl)
    return user
  }

  public signInWithEmailAndPassword = async (
    loginProps: AuthLoginRequest,
    redirectUrl?: string
  ): Promise<IAppUser> => {
    const userCreds = await authApi.login(loginProps)
    const user = userCreds.user
    this.setSession(userCreds.accessToken)
    this.emit("onLogin", user, redirectUrl)
    return user
  }

  public signInWithPhysicianAccessCode = async (
    accessCode: string,
    appCode: string
  ): Promise<void> => {
    const res = await authApi.physicianLogin({
      accessCode,
      appCode
    })
    this.setSession(res.accessToken)
    this.emit("onPhysicianLogin", appCode)
  }

  public signInWithToken = async (
    token = "",
    redirectUrl?: string
  ): Promise<IAppUser> => {
    try {
      const access_token = token || this.getAccessToken()
      const userCreds = await authApi.loginWithToken(access_token || "")
      const user = userCreds.user
      this.setSession(access_token)
      this.emit("onLogin", user, redirectUrl)
      return user
    } catch (error) {
      this.logout()
      throw error
    }
  }

  public logout = () => {
    this.setSession(null)
    this.emit("onLogout", "Logged out")
  }
}

const instance = new JwtService()

export default instance
