import {yupResolver} from "@hookform/resolvers/yup"
import {
  Paper,
  Typography,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Button,
  LinearProgress,
  Box
} from "@mui/material"
import _ from "lodash"
import {LoadingScreen} from "@app-core/LoadingScreen"
import React, {useState} from "react"
import jwtService from "auth/JwtService"
import {useForm, Controller} from "react-hook-form"
import {PrivacyPolicyModal} from "components/PrivacyPolicyModal"
import {PatternFormat} from "react-number-format"
import {TermsAndConditionsModal} from "components/TermsAndConditionsModal"
import {Link, useLocation} from "react-router-dom"
import {authApi} from "data/api"
import * as yup from "yup"
import {useMessages} from "hooks/useMessages"

interface SignUpState {
  firstName: string
  lastName: string
  email: string
  cellNumber: string
  password: string
  passwordConfirm: string
  acceptTermsConditions: boolean
}
const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

const schema = yup.object().shape({
  firstName: yup.string().required("You must enter your first name"),
  lastName: yup.string().required("You must enter your last name"),
  cellNumber: yup
    .string()
    .matches(phoneRegExp, "Cell phone number is not valid")
    .required("You must enter your cell phone number"),
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter an email"),
  password: yup
    .string()
    .required("Please enter your password.")
    .min(8, "Password is too short - should be 8 chars minimum."),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  acceptTermsConditions: yup
    .boolean()
    .oneOf([true], "The terms and conditions must be accepted.")
})

const defaultValues: SignUpState = {
  firstName: "",
  lastName: "",
  email: "",
  cellNumber: "",
  password: "",
  passwordConfirm: "",
  acceptTermsConditions: false
}

export const SignUpView: React.FC = () => {
  const location = useLocation()
  const redirectUrl = location.state?.redirectUrl
  const [loading, setLoading] = useState(false)
  const {addMessage} = useMessages()
  const [otc, setOtc] = useState("")
  const [otcLoading, setOtcLoading] = useState(false)
  const [otcError, setOtcError] = useState("")
  const [otcSent, setOtcSent] = useState(false)
  const [otcVerified, setOtcVerified] = useState(false)
  const {control, formState, handleSubmit, reset, getValues} =
    useForm<SignUpState>({
      mode: "onChange",
      defaultValues,
      resolver: yupResolver(schema)
    })

  const {isValid, dirtyFields, errors} = formState

  const onSubmit = async ({
    firstName,
    lastName,
    email,
    password,
    cellNumber
  }: SignUpState) => {
    try {
      if (!otcVerified) {
        addMessage({
          content: "Verification code must be verified",
          severity: "error"
        })
        return
      }
      setLoading(true)
      await jwtService.signUp(
        {
          firstName,
          lastName,
          email,
          password,
          verificationCode: otc,
          cellNumber
        },
        redirectUrl
      )
    } catch (error) {
      const err = error as {message: string}
      setLoading(false)
      addMessage({
        content: err.message,
        severity: "error"
      })
      reset(defaultValues)
    }
  }

  const onSendVerificationCode = async () => {
    try {
      setOtcLoading(true)
      const {email} = getValues()

      if (email) {
        await authApi.sendVerificationCode({email})
        setOtcError("")
        setOtcSent(true)
        setOtcLoading(false)
      } else {
        setOtcError("Email is required to send verification code")
        setOtcSent(false)
        setOtcLoading(false)
      }
    } catch {
      setOtcError("Error sending verification code. Please try again")
      setOtcSent(false)
      setOtcLoading(false)
    }
  }

  const onSendVerifyCode = async () => {
    try {
      setOtcLoading(true)
      const {email} = getValues()

      if (email && otc) {
        await authApi.verifyCode({email, code: otc})
        setOtcError("")
        setOtcVerified(true)
        setOtcLoading(false)
      } else {
        setOtcError("Email and Verification code is required")
        setOtcVerified(false)
        setOtcLoading(false)
      }
    } catch (error) {
      setOtcError("The code has expired")
      setOtcSent(false)
      setOtcLoading(false)
    }
  }

  const onOtcChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtc(event.target.value)
  }

  if (loading) {
    return <LoadingScreen isLoading={true} />
  }
  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
      <Paper className="w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img
            style={{width: "375px"}}
            src="/static/images/logo.png"
            alt="logo"
          />

          <Typography className="mt-32 text-2xl font-extrabold tracking-tight leading-tight">
            Sign up
          </Typography>
          <div className="flex items-baseline mt-2 font-medium">
            <Typography>Already have an account?</Typography>
            <Link className="ml-4" to="/sign-in">
              Sign in
            </Link>
          </div>

          <form
            name="registerForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="email"
              control={control}
              render={({field}) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Email"
                  type="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  required
                  fullWidth
                  autoComplete="off"
                />
              )}
            />
            {!otcVerified && (
              <Box className="mb-24">
                {otcLoading && (
                  <Box sx={{width: "100%"}}>
                    <LinearProgress />
                  </Box>
                )}
                {!otcSent && !otcLoading && (
                  <Button
                    variant="contained"
                    color="secondary"
                    className=" w-full"
                    aria-label="Send Verification Code"
                    size="large"
                    onClick={onSendVerificationCode}
                  >
                    Send verification code
                  </Button>
                )}
                {otcSent && !otcLoading && (
                  <Box>
                    <Box className="flex items-center justify-center mb-24">
                      <Typography className="text-md">
                        Please check your email for verification code
                      </Typography>
                    </Box>
                    <TextField
                      className="mb-12"
                      name="verificationCode"
                      label="Verification Code"
                      type="text"
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                      value={otc}
                      onChange={onOtcChange}
                    />
                    <Box className="flex items-center justify-center">
                      <Button
                        variant="contained"
                        aria-label="Verify Code"
                        onClick={onSendVerifyCode}
                      >
                        Verify code
                      </Button>
                      <Button
                        variant="contained"
                        className="ml-4"
                        aria-label="Send new Code"
                        onClick={onSendVerificationCode}
                      >
                        Send new code
                      </Button>
                    </Box>
                  </Box>
                )}
                {otcError && !otcLoading && (
                  <div className="flex items-baseline mt-2 font-medium">
                    <Typography sx={{color: "red"}}>{otcError}</Typography>
                  </div>
                )}
              </Box>
            )}

            <Controller
              name="firstName"
              control={control}
              render={({field}) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="First Name"
                  autoFocus
                  type="text"
                  disabled={!otcVerified}
                  error={!!errors.firstName}
                  helperText={errors?.firstName?.message}
                  variant="outlined"
                  required
                  fullWidth
                  autoComplete="off"
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              render={({field}) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Last Name"
                  autoFocus
                  type="text"
                  disabled={!otcVerified}
                  error={!!errors.lastName}
                  helperText={errors?.lastName?.message}
                  variant="outlined"
                  required
                  fullWidth
                  autoComplete="off"
                />
              )}
            />

            <Controller
              name="cellNumber"
              control={control}
              render={({field}) => (
                <PatternFormat
                  {...field}
                  className="mb-24"
                  label="Cell Phone Number"
                  autoFocus
                  type="text"
                  disabled={!otcVerified}
                  error={!!errors.cellNumber}
                  helperText={errors?.cellNumber?.message}
                  variant="outlined"
                  required
                  fullWidth
                  format="+1 (###)-###-####"
                  allowEmptyFormatting
                  mask="_"
                  customInput={TextField}
                  autoComplete="off"
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              render={({field}) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Password"
                  type="password"
                  disabled={!otcVerified}
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  variant="outlined"
                  required
                  fullWidth
                  autoComplete="off"
                />
              )}
            />

            <Controller
              name="passwordConfirm"
              control={control}
              render={({field}) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Password (Confirm)"
                  type="password"
                  disabled={!otcVerified}
                  error={!!errors.passwordConfirm}
                  helperText={errors?.passwordConfirm?.message}
                  variant="outlined"
                  required
                  fullWidth
                  autoComplete="off"
                />
              )}
            />

            <Controller
              name="acceptTermsConditions"
              control={control}
              render={({field}) => (
                <FormControl
                  className="items-center"
                  error={!!errors.acceptTermsConditions}
                >
                  <FormControlLabel
                    label="I agree to the Terms of Service and Privacy Policy"
                    control={
                      <Checkbox
                        disabled={!otcVerified}
                        size="small"
                        {...field}
                      />
                    }
                  />
                  <FormHelperText>
                    {errors?.acceptTermsConditions?.message}
                  </FormHelperText>
                  <TermsAndConditionsModal />
                  <PrivacyPolicyModal />
                </FormControl>
              )}
            />

            <Button
              variant="contained"
              color="secondary"
              className=" w-full mt-24"
              aria-label="Register"
              disabled={_.isEmpty(dirtyFields) || !isValid}
              type="submit"
              size="large"
            >
              Create your account
            </Button>
          </form>
        </div>
      </Paper>
    </div>
  )
}

export default SignUpView
