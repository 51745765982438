import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"
import {styled} from "@mui/material/styles"
import clsx from "clsx"
import React, {useState} from "react"

const StyledAccordion = styled(Accordion)(({theme}) => ({
  margin: 0,
  border: "none!important",
  borderRadius: "8px!important",
  marginBottom: 24,
  "&:before": {
    display: "none"
  },
  "&:first-of-type": {},
  "&:last-of-type": {
    marginBottom: 0
  }
}))

interface Faq {
  question: string
  answer: string
}
interface FaqListProps {
  faqs: Faq[]
  className: string
}

export const FaqList: React.FC<FaqListProps> = (props: FaqListProps) => {
  const {faqs = [], className} = props
  const [expanded, setExpanded] = useState<string>("")

  const toggleAccordion =
    (panel: string) => (event: React.SyntheticEvent, _expanded: boolean) => {
      setExpanded(_expanded ? panel : "")
    }

  return (
    <div className={clsx("", className)}>
      {faqs.map((faq, i) => (
        <StyledAccordion
          key={`faq-${i}`}
          classes={{
            root: "FaqPage-panel shadow"
          }}
          expanded={expanded === faq.question}
          onChange={toggleAccordion(faq.question)}
        >
          <AccordionSummary
            expandIcon={<KeyboardArrowDownIcon fontSize="small" />}
          >
            <div className="flex items-center py-4">
              <HelpOutlineOutlinedIcon color="action" fontSize="small" />
              <Typography className="px-12 font-medium">
                {faq.question}
              </Typography>
            </div>
          </AccordionSummary>

          <AccordionDetails>
            <Typography className="text-14 px-32 pb-8 -mt-8">
              {faq.answer}
            </Typography>
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </div>
  )
}

export default FaqList
