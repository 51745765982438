import {
  ConfirmCardPaymentData,
  StripeCardNumberElement
} from "@stripe/stripe-js"

interface BillingDetail {
  email: string
  nameOnCard: string
  postalCode: string
  city: string
  line1: string
  line2: string
  country: {name: string; code: string} | null
  state: string
  currency: string
}

export const stripeDataObjectConverter = (
  billingDetails: BillingDetail,
  cardElement: StripeCardNumberElement
): ConfirmCardPaymentData => ({
  payment_method: {
    card: cardElement,
    billing_details: {
      address: {
        city: billingDetails.nameOnCard,
        country: billingDetails.country?.code,
        state: billingDetails.state,
        line1: billingDetails.line1,
        line2: billingDetails.line2,
        postal_code: billingDetails.postalCode
      },
      email: billingDetails.email,
      name: billingDetails.nameOnCard
    }
  }
})

export const camelCaseToTitleCase = (text: string) => {
  const result = text.replace(/([A-Z])/g, " $1")
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
  return finalResult
}
