import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import {ButtonProps, Button, Box, Typography, Stack} from "@mui/material"
import {styled} from "@mui/material/styles"
import React from "react"
import {InfoModal} from "components/InfoModal"
import {YearDatePicker} from "components/YearDatePicker"
import {IQuestion} from "models/IQuestion"

interface SelectOneProps {
  question: IQuestion
  answer: string
  year?: string
  onSelectAnswer: (answer: string, subAnswer?: string, year?: string) => void
}

type AnswerButtonProps = ButtonProps & {
  selectedanswer: string
  anscheck: string
}
const AnswerButton = styled(Button)<AnswerButtonProps>(
  ({selectedanswer, anscheck}) => ({
    borderRadius: "17px",
    height: "70px",
    fontSize: "24px",
    color: "#707070",
    justifyContent: "unset",
    border: `${
      selectedanswer === anscheck ? "4px solid #6a75b9" : "1px solid #707070"
    }`,
    ":hover": {
      border: "1px solid #6a75b9"
    }
  })
)

export const SelectOne: React.FC<SelectOneProps> = (props: SelectOneProps) => {
  const {question, answer, year = "", onSelectAnswer} = props
  const {info, yearOption} = question

  const onAnswerClicked = (answer: string) => {
    if (canShowYearPicker()) {
      onSelectAnswer(answer, "", year)
    } else {
      onSelectAnswer(answer, "", "")
    }
  }

  const onYearChanged = (updatedYear: string) => {
    onSelectAnswer(answer, "", updatedYear)
  }

  const getYearLabel = () => {
    if (yearOption) {
      if (yearOption.label.includes("{selectedAnswer}")) {
        return yearOption.label.replace("{selectedAnswer}", answer)
      } else {
        return yearOption.label
      }
    }
  }

  const canShowYearPicker = () => {
    if (!yearOption) {
      return false
    }

    if (yearOption.selectedAnswerRequired && yearOption.conditionalShow) {
      if (answer && yearOption.conditionalAnswer.includes(answer)) {
        return true
      }
    }

    if (yearOption.conditionalShow) {
      if (answer && yearOption.conditionalAnswer.includes(answer)) {
        return true
      }
    }

    if (
      yearOption.selectedAnswerRequired &&
      !yearOption.conditionalShow &&
      answer
    ) {
      return true
    }

    return false
  }

  return (
    <Box>
      <Box marginBottom="20px">
        <Typography
          fontSize={"28px"}
          fontWeight="600"
          variant="h6"
          gutterBottom
          component="div"
        >
          {question.text}
          {info && <InfoModal content={info.label} />}
        </Typography>
      </Box>
      <Stack spacing={2}>
        {question.answerOptions.map((ans, i) => (
          <AnswerButton
            key={`answerOptions_${i}`}
            selectedanswer={answer}
            anscheck={ans}
            onClick={() => onAnswerClicked(ans)}
            fullWidth
            variant="outlined"
            startIcon={
              <CheckCircleIcon
                sx={{
                  fontSize: "38px !important",
                  color: `${answer === ans ? "#66bb6a" : ""}`
                }}
              />
            }
          >
            {ans}
          </AnswerButton>
        ))}
      </Stack>
      {canShowYearPicker() && (
        <Box marginTop="20px">
          <Typography
            fontSize={"28px"}
            fontWeight="600"
            variant="h6"
            gutterBottom
            component="div"
          >
            {getYearLabel()}
          </Typography>
          <YearDatePicker onChange={onYearChanged} year={year} />
        </Box>
      )}
    </Box>
  )
}
