import AppPageCarded from "@app-core/AppPageCarded/AppPageCarded"
import {ErrorModal} from "@app-core/ErrorModal"
import {LoadingScreen} from "@app-core/LoadingScreen"
import {useAuth} from "auth/AuthContext"
import {EligibilityAppProcessSelection} from "components/EligibilityAppProcessSelection"
import {EligibilityDenied} from "components/EligibilityDenied"
import {EligibilityQuestion} from "components/EligibilityQuestion"
import {EligibilityStartView} from "components/EligibilityStartView"
import React, {useEffect, useState} from "react"
import {EligibilitySummary} from "components/EligibilitySummary"
import {useNavigate} from "react-router-dom"
import {EligibilityWelcomeMsg} from "components/EligibilityWelcomeMsg"
import {eligibilityQuestionApi} from "data/api"
import {useEligibilityApplication} from "hooks/useEligibilityApplication"
import {useMessages} from "hooks/useMessages"
import {IEligibilityApplication} from "models/IEligibilityApplication"
import {IEligibilityQuestion} from "models/IEligibilityQuestion"
import {IEligibilityQuestionAnswer} from "models/IEligibilityQuestionAnswer"

export const EligibilityView: React.FC = () => {
  const navigate = useNavigate()
  const {user} = useAuth()
  const {addMessage} = useMessages()
  const [eligibilityQuestions, setEligibilityQuestions] = useState<
    IEligibilityQuestion[] | null
  >()
  const [currentQuestion, setCurrentQuestion] =
    useState<IEligibilityQuestion | null>(
      eligibilityQuestions ? eligibilityQuestions[0] : null
    )
  const [answers, setAnswers] = useState<IEligibilityQuestionAnswer[]>([])
  const [showWelcomeMsg, setShowWelcomeMsg] = useState<boolean>(true)
  const [showStartView, setShowStartView] = useState<boolean>(true)
  const [isCompleted, setIsCompleted] = useState<boolean>(false)
  const [eligible, setEligible] = useState<boolean>(false)
  const [summaryViewed, setSummaryViewed] = useState<boolean>(false)
  const [allowRepresentativeContact, setAllowRepresentativeContact] =
    useState<boolean>(false)
  const [appProcess, setAppProcess] = useState<string>("")
  const {setEligibilityApplication} = useEligibilityApplication()

  useEffect(() => {
    eligibilityQuestionApi
      .fetchEligibilityQuestions()
      .then(setEligibilityQuestions)
      .catch(() => setEligibilityQuestions(null))
  }, [])

  const onSelectAnswer = (answer: string) => {
    if (!currentQuestion) {
      return
    }
    const selectedAnswers = answers

    const answerIndex = selectedAnswers.findIndex(
      (q) => q.question.id === currentQuestion.id
    )

    if (answerIndex < 0) {
      selectedAnswers.push({
        question: currentQuestion,
        answer
      })
    } else {
      selectedAnswers[answerIndex].answer = answer
    }

    setAnswers([...selectedAnswers])
  }

  const onWelcomeMsgNextClick = () => {
    setShowWelcomeMsg(false)
  }

  const restartQuestions = () => {
    navigate("/")
  }

  const onBackToQuestions = () => {
    setEligible(false)
    setIsCompleted(false)
  }

  const onBackToSummary = () => {
    setSummaryViewed(false)
  }

  const getAnswer = (): string => {
    if (!currentQuestion) {
      return ""
    }
    const answerIndex = answers.findIndex(
      (q) => q.question.id === currentQuestion.id
    )

    if (answerIndex < 0) {
      return ""
    } else {
      return answers[answerIndex].answer
    }
  }

  const onConfirmClick = async () => {
    // set prefix application data and redirect to
    // choose your plan view
    const diagnoses = answers
      .filter((a) => a.answer === "Yes")
      .map((q) => q.question.type)

    const dtcApp: IEligibilityApplication = {
      diagnoses,
      plan: "",
      applicationProcess: appProcess,
      allowRepresentativeContact: allowRepresentativeContact
    }
    if (user) {
      setEligibilityApplication(dtcApp)
      navigate("/plan")
    } else {
      setEligibilityApplication(dtcApp)
      navigate("/sign-in", {
        state: {
          redirectUrl: "/plan"
        }
      })
    }
  }

  const onSelectedProcess = (answer: string) => {
    setAppProcess(answer)
  }

  const onAllowRepresentativeContactClick = (answer: boolean) => {
    setAllowRepresentativeContact(answer)
  }

  const onConfirmSummaryClick = async () => {
    setSummaryViewed(true)
  }

  if (eligibilityQuestions === null)
    return <ErrorModal error={`Issue Loading Eligibility Questions`} />
  if (eligibilityQuestions === undefined)
    return <LoadingScreen isLoading={true} />

  const onStartViewClick = () => {
    setCurrentQuestion(eligibilityQuestions[0])
    setShowStartView(false)
  }

  const checkEligibility = () => {
    if (answers.length < eligibilityQuestions.length) {
      addMessage({
        severity: "error",
        content: "Please answer all questions"
      })
      return
    } else if (answers.some((a) => a.answer === "Yes")) {
      setEligible(true)
      setIsCompleted(true)
      return
    } else {
      updateCurrentQuestion(0)
      setAnswers([])
      setEligible(false)
      setIsCompleted(true)
    }
  }

  const updateCurrentQuestion = (index: number) => {
    if (index === eligibilityQuestions.length) {
      checkEligibility()
      return
    }
    if (index < 0) {
      setShowStartView(true)
      return
    }
    setCurrentQuestion(eligibilityQuestions[index])
  }

  const onNextClick = () => {
    if (!currentQuestion) {
      return
    }
    const currentIndex = eligibilityQuestions.findIndex(
      (q) => q.id === currentQuestion.id
    )
    updateCurrentQuestion(currentIndex + 1)
  }

  const onBackClick = () => {
    if (!currentQuestion) {
      return ""
    }
    const currentIndex = eligibilityQuestions.findIndex(
      (q) => q.id === currentQuestion.id
    )
    updateCurrentQuestion(currentIndex - 1)
  }
  const questionNum = currentQuestion
    ? eligibilityQuestions.findIndex((q) => q.id === currentQuestion.id) + 1
    : 0

  return (
    <AppPageCarded scroll="page">
      {showWelcomeMsg && (
        <EligibilityWelcomeMsg onNext={onWelcomeMsgNextClick} />
      )}
      {!showWelcomeMsg && showStartView && (
        <EligibilityStartView onStart={onStartViewClick} />
      )}
      {!showWelcomeMsg && !showStartView && isCompleted && !eligible && (
        <EligibilityDenied onOkClick={restartQuestions} />
      )}
      {!showWelcomeMsg &&
        !showStartView &&
        isCompleted &&
        eligible &&
        !summaryViewed && (
          <EligibilitySummary
            onBack={onBackToQuestions}
            onConfirmClick={onConfirmSummaryClick}
            answers={answers.filter(
              (a) => a.answer === "Yes" || a.question.type === "FamilyDoctor"
            )}
          />
        )}
      {!showWelcomeMsg &&
        !showStartView &&
        isCompleted &&
        eligible &&
        summaryViewed && (
          <EligibilityAppProcessSelection
            allowRepresentativeContact={allowRepresentativeContact}
            onBack={onBackToSummary}
            onConfirmClick={onConfirmClick}
            selectedProcess={appProcess}
            onSelectedProcess={onSelectedProcess}
            onAllowRepresentativeContactClick={
              onAllowRepresentativeContactClick
            }
          />
        )}
      {!showWelcomeMsg && !showStartView && !isCompleted && currentQuestion && (
        <EligibilityQuestion
          question={currentQuestion}
          questionNum={questionNum}
          answer={getAnswer()}
          totalQuestions={eligibilityQuestions.length}
          onSelectAnswer={onSelectAnswer}
          onNext={onNextClick}
          onBack={onBackClick}
        />
      )}
    </AppPageCarded>
  )
}

export default EligibilityView
