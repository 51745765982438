import React from "react"
import {RouteObject} from "react-router-dom"
import AboutView from "./about"
import ApplicationView from "./application"
import ApplicationViewerView from "./application-viewer"
import ChoosePlanView from "./choose-payment"
import EligibilityView from "./eligibility"
import ForgotPasswordView from "./forgot-password"
import HelpCenterView from "./help-center"
import FAQView from "./help-center/faq"
import GuidesView from "./help-center/guides"
import SupportView from "./help-center/support"
import HomeView from "./home"
import NotFound from "./notFound"
import PaymentView from "./payment"
import PaymentCompletionView from "./payment-completion"
import PhysicianAccessView from "./physician-access"
import PreviousApplicationsView from "./previous-applications"
import ResetPasswordView from "./reset-password"
import SignInView from "./sign-in"
import SignOutView from "./sign-out"
import SignUpView from "./sign-up"
import WalkThroughView from "./walk-through"

const routes: RouteObject[] = [
  {
    path: "/",
    element: <HomeView />
  },
  {
    path: "about",
    element: <AboutView />
  },
  {
    path: "help-center",
    element: <HelpCenterView />
  },
  {
    path: "help-center/faqs",
    element: <FAQView />
  },
  {
    path: "help-center/support",
    element: <SupportView />
  },
  {
    path: "help-center/guides",
    element: <GuidesView />
  },
  {
    path: "payment",
    element: <PaymentView />
  },
  {
    path: "eligibility",
    element: <EligibilityView />
  },
  {
    path: "plan",
    element: <ChoosePlanView />
  },
  {
    path: "payment-completion",
    element: <PaymentCompletionView />
  },
  {
    path: "application",
    element: <ApplicationView />
  },
  {
    path: "application-viewer/:code",
    element: <ApplicationViewerView />
  },
  {
    path: "previous-applications",
    element: <PreviousApplicationsView />
  },
  {
    path: "sign-in",
    element: <SignInView />
  },
  {
    path: "sign-up",
    element: <SignUpView />
  },
  {
    path: "sign-out",
    element: <SignOutView />
  },
  {
    path: "forgot-password",
    element: <ForgotPasswordView />
  },
  {
    path: "reset-password/:token",
    element: <ResetPasswordView />
  },
  {
    path: "physician-access",
    element: <PhysicianAccessView />
  },
  {
    path: "walk-through",
    element: <WalkThroughView />
  },
  {
    path: "*",
    element: <NotFound />
  }
]

export default routes
