// import { StripePaymentElement } from "@stripe/stripe-js"
// import React, {useRef, useImperativeHandle} from "react"

// export const StripeInput = ({component: Component, inputRef, ...other}) => {
//   const elementRef = useRef()
//   useImperativeHandle(inputRef, () => ({
//     focus: () => elementRef.current.focus
//   }))

//   return (
//     <Component
//       onReady={(element: StripePaymentElement) => (elementRef.current = element)}
//       {...other}
//     />
//   )
// }
import {InputBaseComponentProps} from "@mui/material/InputBase"
import {alpha, useTheme} from "@mui/material/styles"
import * as React from "react"

export const StripeInput = React.forwardRef<any, InputBaseComponentProps>(
  function StripeInput(props, ref) {
    const {component: Component, options, ...other} = props
    const theme = useTheme()
    const [mountNode, setMountNode] = React.useState<any | null>(null)

    React.useImperativeHandle(
      ref,
      () => ({
        focus: () => mountNode.focus()
      }),
      [mountNode]
    )

    return (
      <Component
        onReady={setMountNode}
        options={{
          ...options,
          style: {
            base: {
              color: theme.palette.text.primary,
              fontSize: "16px",
              lineHeight: "1.4375em", // 23px
              fontFamily: theme.typography.fontFamily,
              "::placeholder": {
                color: alpha(theme.palette.text.primary, 0.42)
              }
            },
            invalid: {
              color: theme.palette.text.primary
            }
          }
        }}
        {...other}
      />
    )
  }
)

export default StripeInput
