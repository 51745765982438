import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import DownloadIcon from "@mui/icons-material/Download"
import {Box, Button, Container, GlobalStyles} from "@mui/material"
import React, {useState, useEffect} from "react"
import {ErrorModal} from "@app-core/ErrorModal"
import {useNavigate, useParams} from "react-router-dom"
import {LoadingScreen} from "@app-core/LoadingScreen"
import {useAuth} from "auth/AuthContext"
import {PdfViewer} from "components/PdfViewer"
import {applicationApi} from "data/api"

export const ApplicationViewerView: React.FC = () => {
  const {code} = useParams()
  const {user, isPhysicianUser} = useAuth()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(true)
  const [callingApi, setCallingApi] = useState<boolean>(true)
  const [pdfUrl, setPdfUrl] = useState<string>("")
  const [errorObj, setErrorObj] = useState({hasError: false, message: ""})

  useEffect(() => {
    const loadApplication = async () => {
      if (pdfUrl || (!callingApi && pdfUrl === "")) {
        return
      }

      try {
        if (code) {
          const url = await applicationApi.getApplicationPdfUrl(code)
          setPdfUrl(url)
          setLoading(false)
        } else {
          setErrorObj({
            hasError: true,
            message: "Issue Loading DTC Application PDF"
          })
        }
      } catch (e) {
        setErrorObj({
          hasError: true,
          message: "Issue Loading DTC Application PDF"
        })
      }

      setCallingApi(false)
    }

    if (!user && !isPhysicianUser) {
      navigate("/")
    }

    loadApplication()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDownloadApplication = async () => {
    if (code) {
      const url = await applicationApi.getApplicationPdfUrl(code)
      const alink = document.createElement("a")
      alink.href = url
      alink.download = `${code}.pdf`
      alink.click()
      alink.remove()
    }
  }

  const goToPrevApps = () => {
    navigate("/previous-applications")
  }
  if (errorObj.hasError) return <ErrorModal error={errorObj.message} />
  if (loading) return <LoadingScreen isLoading={true} label="Please Wait..." />
  return (
    <>
      <GlobalStyles
        styles={(theme) => ({
          ...{
            "#dtc-toolbar": {
              position: "static"
            },
            "#dtc-footer": {
              position: "static"
            }
          }
        })}
      />
      <Box padding={4}>
        <Container fixed sx={{marginBottom: "12px"}}>
          {!isPhysicianUser && (
            <Button
              onClick={goToPrevApps}
              variant="contained"
              startIcon={<ArrowBackIosIcon />}
              sx={{marginRight: "12px"}}
            >
              Previous Applications
            </Button>
          )}
          <Button
            sx={{backgroundColor: "#FF9100"}}
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={onDownloadApplication}
          >
            Download Application
          </Button>
        </Container>
        <PdfViewer fileUrl={pdfUrl} />
      </Box>
    </>
  )
}

export default ApplicationViewerView
