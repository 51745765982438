import CloseIcon from "@mui/icons-material/Close"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import React from "react"

export const PrivacyPolicyModal: React.FC = () => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const descriptionElementRef = React.useRef<HTMLElement>(null)
  React.useEffect(() => {
    if (open) {
      const {current: descriptionElement} = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  return (
    <div>
      <Button onClick={handleClickOpen} variant="text">
        Privacy Policy
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title-Privacy-Policy" sx={{m: 0, p: 2}}>
          Privacy Policy
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Typography variant="h5" gutterBottom>
              1. INFORMATION THAT WE COLLECT
            </Typography>
            <Typography variant="body1" gutterBottom>
              1.A If You decide to register and use benefits2.ca you will be
              asked to provide information about yourself or the person you are
              legally representing that will include your name and contact
              details. By submitting this personal information, you are
              consenting to the collection, use and transfer of your information
              in accordance with the terms of our privacy policy.
            </Typography>
            <Typography variant="body1" gutterBottom>
              1.B At times, our website may collect information about specific
              usage of the website and of the service being provided, as well as
              information from emails, phone calls or mailed letters that may be
              sent to our company, or if you post information on our website. We
              will only use the information in accordance with the terms of our
              privacy policy.
            </Typography>
            <Typography variant="body1" gutterBottom>
              1.C You must be 18 years old to be able to register with us on our
              website. If our system detects that you are not 18 years old, we
              will remove your personal information from our system. Entering
              details on our website you are stating that you are 18 years old
              or older.
            </Typography>
            <Typography variant="body1" gutterBottom>
              1.D We do not maintain financial information including billing and
              credit card information. We use a secure third-party service for
              any financial transactions.
            </Typography>
            <Typography variant="h5" gutterBottom>
              MODIFICATION OF TERMS OF USE
            </Typography>
            <Typography variant="body1" gutterBottom>
              Benefits2 reserves the right, at its sole discretion, to modify or
              replace any of these Terms of Use at any time. Benefits2 will
              notify you of changes on the Site and through the benefits2.ca.com
              and we may send an email to registered users to notify them of the
              change. Your continued use of the Service following the posting of
              any changes to the Terms of Use constitutes acceptance of those
              changes.
            </Typography>
            <Typography variant="h5" gutterBottom>
              2. USE OF YOUR PERSONAL INFORMATION
            </Typography>
            <Typography variant="body1" gutterBottom>
              2.A Information we collect will be used for the following
              purposes: to provide secure access to specific parts of the
              Benefits2 website and to use the service to allow for the ability
              to download information and receive email notifications from our
              website. In addition, we may contact you via email with occasional
              website updates about new website features and services, along
              with occasional newsletters. In the event our privacy policy
              changes we will update you with an email notification indicating
              the upcoming changes and our website will reflect the most recent
              update of our privacy policy. Any purchased service on the website
              pertaining to a completed Disability Tax Credit Form will be
              permanently deleted after 30 days. The ONLY information retained
              in our system after 30 days will include customer email address,
              diagnosis type, gender, age, and physical location pertaining to
              city/town. ALL other personal information including your name,
              address details and all other personal information will be deleted
              after 30 days. The information retained after 30 days will be used
              for reporting statistics along with identifying potential patterns
              and correlation of diagnosis types in specific areas and regions
              of Canada.
            </Typography>
            <Typography variant="body1" gutterBottom>
              2.B At any time a registered user can ask to be removed from our
              mailing/ e-mailing list.
            </Typography>
            <Typography variant="h5" gutterBottom>
              3. DISCLOSURE OF PERSONAL INFORMATION
            </Typography>
            <Typography variant="body1" gutterBottom>
              3.A We adhere to strict guidelines in the storage and disclosure
              of your personal information which you have provided us, we take
              secure steps to prevent unauthorized access to your personal data.
              We comply with the data privacy laws of Canada.
            </Typography>
            <Typography variant="body1" gutterBottom>
              3.B Specific personal information you provide to us may be
              sensitive in nature with regards to Health Information. We will
              ensure that we adhere to the Canadian PIPEDA (Personal Information
              Protection and Electronic Documents Act).
            </Typography>
            <Typography variant="body1" gutterBottom>
              We do not seek any sensitive personal data other than that which
              is reasonable and necessary for the purpose of completing the
              Disability Tax Credit Form. You are giving your explicit consent
              to collect, process, disclose, transfer, or store your or the
              individual you are legally representing, Sensitive Personal Data
              for the purpose of completing the Disability Tax Credit Form
              service.
            </Typography>
            <Typography variant="body1" gutterBottom>
              3.C We will NOT disclosure your personal data to any third party
              except for the sole purpose of processing a credit card payment by
              a 3rd party provider (Stripe) using a Canadian bank account.
            </Typography>
            <Typography variant="body1" gutterBottom>
              3.D Using our website, you agree to keep confidential and not to
              disclose to any other individual or individuals for all time the
              username and password related to your benefits2.ca account. While
              setting a password for your account while using the benefits2.ca
              website, it is important to note that this is proprietary
              information and belongs to Benefits2.
            </Typography>
            <Typography variant="h5" gutterBottom>
              4. WEBSITE COOKIES
            </Typography>
            <Typography variant="body1" gutterBottom>
              Use of cookies that store information on your computer may be used
              by our website to ensure we can provide necessary operation of our
              website service. If you set up your computer to dis-allow cookies,
              you may be unable to access or complete the service being offered
              by our website.
            </Typography>
            <Typography variant="h5" gutterBottom>
              5. OUR SECURITY AND DATA RETENTION
            </Typography>
            <Typography variant="body1" gutterBottom>
              We provide security measures to protect your data and personal
              information from external access by unauthorized individuals and
              against unlawful use of the data, accidental loss, intentional
              destruction of data, and any damage to your data. We will retain
              information for a 30-day period at which point we will delete all
              personal data and service records as indicated in section 2.A.
            </Typography>
            <Typography variant="h5" gutterBottom>
              6. ACCESSING AND UPDATING INFORMATION
            </Typography>
            <Typography variant="body1" gutterBottom>
              Individuals registered on our website are entitled to see any
              information held about them. As a result, you can ask for specific
              changes to your registered account up till 30 days after the
              initial purchase to ensure that the personal information and data
              we hold it is accurate and up to date. If you wish to do this,
              please contact us at{" "}
              <a href="mailto:privacy_officer@benefits2.ca">
                privacy_officer@benefits2.ca
              </a>
              . Our fee for this service is $8.00 plus applicable sales tax to
              meet our organizations costs in providing you with details of the
              information we maintain about your account.
            </Typography>
            <Typography variant="h5" gutterBottom>
              7. UPDATES TO OUR PRIVACY POLICY
            </Typography>
            <Typography variant="body1" gutterBottom>
              Any changes to our company privacy policy will be posted on our
              website and, if possible, through e-mail notification for
              registered users. Please ensure that you are kept notified with
              any future changes. By using our website benefits2.ca you are
              agreeing to be bound by the most recent version of this privacy
              policy.
            </Typography>
            <Typography variant="h5" gutterBottom>
              8. CONTACT
            </Typography>
            <Typography variant="body1" gutterBottom>
              Any comments and requests relating to Benefits2’s use of your
              personal information are welcomed and we can be contacted at{" "}
              <a href="mailto:privacy_officer@benefits2.ca">
                privacy_officer@benefits2.ca
              </a>
              .
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default PrivacyPolicyModal
