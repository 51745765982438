import {yupResolver} from "@hookform/resolvers/yup"
import {Paper, Typography, TextField, Button} from "@mui/material"
import _ from "lodash"
import React, {useEffect, useState} from "react"
import {LoadingScreen} from "@app-core/LoadingScreen"
import {useForm, Controller} from "react-hook-form"
import {useAuth} from "auth/AuthContext"
import {Link, useNavigate, useParams} from "react-router-dom"
import {authApi} from "data/api"
import * as yup from "yup"
import {useMessages} from "hooks/useMessages"
import {getItem} from "utils/localStorageService"

interface ResetPasswordState {
  password: string
  passwordConfirm: string
}
const schema = yup.object().shape({
  password: yup
    .string()
    .required("Please enter your password.")
    .min(8, "Password is too short - should be 8 chars minimum."),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
})

const defaultValues = {
  password: "",
  passwordConfirm: ""
}
export const ResetPasswordView: React.FC = () => {
  const {token} = useParams()
  const {user} = useAuth()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const {addMessage} = useMessages()
  const {control, formState, handleSubmit, reset} = useForm<ResetPasswordState>(
    {
      mode: "onChange",
      defaultValues,
      resolver: yupResolver(schema)
    }
  )

  const {isValid, dirtyFields, errors} = formState

  useEffect(() => {
    if (!token && !user) {
      navigate("/")
    }
  })
  const onSubmit = async ({password}: ResetPasswordState) => {
    try {
      setLoading(true)

      if (user && user.passwordResetNeeded) {
        await authApi.resetPassword(password, getItem("dtc_access_token") || "")
      } else {
        await authApi.resetPassword(password, token || "")
      }
      setLoading(false)
      addMessage({
        content: "Your password has been reset",
        severity: "success"
      })
      navigate("/sign-in")
    } catch (error) {
      setLoading(false)
      addMessage({
        content: "Error during password reset attempt. Please try again",
        severity: "error"
      })
      reset(defaultValues)
    }
  }

  if (loading) {
    return <LoadingScreen isLoading={true} />
  }

  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
      <Paper className="w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img
            style={{width: "375px"}}
            src="/static/images/logo.png"
            alt="logo"
          />

          <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
            Reset your password
          </Typography>
          <Typography className="font-medium">
            Create a new password for your account
          </Typography>

          <form
            name="registerForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="password"
              control={control}
              render={({field}) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Password"
                  type="password"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <Controller
              name="passwordConfirm"
              control={control}
              render={({field}) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Password (Confirm)"
                  type="password"
                  error={!!errors.passwordConfirm}
                  helperText={errors?.passwordConfirm?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <Button
              variant="contained"
              color="secondary"
              className=" w-full mt-4"
              aria-label="Register"
              disabled={_.isEmpty(dirtyFields) || !isValid}
              type="submit"
              size="large"
            >
              Reset your password
            </Button>

            <Typography
              className="mt-32 text-md font-medium"
              color="text.secondary"
            >
              <span>Return to</span>
              <Link className="ml-4" to="/sign-in">
                sign in
              </Link>
            </Typography>
          </form>
        </div>
      </Paper>
    </div>
  )
}

export default ResetPasswordView
