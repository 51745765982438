import Button, {ButtonProps} from "@mui/material/Button"
import {styled} from "@mui/material/styles"
import React from "react"

const CustomButton = styled(Button)<ButtonProps>(({theme}) => ({
  color: "#FFFFFF",
  // backgroundColor: "#FF9100",
  borderRadius: "20px"
}))

export const AppButton: React.FC<ButtonProps> = (props: ButtonProps) => {
  return <CustomButton variant="contained" {...props} />
}

export default AppButton
