import {AppBar, Box, GlobalStyles, Toolbar, Typography} from "@mui/material"
import {AppSuspense} from "@app-core/AppSuspense"
import {styled} from "@mui/material/styles"
import {alpha} from "@mui/material/styles"
import {TimeoutWarningModal} from "@app-core/TimeoutWarningModal"
import React, {memo, useContext, useEffect, useState} from "react"
import AppContext from "AppContext"
import {useRoutes, useNavigate, useLocation} from "react-router-dom"
import {useAuth} from "auth/AuthContext"
import jwtService from "auth/JwtService"
import {HeaderWrapper} from "components/Header2/HeaderWrapper"
import {addEventListeners, removeEventListeners} from "utils/eventListenerUtil"

const inputGlobalStyles = (
  <GlobalStyles
    styles={(theme) => ({
      html: {
        backgroundColor: `${theme.palette.background.default}!important`,
        color: `${theme.palette.text.primary}!important`
      },
      body: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary
      },
      /*  'code:not([class*="language-"])': {
        color: theme.palette.secondary.dark,
        backgroundColor:
          theme.palette.mode === 'light' ? 'rgba(255, 255, 255, .9)' : 'rgba(0, 0, 0, .9)',
        padding: '2px 3px',
        borderRadius: 2,
        lineHeight: 1.7,
      }, */
      "table.simple tbody tr th": {
        borderColor: theme.palette.divider
      },
      "table.simple thead tr th": {
        borderColor: theme.palette.divider
      },
      "a:not([role=button]):not(.MuiButtonBase-root)": {
        color: theme.palette.secondary.main,
        textDecoration: "underline",
        "&:hover": {}
      },
      "a.link, a:not([role=button])[target=_blank]": {
        background: alpha(theme.palette.secondary.main, 0.2),
        color: "inherit",
        borderBottom: `1px solid ${theme.palette.divider}`,
        textDecoration: "none",
        "&:hover": {
          background: alpha(theme.palette.secondary.main, 0.3),
          textDecoration: "none"
        }
      },
      '[class^="border"]': {
        borderColor: theme.palette.divider
      },
      '[class*="border"]': {
        borderColor: theme.palette.divider
      },
      '[class*="divide-"] > :not([hidden]) ~ :not([hidden])': {
        borderColor: theme.palette.divider
      },
      hr: {
        borderColor: theme.palette.divider
      },

      "::-webkit-scrollbar-thumb": {
        boxShadow: `inset 0 0 0 20px ${
          theme.palette.mode === "light"
            ? "rgba(0, 0, 0, 0.24)"
            : "rgba(255, 255, 255, 0.24)"
        }`
      },
      "::-webkit-scrollbar-thumb:active": {
        boxShadow: `inset 0 0 0 20px ${
          theme.palette.mode === "light"
            ? "rgba(0, 0, 0, 0.37)"
            : "rgba(255, 255, 255, 0.37)"
        }`
      }
    })}
  />
)
interface LayoutProps {
  children?: React.ReactNode
}

const layoutConfig = {
  title: "Layout",
  useMaxWidth: false,
  mode: "container",
  containerWidth: 1120,
  navbar: {
    display: true,
    style: "fixed"
  },
  toolbar: {
    display: true,
    style: "static",
    position: "below"
  },
  footer: {
    display: true,
    style: "fixed"
  },
  leftSidePanel: {
    display: true
  },
  rightSidePanel: {
    display: true
  }
}

const Root = styled("div")(({theme}) => ({
  backgroundColor: "#F0F0F0",
  ...(layoutConfig.mode === "boxed" && {
    clipPath: "inset(0)",
    maxWidth: `${layoutConfig.containerWidth}px`,
    margin: "0 auto",
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
  }),
  ...(layoutConfig.mode === "container" && {
    "& .container": {
      maxWidth: layoutConfig.useMaxWidth
        ? "100%"
        : `${layoutConfig.containerWidth}px`,
      width: "100%",
      margin: "0 auto"
    }
  })
}))
const Layout = (props: LayoutProps) => {
  const {user, isAuthenticated, isPhysicianUser} = useAuth()
  const appContext = useContext(AppContext)
  const navigate = useNavigate()
  const location = useLocation()
  const {routes} = appContext

  const [isWarningModalOpen, setWarningModalOpen] = useState(false)

  useEffect(() => {
    const showWarning = () => {
      if (isAuthenticated) {
        setWarningModalOpen(true)
      }
    }
    const createTimeout1 = () =>
      setTimeout(() => {
        showWarning()
      }, 900000)

    const createTimeout2 = () =>
      setTimeout(() => {
        // Implement a sign out function here
        setWarningModalOpen(false)
        jwtService.logout()
      }, 60000)

    const listener = () => {
      if (!isWarningModalOpen) {
        clearTimeout(timeout)
        timeout = createTimeout1()
      }
    }

    // Initialization
    let timeout = isWarningModalOpen ? createTimeout2() : createTimeout1()
    addEventListeners(listener)

    // Cleanup
    return () => {
      removeEventListeners(listener)
      clearTimeout(timeout)
    }
  }, [isAuthenticated, isWarningModalOpen])

  const blankLayoutPaths = [
    "sign-in",
    "forgot-password",
    "reset-password",
    "sign-out",
    "sign-up",
    "physician-access",
    "walk-through"
  ]
  const hideHeaderAndFooter = blankLayoutPaths.some((l) =>
    location.pathname.includes(l)
  )
  const goToPrevApps = () => {
    navigate("/previous-applications")
  }

  const onSignIn = () => {
    navigate("/sign-in", {state: {redirectUrl: location.pathname}})
  }

  const onSignOut = () => {
    if (isWarningModalOpen) {
      setWarningModalOpen(false)
    }
    navigate("/sign-out")
  }

  const onPhysicianSignIn = () => {
    navigate("/physician-access")
  }

  const onWalkThrough = () => {
    navigate("/walk-through")
  }

  layoutConfig.useMaxWidth = location.pathname.includes("help-center")

  return (
    <>
      {inputGlobalStyles}
      <Root id="dtc-layout" className="w-full flex">
        <div className="flex flex-col flex-auto min-w-0">
          <main
            id="dtc-main"
            className="container mb-7 flex flex-col flex-auto min-h-full min-w-0 relative"
          >
            {!hideHeaderAndFooter && (
              <HeaderWrapper
                signIn={onSignIn}
                signOut={onSignOut}
                physicianSignIn={onPhysicianSignIn}
                authenticated={isAuthenticated}
                isPhysicianLoggedIn={isPhysicianUser}
                user={user}
                goToPrevApps={goToPrevApps}
                walkThrough={onWalkThrough}
              />
            )}
            <div className="flex flex-col flex-auto min-h-0 relative z-10">
              <AppSuspense>{useRoutes(routes)}</AppSuspense>
              {props.children}
            </div>

            {!hideHeaderAndFooter && (
              <AppBar
                id="dtc-footer"
                className="z-20 sticky bottom-0"
                color="default"
                sx={{marginTop: "70px"}}
              >
                <Toolbar className="container px-8 sm:px-12 py-0 flex items-center overflow-x-auto">
                  <Box
                    component="footer"
                    sx={{
                      py: 3,
                      px: 2,
                      mt: "auto",
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "lightgray",
                      position: "fixed",
                      textAlign: "center"
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "#191919"
                      }}
                    >
                      {"© "}
                      All Rights Reserved For Benefits2
                    </Typography>
                  </Box>
                </Toolbar>
              </AppBar>
            )}
          </main>
        </div>
      </Root>
      <TimeoutWarningModal
        open={isWarningModalOpen}
        onLogOffCall={() => onSignOut()}
        onStayLoggedIn={() => setWarningModalOpen(false)}
      />
    </>
  )
}

export default memo(Layout)
