export enum IQuestionSection {
  Hearing = "Hearing",
  Speaking = "Speaking",
  Vision = "Vision",
  Walking = "Walking",
  Eliminating = "Eliminating",
  EatingPreparingFood = "EatingPreparingFood",
  Dressing = "Dressing",
  MentalFunctions = "MentalFunctions",
  LifeSustainingTherapy = "LifeSustainingTherapy",
  ModerateImpairment = "ModerateImpairment"
}
