import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography
} from "@mui/material"
import React from "react"
import {AppButton} from "@app-core/AppButton"
import {SelectOne} from "components/ApplicationQuestions/SelectOne"
import {IQuestion} from "models/IQuestion"
import {IQuestionSection} from "models/IQuestionSection"
import {IQuestionType} from "models/IQuestionType"

interface EligibilityAppProcessSelectionProps {
  selectedProcess: string
  allowRepresentativeContact: boolean
  onSelectedProcess: (answer: string) => void
  onAllowRepresentativeContactClick: (answer: boolean) => void
  onConfirmClick: () => void
  onBack: () => void
}

export const EligibilityAppProcessSelection: React.FC<
  EligibilityAppProcessSelectionProps
> = (props: EligibilityAppProcessSelectionProps) => {
  const {
    selectedProcess,
    allowRepresentativeContact,
    onConfirmClick,
    onBack,
    onSelectedProcess,
    onAllowRepresentativeContactClick
  } = props
  const previouslyDenied = "I am trying again as I was previously denied"

  const question: IQuestion = {
    id: "",
    text: "Choose only one:",
    type: IQuestionType.SelectOne,
    section: IQuestionSection.Hearing,
    answerOptions: [
      "This is my first time applying for the DTC",
      "I'm reapplying as my eligibility has lapsed",
      "I am trying again as I was previously denied"
    ],
    questionMapTag: "",
    required: true
  }

  const onSelect = (answer: string) => {
    onSelectedProcess(answer)
    onAllowRepresentativeContactClick(answer === previouslyDenied)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onAllowRepresentativeContactClick(event.target.checked)
  }

  return (
    <Box padding={6}>
      <Box padding={4}>
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"center"}
          flexDirection="column"
          marginBottom="20px"
        >
          <Typography
            fontSize={"28px"}
            fontWeight="600"
            variant="h6"
            component="div"
          >
            Please Confirm Your Disability Tax Credit Status
          </Typography>
        </Box>
        <Box>
          <SelectOne
            onSelectAnswer={onSelect}
            question={question}
            answer={selectedProcess}
          />
        </Box>
      </Box>
      {selectedProcess === previouslyDenied && (
        <Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allowRepresentativeContact}
                  onChange={handleChange}
                />
              }
              label="We recommend that you speak with our legal expert to review your application prior to submitting to the CRA website. This is an initial free 30-minute review of your application to see if more detailed information is required. Would you like to give permission for a representative to review your completed application and contact you to setup a meeting? If you choose not to have a representative contact you, please remove the selected checkmark and proceed as normal to complete your application."
            />
          </FormGroup>
        </Box>
      )}
      <Box
        display={"flex"}
        alignItems="center"
        justifyContent="flex-end"
        marginTop={4}
        padding={4}
      >
        <Button onClick={onBack}>Back</Button>
        <AppButton
          onClick={onConfirmClick}
          disabled={selectedProcess === ""}
          sx={{
            backgroundColor: "#6A75B9",
            minWidth: "150px",
            minHeight: "44px"
          }}
          variant="contained"
        >
          Confirm
        </AppButton>
      </Box>
    </Box>
  )
}

export default EligibilityAppProcessSelection
