import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from "@mui/material"
import React from "react"

export interface IUserDropdown {
  title: string
  onSignOut: () => void
  onPrevApps: () => void
  onHomeClicked: () => void
}

export const UserDropdown: React.FC<IUserDropdown> = (props: IUserDropdown) => {
  const {title, onSignOut, onPrevApps, onHomeClicked} = props
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  )
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }
  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const prevAppsClicked = () => {
    onPrevApps()
    handleCloseUserMenu()
  }

  return (
    <Box>
      <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
        <Avatar sx={{mr: "5px"}} alt="User Avatar">
          <AccountCircleIcon />
        </Avatar>
        {title}
      </IconButton>
      <Menu
        sx={{mt: "45px"}}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={onHomeClicked}>
          <Typography textAlign="center">Home</Typography>
        </MenuItem>
        <MenuItem onClick={prevAppsClicked}>
          <Typography textAlign="center">View Previous Applications</Typography>
        </MenuItem>
        <MenuItem onClick={onSignOut}>
          <Typography textAlign="center">Sign Out</Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default UserDropdown
