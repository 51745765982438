import ApiClient, {IApiClient} from "./ApiClient"

interface CreatePaymentIntentRequest {
  plan: string
  receipt_email: string
  promoCode: string
  state: string
}
interface CreatePaymentIntentResponse {
  clientSecret: string
}

interface CreateCheckoutSessionRequest {
  receipt_email: string
  selectedSections: string[]
  applicationProcess: string
  allowRepresentativeContact: boolean
  plan: string
  priceId: string
}
interface CreateCheckoutSessionResponse {
  url: string
}

interface VerifyPromoCodeRequest {
  code: string
}
interface VerifyPromoCodeResponse {
  discount: number
}
const urlSubPath = "/create-payment-intent"
export default class PaymentApi {
  private apiClient: IApiClient

  public constructor() {
    this.apiClient = new ApiClient(process.env.REACT_APP_API_BASE_URL || "")
  }

  public createPaymentIntent = async (
    payload: CreatePaymentIntentRequest
  ): Promise<string> => {
    const data = await this.apiClient.post<
      CreatePaymentIntentRequest,
      CreatePaymentIntentResponse
    >(`${urlSubPath}`, payload)
    return data.clientSecret
  }
  public createCheckoutSession = async (
    payload: CreateCheckoutSessionRequest
  ): Promise<string> => {
    const data = await this.apiClient.post<
      CreateCheckoutSessionRequest,
      CreateCheckoutSessionResponse
    >("create-checkout-session", payload)
    return data.url
  }

  public verifyPromoCode = async (
    payload: VerifyPromoCodeRequest
  ): Promise<number> => {
    const data = await this.apiClient.post<
      VerifyPromoCodeRequest,
      VerifyPromoCodeResponse
    >(`verifyPromoCode`, payload)
    return data.discount
  }
}
