import React, {useState, useEffect, useContext} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import {LoadingScreen} from "@app-core/LoadingScreen"
import jwtService from "auth/JwtService"
import {useMessages} from "hooks/useMessages"
import {IAppUser} from "models/IAppUser"
import {getItem} from "utils/localStorageService"
interface IAuthContext {
  user?: IAppUser
  isAuthenticated: boolean
  isPhysicianUser: boolean
}

interface IAuthProvider {
  children: React.ReactNode
}

const AuthContext = React.createContext<IAuthContext>({} as IAuthContext)

export const AuthProvider = ({children}: IAuthProvider) => {
  const auth = useProvideAuthContext()

  if (auth.waitAuthCheck) {
    return <LoadingScreen isLoading={true} />
  }

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

const useProvideAuthContext = () => {
  const [user, setUser] = useState<IAppUser | undefined>(undefined)
  const {addMessage} = useMessages()
  const navigate = useNavigate()
  const location = useLocation()
  const [waitAuthCheck, setWaitAuthCheck] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isPhysicianUser, setIsPhysicianUser] = useState(false)

  useEffect(() => {
    jwtService.on("onAutoLogin", () => {
      /**
       * Sign in and retrieve user data with stored token
       */
      jwtService
        .signInWithToken()
        .then((user) => {
          success(user, location.pathname)
        })
        .catch((error) => {
          pass(error.message)
        })
    })

    jwtService.on("onLogin", (user: IAppUser, redirectUrl?: string) => {
      success(user, redirectUrl)
    })

    jwtService.on("onPhysicianLogin", (appCode: string) => {
      physicianLoginSuccess(appCode)
    })

    jwtService.on("onLogout", () => {
      pass("Signed out")
      setUser(undefined)
    })

    jwtService.on("onAutoLogout", (message: string) => {
      pass(message)
      setUser(undefined)
    })

    jwtService.on("onNoAccessToken", () => {
      pass()
    })

    jwtService.init()
    function success(user: IAppUser, redirectUrl?: string) {
      setUser(user)
      setWaitAuthCheck(false)
      setIsAuthenticated(true)

      if (user.passwordResetNeeded) {
        navigate(`/reset-password/${getItem("dtc_access_token")}`)
        return
      }

      if (redirectUrl && redirectUrl !== "/") {
        navigate(redirectUrl)
      } else {
        navigate("/")
      }
    }

    function physicianLoginSuccess(appCode: string) {
      setIsPhysicianUser(true)
      setIsAuthenticated(true)

      navigate(`/application-viewer/${appCode}`)
    }

    function pass(message?: string) {
      if (message) {
        addMessage({
          content: message,
          severity: "error"
        })
      }
      setWaitAuthCheck(false)
      setIsAuthenticated(false)
      // navigate("/")
    }
  }, [])
  return {
    user,
    waitAuthCheck,
    isAuthenticated,
    isPhysicianUser
  }
}

export const useAuth = (): IAuthContext => useContext(AuthContext)
