export enum IQuestionType {
  SelectOne = "SelectOne",
  SelectAllThatApply = "SelectAllThatApply",
  SelectAllThatApplyWithFreeText = "SelectAllThatApplyWithFreeText",
  SelectAllDiagnosesThatApply = "SelectAllDiagnosesThatApply",
  YesNoGroups = "YesNoGroups",
  NumberTextBoxes = "NumberTextBoxes",
  VisualAcuity = "VisualAcuity",
  SelectOneWithSelectAll = "SelectOneWithSelectAll",
  SelectYear = "SelectYear"
}
