import {AuthLoginRequest} from "data/types/AuthLoginRequest"
import {AuthLoginResponse} from "data/types/AuthLoginResponse"
import {AuthSignUpRequest} from "data/types/AuthSignUpRequest"
import {PhysicianAccessRequest} from "data/types/PhysicianAccessRequest"
import {PhysicianLoginRequest} from "data/types/PhysicianLoginRequest"
import {PhysicianLoginResponse} from "data/types/PhysicianLoginResponse"
import ApiClient, {IApiClient} from "./ApiClient"
import {ResetPasswordRequest} from "data/types/ResetPasswordRequest"
import {SendOtcRequest} from "data/types/SendOtcRequest"
import {SuccessResponse} from "data/types/SuccessResponse"
import {VerifyOtcRequest} from "data/types/VerifyOtcRequest"

const urlSubPath = "/auth"
export default class AuthApi {
  private apiClient: IApiClient

  public constructor() {
    this.apiClient = new ApiClient(process.env.REACT_APP_API_BASE_URL || "")
  }

  public login = async (
    payload: AuthLoginRequest
  ): Promise<AuthLoginResponse> => {
    const data = await this.apiClient.post<AuthLoginRequest, AuthLoginResponse>(
      `${urlSubPath}/sign-in`,
      payload
    )
    return data
  }

  public loginWithToken = async (
    payload: string
  ): Promise<AuthLoginResponse> => {
    const data = await this.apiClient.post<{token: string}, AuthLoginResponse>(
      `${urlSubPath}/sign-in/token`,
      {token: payload}
    )
    return data
  }

  public signUp = async (
    payload: AuthSignUpRequest
  ): Promise<AuthLoginResponse> => {
    const data = await this.apiClient.post<
      AuthSignUpRequest,
      AuthLoginResponse
    >(`${urlSubPath}/sign-up`, payload)
    return data
  }

  public sendVerificationCode = async (
    payload: SendOtcRequest
  ): Promise<SuccessResponse> => {
    const data = await this.apiClient.post<SendOtcRequest, SuccessResponse>(
      `${urlSubPath}/otc`,
      payload
    )
    return data
  }

  public verifyCode = async (
    payload: VerifyOtcRequest
  ): Promise<SuccessResponse> => {
    const data = await this.apiClient.post<SendOtcRequest, SuccessResponse>(
      `${urlSubPath}/otc/verify`,
      payload
    )
    return data
  }

  public forgotPassword = async (payload: string): Promise<SuccessResponse> => {
    const data = await this.apiClient.post<SendOtcRequest, SuccessResponse>(
      `${urlSubPath}/forgot-password`,
      {email: payload}
    )
    return data
  }

  public resetPassword = async (
    password: string,
    token: string
  ): Promise<SuccessResponse> => {
    const data = await this.apiClient.post<
      ResetPasswordRequest,
      SuccessResponse
    >(`${urlSubPath}/reset-password`, {password, token})
    return data
  }

  public sendPhysicianAccessCode = async (
    payload: PhysicianAccessRequest
  ): Promise<SuccessResponse> => {
    const data = await this.apiClient.post<
      PhysicianAccessRequest,
      SuccessResponse
    >(`${urlSubPath}/physician-access-request`, payload)
    return data
  }

  public physicianLogin = async (
    payload: PhysicianLoginRequest
  ): Promise<PhysicianLoginResponse> => {
    const data = await this.apiClient.post<
      PhysicianLoginRequest,
      PhysicianLoginResponse
    >(`${urlSubPath}/physician-login`, payload)
    return data
  }
}
