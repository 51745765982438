import {AppButton} from "@app-core/AppButton"
import BathroomImage from "assets/images/bathroom.svg"
import DressingImage from "assets/images/dressing.svg"
import EatingPreparingFoodImage from "assets/images/eatingPreparingFood.svg"
import CheckRoundedIcon from "@mui/icons-material/CheckRounded"
import HearingImage from "assets/images/hearing.svg"
import CircleIcon from "@mui/icons-material/Circle"
import LifeSustainingTherapyImage from "assets/images/lifeSustainingTherapy.svg"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import MentalFunctionsImage from "assets/images/mentalFunctions.svg"
import {
  Box,
  Button,
  ButtonProps,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material"
import ModerateImpairmentImage from "assets/images/moderateImpairment.svg"
import {styled} from "@mui/material/styles"
import SpeakingImage from "assets/images/speaking.svg"
import React, {useState, useEffect} from "react"
import VisionImage from "assets/images/vision.svg"
import WalkingImage from "assets/images/walking.svg"
import {IEligibilityQuestion} from "models/IEligibilityQuestion"

type AnswerButtonProps = ButtonProps & {
  selectedanswer: string
  anscheck: string
}
const AnswerButton = styled(Button)<AnswerButtonProps>(
  ({selectedanswer, anscheck}) => ({
    borderRadius: "17px",
    height: "70px",
    fontSize: "24px",
    color: "#707070",
    justifyContent: "unset",
    border: `${
      selectedanswer === anscheck ? "4px solid #6a75b9" : "1px solid #707070"
    }`,
    ":hover": {
      border: "1px solid #6a75b9"
    }
  })
)
interface EligibilityQuestionProps {
  question: IEligibilityQuestion
  questionNum: number
  totalQuestions: number
  answer: string
  onSelectAnswer: (answer: string) => void
  onNext: () => void
  onBack: () => void
}

export const EligibilityQuestion: React.FC<EligibilityQuestionProps> = (
  props: EligibilityQuestionProps
) => {
  const {
    question,
    questionNum,
    totalQuestions,
    answer,
    onSelectAnswer,
    onNext,
    onBack
  } = props
  const [selectedAnswer, setSelectedAnswer] = useState<string>("")

  useEffect(() => {
    setSelectedAnswer(answer)
  }, [answer])

  const onNextClick = () => {
    onNext()
  }

  const onYesClick = () => {
    onSelectAnswer("Yes")
  }

  const onNoClick = () => {
    onSelectAnswer("No")
  }

  const setImageUri = () => {
    switch (question.type) {
      case "Hearing":
        return HearingImage
      case "Speaking":
        return SpeakingImage
      case "Vision":
        return VisionImage
      case "Walking":
        return WalkingImage
      case "Eliminating":
        return BathroomImage
      case "EatingPreparingFood":
        return EatingPreparingFoodImage
      case "Dressing":
        return DressingImage
      case "MentalFunctions":
        return MentalFunctionsImage
      case "LifeSustainingTherapy":
        return LifeSustainingTherapyImage
      case "ModerateImpairment":
        return ModerateImpairmentImage
      default:
        return ""
    }
  }

  return (
    <Box padding={4}>
      <Box display={"flex"} alignItems="center" justifyContent="flex-start">
        <Box
          className="text-xl"
          fontWeight={900}
          sx={{
            width: "90px",
            backgroundColor: "#ebebeb",
            borderRadius: "22px",
            height: "40px",
            alignItems: "center",
            display: "flex",
            justifyContent: "center"
          }}
        >
          {`${questionNum}/${totalQuestions}`}
        </Box>
      </Box>
      <Box
        display={"flex"}
        alignItems="center"
        justifyContent={"center"}
        flexDirection="column"
      >
        {setImageUri() !== "" && (
          <Box>
            <img src={setImageUri()} alt={question.title} />
          </Box>
        )}
        <Box>
          <Typography variant="h6" gutterBottom component="div">
            {question.title}
          </Typography>
        </Box>
      </Box>
      <Box padding={3}>
        <Box sx={{minHeight: "230px"}}>
          <Typography
            className="text-lg"
            variant="h6"
            gutterBottom
            component="div"
          >
            {question.question}
          </Typography>
          {question.options && (
            <List dense={true}>
              {question.options.map((val) => (
                <ListItem key={val}>
                  <ListItemIcon>
                    <CircleIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        className="text-lg"
                        variant="h6"
                        gutterBottom
                        component="div"
                      >
                        {val}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <AnswerButton
              selectedanswer={selectedAnswer}
              anscheck={"Yes"}
              onClick={onYesClick}
              fullWidth
              variant="outlined"
              startIcon={
                <CheckRoundedIcon
                  sx={{
                    fontSize: "38px !important",
                    color: `${selectedAnswer === "Yes" ? "#66bb6a" : ""}`
                  }}
                />
              }
            >
              Yes
            </AnswerButton>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <AnswerButton
              selectedanswer={selectedAnswer}
              anscheck={"No"}
              onClick={onNoClick}
              fullWidth
              variant="outlined"
              startIcon={
                <CloseRoundedIcon
                  sx={{
                    fontSize: "38px !important",
                    color: `${selectedAnswer === "No" ? "#ED385B" : ""}`
                  }}
                />
              }
            >
              No
            </AnswerButton>
          </Grid>
        </Grid>
      </Box>
      <Box
        display={"flex"}
        alignItems="center"
        justifyContent="flex-end"
        marginTop={4}
      >
        <Button onClick={onBack}>Back</Button>
        <AppButton
          onClick={onNextClick}
          sx={{
            backgroundColor: "#6A75B9",
            minWidth: "150px",
            minHeight: "44px"
          }}
          variant="contained"
          disabled={selectedAnswer === ""}
        >
          Next
        </AppButton>
      </Box>
    </Box>
  )
}

export default EligibilityQuestion
