import React, {useEffect, useState} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import AppPageCarded from "@app-core/AppPageCarded"
import {ErrorModal} from "@app-core/ErrorModal"
import {LoadingScreen} from "@app-core/LoadingScreen"
import {useAuth} from "auth/AuthContext"
import jwtService from "auth/JwtService"
import {PaymentCompleted} from "components/PaymentCompleted"
import {applicationApi} from "data/api"

export const PaymentCompletionView: React.FC = () => {
  const [clientCode, setClientCode] = useState("")
  const [plan, setPlan] = useState("")
  const [hasError, setHasError] = useState(false)
  const [processing, setProcessing] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const {user} = useAuth()

  useEffect(() => {
    if (clientCode) {
      return
    }
    const session_id = new URLSearchParams(location.search).get("session_id")
    const token = new URLSearchParams(location.search).get("token")

    const tokenLogin = async () => {
      try {
        await jwtService.signInWithToken(
          token || "",
          `${location.pathname}?session_id=${session_id}`
        )
      } catch {
        setHasError(true)
      }
    }

    const sa = async () => {
      try {
        setProcessing(true)
        const results = await applicationApi.startDtcApplicationV2(
          session_id || ""
        )
        if (results.plan === "AssistanceRequired") {
          setClientCode(results.walkthroughCode as string)
        } else {
          setClientCode(results.applicationCode)
        }
        setPlan(results.plan)
        setProcessing(false)
      } catch {
        setHasError(true)
      }
    }

    if (session_id) {
      if (user) {
        if (!processing) {
          sa()
        }
      } else {
        tokenLogin()
      }
    } else {
      setHasError(true)
    }
  }, [clientCode, location, processing, user])

  if (hasError) return <ErrorModal error={`Issue Loading Payment Completion`} />
  if (clientCode === "") return <LoadingScreen isLoading={true} />

  const completeSession = () => {
    navigate(`/application?code=${clientCode}`)
  }

  const print = () => {
    window.print()
  }

  const signOut = () => {
    navigate("/sign-out")
  }

  return (
    <AppPageCarded scroll="page">
      <PaymentCompleted
        code={clientCode}
        plan={plan}
        onCompleteSession={completeSession}
        onSignOut={signOut}
        onPrint={print}
      />
    </AppPageCarded>
  )
}

export default PaymentCompletionView
