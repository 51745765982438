import ApiClient, {IApiClient} from "./ApiClient"
import {SuccessResponse} from "data/types/SuccessResponse"
import {ISupportMsg} from "models/ISupportMsg"

const urlSubPath = "/support-request"
export default class SupportApi {
  private apiClient: IApiClient

  public constructor() {
    this.apiClient = new ApiClient(process.env.REACT_APP_API_BASE_URL || "")
  }

  public createSupportRequest = async (req: ISupportMsg) => {
    await this.apiClient.post<ISupportMsg, SuccessResponse>(
      `${urlSubPath}`,
      req
    )
    return
  }
}
