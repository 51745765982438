export const diagnoses = [
  "Accommodative Infacility/Instability",
  "Achalasia",
  "Achilles Tendinopathy",
  "ACOS",
  "Acute Myocardial Infarction",
  "ADD",
  "Addison's Disease",
  "ADHD",
  "ADHD - Combined Type",
  "Adjustment Disorder",
  "Age Related Macular Degeneration",
  "Agoraphobia",
  "AIDS",
  "Alcoholism",
  "ALS",
  "Alzheimer's Disease",
  "Amyloidosis",
  "Amylotrophic Lateralizing Sclerosis",
  "Anemia",
  "Angina",
  "Ankylosing Spondylitis",
  "Anorexia",
  "Anorexia Nervosa",
  "Anoxic Brain Injury",
  "Anxiety",
  "Anxiety Disorder",
  "Anxiety Neurosis",
  "Aortic Stenosis",
  "Aphasia",
  "Arm Paresthesias",
  "Arthralgia",
  "Arthritis",
  "Asbestosis",
  "Asperger's Syndrome",
  "Asthma",
  "Asthma COPD Overlap Syndrome",
  "Astigmatism",
  "Ataxia",
  "Atrial Fibrillation",
  "Attention Deficit Disorder (ADD)",
  "Attention Deficit Hyperactive Disorder (ADHD)",
  "Autism Spectrum Disorder",
  "Auto-Immune Disorder",
  "Baker's Cyst",
  "Bell's Palsy",
  "Bipolar Disorder",
  "Birth Defect",
  "Bladder Cancer",
  "Bladder Disorder",
  "Bladder Incontinence",
  "Blind",
  "Bone Cancer Lower Limb",
  "Bone Spurs",
  "Bony Lesions",
  "Borderline Intellectual Functioning",
  "Borderline Personality Disorder",
  "Bowel Cancer",
  "Bowel Disorder",
  "Bowel Incontinence",
  "Brain Cancer",
  "Brain Injury",
  "Brain Tumor",
  "Bulging Disc",
  "Bulimia",
  "Bunion",
  "Cachexia",
  "Calcaneal Enthesophyte",
  "Cardiac Functional Class Of 4/Iv Or An Injection Fraction Of 20% Or Less",
  "Carotid Bruits",
  "Carpal Tunnel Syndrome",
  "Cataracts",
  "Celiac",
  "Cerebral Contusion",
  "Cerebral Palsy",
  "Cerebral Vascular Disease",
  "Cerebrovascular Disease",
  "Cervical Stenosis",
  "Charcot Marie Tooth Disease",
  "Chemical Sensitivities",
  "Childhood Absence Epilepsy",
  "Childhood Apraxia of Speech",
  "Choledocholithiasis",
  "Cholesteatoma",
  "Chronic Bronchitis",
  "Chronic Diarrhea",
  "Chronic Fatigue Syndrome",
  "Cirrhosis",
  "Claudication",
  "Clawed Foot",
  "Club Foot",
  "Cognitive Disorder",
  "Colitis",
  "Colon Cancer",
  "Colorectal Cancer",
  "Colostomy",
  "Complex PTSD",
  "Complex Regional Pain Syndrome (Lower Limb)",
  "Complex Regional Pain Syndrome (CRPS)",
  "Concussion",
  "Conductive Hearing Loss",
  "Congenital Abnormality",
  "Congenital Hip Dysplasia",
  "Congestive Heart Failure",
  "Connective Tissue Disorder",
  "COPD",
  "Coronary Artery Disease",
  "Covid",
  "CPPD Arthropathy",
  "Creukfeld Jacob Disease (Mad Cow Disease)",
  "Cris Du Chat Syndrome",
  "Crohn's Disease",
  "Crush Injury",
  "Cystic Fibrosis",
  "Cystocele",
  "Cystorectal Fistula",
  "DDD",
  "Deformation",
  "Deformed",
  "Degenerative Changes",
  "Degenerative Disc Disease",
  "Degenerative End Plate Sclerosis",
  "Degenerative Joint Changes",
  "Dementia",
  "Denervation Changes",
  "Depression",
  "Developmental Trauma Disorder",
  "Developmentally Delayed",
  "Deviated Septum",
  "Diabetes",
  "Diabetes Mellitus",
  "Diffuse Allodynia",
  "Diffuse Back Pain",
  "Disc Osteophyte",
  "Disc Protrusion",
  "Disc Vacuum Phenomenon",
  "Diverticulitis",
  "Down's Syndrome",
  "Dumping Syndrome",
  "Duodenal Atresia",
  "Dwarfism/Achondroplasia",
  "Dysarthria (due to muscle weakness)",
  "Dyslexia",
  "Dyslipidemia",
  "Dysphagia",
  "Dysplasia",
  "Dystonia",
  "Eating Disorder",
  "Ehlers-Danlos Syndrome (EDS)",
  "Emphysema",
  "Enthesopathy",
  "Enucleation of The Eye",
  "Epilepsy",
  "Erbs Palsy",
  "Esophageal Stricture",
  "Expressive Language Display",
  "Extreme Stutter",
  "Family Hyperaldosteronism 1",
  "Fetal Alcohol Syndrome",
  "Fetal Alcohol Syndrome Disorder (FASD)",
  "Fibromatosis",
  "Fibromyalgia",
  "Foraminal Narrowing",
  "Ganglion Cyst",
  "Gastritis",
  "General Anxiety Disorder",
  "Glaucoma",
  "Global Developmental Delay",
  "Gout",
  "Graves Disease",
  "Greater Trochanter Pain Syndrome",
  "Guillain-Barre Syndrome",
  "Hand Tremors",
  "Heart Attack",
  "Heart Block",
  "Heart Defect",
  "Heart Disease",
  "Heel Spurs",
  "Hemi-Pelvectomy",
  "Hepatitis",
  "Herniated Discs",
  "Hip Disarticulation",
  "Hip Replacement",
  "HIV Positive",
  "Huntington's Disease",
  "Hypermobility Syndrome",
  "Hypertension",
  "Hypoglycemia",
  "Hypothyroidism",
  "Hypotonia",
  "IBD",
  "IBS",
  "Idiopathic Hypersomnia",
  "Ileitis",
  "Ileostomy",
  "Iliolumbar Ligament Syndrome",
  "Iliolumbar Pain Syndrome",
  "Inflammatory Bowel Disease",
  "Interstitial Cystitis",
  "Irritable Bowel Syndrome",
  "Ischemic Heart Disease",
  "Juvenile Diabetes",
  "Kidney Transplant",
  "Klinefelter Syndrome",
  "Knee Replacement",
  "Kyphosis",
  "Lack Of Sensation",
  "Laryngeal Cancer",
  "Lateral Meniscal Extrusion",
  "Learning Disability",
  "Leg Length Discrepancy",
  "Leg Paresthesias",
  "Lesions On The Brain",
  "Lesions On The Spinal Cord",
  "Leukemia",
  "Lewey Body Dementia",
  "Liver Cancer",
  "Liver Cirrhosis",
  "Liver Disease",
  "Liver Transplant",
  "Loeys-Dietz Syndrome",
  "Lower Limb Amputation",
  "Lower Limb Crush Injury",
  "Lower Limb Neuropathy",
  "Lung Cancer",
  "Lupus",
  "Lyme Disease",
  "Macular Degeneration",
  "Major Depressive Disorder",
  "Manic Depressive",
  "Marfan's Syndrome",
  "Meniere's Disease",
  "Meningitis",
  "Mental Illness",
  "Mesothelioma",
  "Migraine Headaches",
  "Mitral Regurgitation",
  "Mitral Stenosis",
  "Morbid Obesity",
  "Motor Vehicle Accident (MVA)",
  "Multiple Personality Disorder",
  "Multiple Sclerosis",
  "Myalgic Encephalomyelitis",
  "Myelomeningocele",
  "Myofascial Pain Syndrome",
  "Myofascial Tenderness",
  "Myopathy",
  "Nerve Compression",
  "Nerve Damage",
  "Neurofibromatosis",
  "Neurogenic Bladder",
  "Neuropathic Pain",
  "Neuropathy",
  "Noise Induced Hearing Loss",
  "Obesity",
  "Obsessive Compulsive Disorder (OCD)",
  "OCD",
  "Oculomotor Dysfunction",
  "Oppositional Defiant Disorder",
  "Osteoarthritis",
  "Osteopenia",
  "Osteoporosis",
  "Overactive Bladder",
  "Overflow Incontinence",
  "Painful Bladder Syndrome",
  "Pancreatic Cancer",
  "Pancreatitis",
  "Panic Disorder",
  "Paralysis",
  "Parapelvic Renal Cysts",
  "Paraplegia",
  "Paraplegic",
  "Parethesia",
  "Parethesias",
  "Parkinson's Disease",
  "Patellofemoral Syndrome",
  "Pelvic Organ Prolapse",
  "Percussive Noise Hearing Loss",
  "Periarticular Calcifications",
  "Peripheral Arterial Disease (PAD)",
  "Peripheral Vascular Disease (PVD)",
  "Plantar Fasciitis",
  "Polio",
  "Polycystic Kidney Disease",
  "Polycystic Ovary Syndrome",
  "Polycythemia",
  "Polymyositis",
  "Post Traumatic Stress Disorder",
  "Post Traumatic Stress Syndrome",
  "Postpartum Depression",
  "Proctitis",
  "Progressive Supranuclear Palsy",
  "Pseudobulbar Palsy",
  "Psoriatic Arthritis",
  "Psychosis",
  "Psychotic Episodes",
  "PTSD",
  "Pulmonary Fibrosis",
  "Quadriplegic",
  "Radial Tear",
  "Rapid Gastric Emptying",
  "Raynaud's Disease",
  "Raynaud's Phenomenon",
  "Raynaud's Syndrome",
  "Reactive Attahment Disorder",
  "Rectocele",
  "Rectovaginal Fistula",
  "Reflex Sympathetic Dystrophy",
  "Renal Failure",
  "Retinitis Pigmentosa",
  "Retrolisthesis",
  "Rheumatoid Arthritis",
  "Ruptured Achilles Tendon",
  "Schizophrenia",
  "Sciatica",
  "Scleroderma",
  "Scoliosis",
  "Seizure Disorder",
  "Sensorineural Hearing Loss",
  "Septated Fluid Collection",
  "Seronegative Spondyloarthropathy",
  "Shoulder Replacement",
  "Sinusitis",
  "Sjogren's Syndrome",
  "Social Anxiety",
  "Speech Disorder",
  "Speech Disturbance",
  "Spina Bifida",
  "Spinal Chord Trauma",
  "Spinal Stenosis",
  "Spondylitis",
  "Spondylolisthesis",
  "Spondylosis",
  "Stomach Cancer",
  "Stress Incontinence",
  "Stroke",
  "Suprapatellar Bursal Effusion",
  "Systemic Lupus (SLE)",
  "Tardive Dyskinesia",
  "Tendinosis",
  "Tension Headaches",
  "Tetraplegia",
  "Thoracic Outlet Syndrome",
  "Throat Cancer",
  "Thyroid Cancer",
  "Torn Achilles Tendon",
  "Torticollis",
  "Traumatic Brain Injury",
  "Traumatic Spine Injury",
  "Trichotillomania",
  "Tricuspid Regurgitation",
  "Trigeminal Neuralgia",
  "Trisomy 13 (Patau Syndrome)",
  "Trisomy 18",
  "Trisomy 18 (Edwards Syndrome)",
  "Trisomy 21",
  "Trisomy 21 (Down Syndrome)",
  "Trochanteric Bursitis",
  "Tuberculosis",
  "Type 1 Diabetic",
  "Type 2 Diabetic",
  "Ulcerative Colitis",
  "Upper Limb Amputation",
  "Upper Limb Neuropathy",
  "Urge incontinence",
  "Urinary Incontinence",
  "Urostomy",
  "Valvular Heart Disease",
  "Vascular Dementia",
  "Vasomotor Instability",
  "Vasomotor Rhinitis",
  "Vertigo",
  "Whiplash"
]
