import TextField from "@mui/material/TextField"
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import dayjs, {Dayjs} from "dayjs"
import React from "react"

export interface IYearDatePicker {
  onChange: (year: string) => void
  year: string
}

export const YearDatePicker: React.FC<IYearDatePicker> = (
  props: IYearDatePicker
) => {
  const {onChange, year} = props
  const value: Dayjs | null = year
    ? dayjs(`${year || dayjs().year()}-01-01`)
    : null

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        views={["year"]}
        value={value}
        onChange={(newValue) => {
          if (newValue && !newValue.isValid()) {
            return
          }
          if (newValue && newValue.isValid()) {
            onChange(dayjs(newValue).year().toString())
          } else {
            onChange("")
          }
        }}
        renderInput={(params) => <TextField {...params} helperText={null} />}
      />
    </LocalizationProvider>
  )
}

export default YearDatePicker
