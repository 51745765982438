import {yupResolver} from "@hookform/resolvers/yup"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import _ from "lodash"
import {LoadingScreen} from "@app-core/LoadingScreen"
import React, {useState} from "react"
import {authApi} from "data/api"
import {Controller, useForm} from "react-hook-form"
import {useMessages} from "hooks/useMessages"
import {Link, useNavigate} from "react-router-dom"
import * as yup from "yup"

interface ForgotPasswordState {
  email: string
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter a email")
})

const defaultValues: ForgotPasswordState = {
  email: ""
}

export const ForgotPasswordView: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const {addMessage} = useMessages()
  const navigate = useNavigate()

  const {control, formState, handleSubmit, reset} =
    useForm<ForgotPasswordState>({
      mode: "onChange",
      defaultValues,
      resolver: yupResolver(schema)
    })

  const {isValid, dirtyFields, errors} = formState

  const onSubmit = async ({email}: ForgotPasswordState) => {
    try {
      setLoading(true)
      await authApi.forgotPassword(email)
      setLoading(false)
      addMessage({
        content: "We've sent you an email to reset your password",
        severity: "success"
      })
    } catch (error) {
      setLoading(false)
      addMessage({
        content: "Error during password reset attempt. Please try again",
        severity: "error"
      })
      reset(defaultValues)
    }
  }

  const onImageClick = () => {
    navigate("/")
  }

  if (loading) {
    return <LoadingScreen isLoading={true} />
  }

  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
      <Paper className="w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img
            style={{width: "375px", cursor: "pointer"}}
            src="/static/images/logo.png"
            alt="logo"
            onClick={onImageClick}
          />
          <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
            Forgot password?
          </Typography>
          <div className="flex items-baseline mt-2 font-medium">
            <Typography>Fill the form to reset your password</Typography>
          </div>

          <form
            name="registerForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="email"
              control={control}
              render={({field}) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Email"
                  type="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <Button
              variant="contained"
              color="secondary"
              className=" w-full mt-4"
              aria-label="Register"
              disabled={_.isEmpty(dirtyFields) || !isValid}
              type="submit"
              size="large"
            >
              Send reset link
            </Button>

            <Typography
              className="mt-32 text-md font-medium"
              color="text.secondary"
            >
              <span>Return to</span>
              <Link className="ml-4" to="/sign-in">
                sign in
              </Link>
            </Typography>
          </form>
        </div>
      </Paper>
    </div>
  )
}

export default ForgotPasswordView
