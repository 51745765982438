import ArrowLeftAltIcon from "@mui/icons-material/KeyboardBackspace"
import {Button} from "@mui/material"
import React from "react"
import {Link} from "react-router-dom"
import {faqs} from "data/constants/faqs"
import FaqList from "./FaqList"

export const FAQView: React.FC = () => {
  return (
    <div className="flex flex-col items-center p-24 sm:p-40">
      <div className="flex flex-col w-full max-w-4xl">
        <div className="sm:mt-32">
          <Button
            component={Link}
            to="/help-center"
            color="secondary"
            startIcon={<ArrowLeftAltIcon fontSize="small" color="secondary" />}
          >
            Back to Help Center
          </Button>
        </div>
        <div className="mt-8 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight">
          Frequently Asked Questions
        </div>

        <div>
          {/* <Typography className="mt-48 sm:mt-64 text-3xl font-bold leading-tight tracking-tight">
              {category.title}
            </Typography> */}
          <FaqList className="w-full mt-32" faqs={faqs} />
        </div>
      </div>
    </div>
  )
}

export default FAQView
