import {Container, Box, Typography, Alert, Button} from "@mui/material"
import React from "react"
import {AppButton} from "@app-core/AppButton"
import DtcAppImage from "assets/images/dtc_app_large.svg"

interface ApplicationCompletedProps {
  errorMsg: string
  plan: string
  onSubmitApplication: () => void
  onEmailApp: () => void
  onCancel: () => void
  appGenerated: boolean
  walkthroughUser: boolean
  onSignOut: () => void
}

export const ApplicationCompleted: React.FC<ApplicationCompletedProps> = (
  props: ApplicationCompletedProps
) => {
  const {
    errorMsg,
    plan,
    onEmailApp,
    onSubmitApplication,
    onCancel,
    appGenerated,
    walkthroughUser,
    onSignOut
  } = props

  return (
    <Container maxWidth="md">
      <Box padding={4}>
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"center"}
          flexDirection="column"
          marginTop={4}
        >
          {errorMsg && (
            <Box marginTop="20px" marginBottom="20px">
              <Alert variant="filled" severity="error">
                {errorMsg}
              </Alert>
            </Box>
          )}
          <Box>
            <img src={DtcAppImage} alt={"DTC App completed"} />
          </Box>
          <Box>
            <Typography variant="h6" gutterBottom component="div">
              Application filled out successfully
            </Typography>
          </Box>
          <Box marginTop="20px" display="flex" flexDirection="column">
            {!appGenerated && (
              <AppButton
                sx={{backgroundColor: "#6A75B9", width: "350px"}}
                variant="contained"
                onClick={onSubmitApplication}
              >
                Generate Application
              </AppButton>
            )}
            {plan === "AssistanceRequired" &&
              appGenerated &&
              walkthroughUser && (
                <Box
                  display={"flex"}
                  alignItems="center"
                  justifyContent={"center"}
                  flexDirection="column"
                >
                  <AppButton
                    sx={{
                      backgroundColor: "#1ac725",
                      width: "350px",
                      marginTop: "10px"
                    }}
                    variant="contained"
                    onClick={onEmailApp}
                  >
                    Email Application
                  </AppButton>
                  <AppButton
                    sx={{
                      backgroundColor: "#FF9100",
                      width: "350px",
                      marginTop: "10px"
                    }}
                    variant="contained"
                    onClick={onSignOut}
                  >
                    Sign-Out of Application
                  </AppButton>
                </Box>
              )}
            <Button onClick={onCancel}>Back</Button>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default ApplicationCompleted
