import React, {useState, useContext} from "react"
import {IEligibilityApplication} from "models/IEligibilityApplication"

export interface IEligibilityApplicationContext {
  eligibilityApplication: IEligibilityApplication | undefined
  setEligibilityApplication: (
    eligibilityApplication: IEligibilityApplication
  ) => void
}

export type IEligibilityApplicationProvider = {
  children: React.ReactNode
}

const EligibilityApplicationContext =
  React.createContext<IEligibilityApplicationContext>(
    {} as IEligibilityApplicationContext
  )

export const EligibilityApplicationProvider = ({
  children
}: IEligibilityApplicationProvider) => {
  const [activeEligibilityApplication, setActiveEligibilityApplication] =
    useState<IEligibilityApplication | undefined>(undefined)

  const setEligibilityApplication = (
    eligibilityApplication: IEligibilityApplication
  ) => {
    setActiveEligibilityApplication(eligibilityApplication)
  }

  const value: IEligibilityApplicationContext = {
    eligibilityApplication: activeEligibilityApplication,
    setEligibilityApplication
  }

  return (
    <EligibilityApplicationContext.Provider value={value}>
      {children}
    </EligibilityApplicationContext.Provider>
  )
}

export const useEligibilityApplication = (): IEligibilityApplicationContext =>
  useContext(EligibilityApplicationContext)
