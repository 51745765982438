import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import {useTheme} from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import React from "react"

interface TimeoutWarningModalProps {
  onLogOffCall: () => void
  onStayLoggedIn: () => void
  open: boolean
}
export const TimeoutWarningModal = (props: TimeoutWarningModalProps) => {
  const {onLogOffCall, onStayLoggedIn, open} = props
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      disableEscapeKeyDown
      aria-labelledby="timeout-dialog-title"
    >
      <DialogTitle id="timeout-dialog-title">Session Timeout</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You&apos;re being timed out due to inactivity. Please choose to stay
          signed in or to logoff. Otherwise, you will be logged off
          automatically
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onLogOffCall}>Log off</Button>
        <Button onClick={onStayLoggedIn}>Stay Logged In</Button>
      </DialogActions>
    </Dialog>
  )
}

export default TimeoutWarningModal
