import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from "@mui/material"
import Avatar from "@mui/material/Avatar"
import Typography from "@mui/material/Typography"
import {styled} from "@mui/material/styles"
import React from "react"

interface MobilePanelProps {
  title: string
  onSignOut: () => void
  onPrevApps: () => void
}

const Root = styled("div")(({theme}) => ({
  "& .username": {
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut
    })
  }
}))

const MobilePanel: React.FC<MobilePanelProps> = (props: MobilePanelProps) => {
  const {title, onSignOut, onPrevApps} = props

  return (
    <Root className="user relative flex flex-col items-center justify-center p-16 pb-14 shadow-0">
      <div className="flex items-center justify-center mb-24">
        <Avatar className="avatar text-32 font-bold w-96 h-96" alt={title}>
          <AccountCircleIcon fontSize="large" />
        </Avatar>
      </div>
      <Typography className="username text-14 whitespace-nowrap font-medium">
        {title}
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={onPrevApps}>
            <ListItemText
              primary={
                <Typography textAlign="center">
                  View Previous Applications
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={onSignOut}>
            <ListItemText
              primary={<Typography textAlign="center">Sign Out</Typography>}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Root>
  )
}

export default MobilePanel
