import {StyledEngineProvider} from "@mui/material"
import React from "react"
import AppBrowserRouter from "@app-core/AppBrowserRouter/AppBrowserRouter"
import {AuthProvider} from "auth/AuthContext"
import {EligibilityApplicationProvider} from "hooks/useEligibilityApplication"
import {MessagesProvider} from "hooks/useMessages"
import Layout from "views/layouts/Layout"

function App() {
  return (
    <AppBrowserRouter>
      <MessagesProvider>
        <AuthProvider>
          <StyledEngineProvider injectFirst>
            <EligibilityApplicationProvider>
              <Layout />
            </EligibilityApplicationProvider>
          </StyledEngineProvider>
        </AuthProvider>
      </MessagesProvider>
    </AppBrowserRouter>
  )
}

export default App
