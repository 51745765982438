import {
  Box,
  Backdrop,
  CircularProgress,
  GlobalStyles,
  Typography
} from "@mui/material"
import {styled} from "@mui/material/styles"
import React from "react"

export interface ILoadingScreen {
  isLoading?: boolean
  label?: string
}

const StyledBox = styled(Box)(({theme}) => ({
  display: "row",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "center",
  marginTop: theme.spacing(10),
  height: "100%"
}))

const StyledBackdrop = styled(Backdrop)(({theme}) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: "#ffffff"
}))

export const LoadingScreen: React.FC<ILoadingScreen> = (
  props: ILoadingScreen
) => {
  const {isLoading = true, label} = props
  return (
    <>
      <GlobalStyles
        styles={(theme) => ({
          ...{
            "#dtc-toolbar": {
              position: "static"
            },
            "#dtc-footer": {
              position: "static",
              zIndex: 1
            }
          }
        })}
      />

      <StyledBox>
        <StyledBackdrop open={isLoading}>
          <CircularProgress color="inherit" />
          {label && <Typography style={{color: "black"}}>{label}</Typography>}
        </StyledBackdrop>
      </StyledBox>
    </>
  )
}

export default LoadingScreen
