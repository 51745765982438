import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import {Paper, Toolbar} from "@mui/material"
import Typography from "@mui/material/Typography"
import React from "react"

interface GuideVideo {
  name: string
  url: string
}
interface GuideVideoListProps {
  guideVideos: GuideVideo[]
}

export const GuideVideoList: React.FC<GuideVideoListProps> = (
  props: GuideVideoListProps
) => {
  const {guideVideos = []} = props

  return (
    <div>
      {guideVideos.map((vid, i) => (
        <Paper
          key={`guideVideos-${i}`}
          elevation={1}
          sx={{borderRadius: "8px", cursor: "pointer", marginBottom: "24px"}}
          onClick={() => window.open(vid.url, "_blank")}
        >
          <Toolbar>
            <Typography
              className="px-12 font-medium"
              variant="h6"
              component="div"
              sx={{flexGrow: 1}}
            >
              {vid.name}
            </Typography>
            <OpenInNewIcon />
          </Toolbar>
        </Paper>
      ))}
    </div>
  )
}

export default GuideVideoList
