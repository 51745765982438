import {Elements} from "@stripe/react-stripe-js"
import {Appearance} from "@stripe/stripe-js"
import {loadStripe} from "@stripe/stripe-js"
import React, {useEffect} from "react"
import {useNavigate} from "react-router-dom"
import AppPageCarded from "@app-core/AppPageCarded"
import {useAuth} from "auth/AuthContext"
import {CheckoutForm} from "components/CheckoutForm"
import {useEligibilityApplication} from "hooks/useEligibilityApplication"

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "")

export const PaymentView: React.FC = () => {
  const {user} = useAuth()
  const navigate = useNavigate()
  const {eligibilityApplication} = useEligibilityApplication()

  useEffect(() => {
    if (!user || !eligibilityApplication) {
      navigate("/")
    }
  }, [eligibilityApplication, navigate, user])

  const appearance: Appearance = {
    theme: "stripe"
  }
  const options = {
    appearance
  }

  return (
    <AppPageCarded scroll="page">
      <Elements options={options} stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </AppPageCarded>
  )
}

export default PaymentView
