import {AppButton} from "@app-core/AppButton"
import BathroomImage from "assets/images/bathroom_small.svg"
import DressingImage from "assets/images/dressing_small.svg"
import EatingPreparingFoodImage from "assets/images/eatingPreparingFood_small.svg"
import HearingImage from "assets/images/hearing_small.svg"
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@mui/material"
import LifeSustainingTherapyImage from "assets/images/lifeSustainingTherapy_small.svg"
import React from "react"
import MentalFunctionsImage from "assets/images/mentalFunctions_small.svg"
import ModerateImpairmentImage from "assets/images/moderateImpairment_small.svg"
import SpeakingImage from "assets/images/speaking_small.svg"
import VisionImage from "assets/images/vision_small.svg"
import WalkingImage from "assets/images/walking_small.svg"
import {QuestionTitleAndLogo} from "components/QuestionTitleAndLogo"
import {IEligibilityQuestionAnswer} from "models/IEligibilityQuestionAnswer"

interface EligibilitySummaryProps {
  answers: IEligibilityQuestionAnswer[]
  onConfirmClick: () => void
  onBack: () => void
}

export const EligibilitySummary: React.FC<EligibilitySummaryProps> = (
  props: EligibilitySummaryProps
) => {
  const {answers, onConfirmClick, onBack} = props

  const setImageUri = (type: string) => {
    switch (type) {
      case "Hearing":
        return HearingImage
      case "Speaking":
        return SpeakingImage
      case "Vision":
        return VisionImage
      case "Walking":
        return WalkingImage
      case "Eliminating":
        return BathroomImage
      case "EatingPreparingFood":
        return EatingPreparingFoodImage
      case "Dressing":
        return DressingImage
      case "MentalFunctions":
        return MentalFunctionsImage
      case "LifeSustainingTherapy":
        return LifeSustainingTherapyImage
      case "ModerateImpairment":
        return ModerateImpairmentImage
      default:
        return ""
    }
  }

  const setSummary = (ans: IEligibilityQuestionAnswer) => {
    if (ans.question.type !== "FamilyDoctor") {
      return ans.question.summary
    }

    if (ans.answer === "No") {
      return "You currently do not have a family doctor"
    }

    return ans.question.summary
  }

  const setTitle = (ans: IEligibilityQuestionAnswer) => {
    if (ans.question.type !== "FamilyDoctor") {
      return ans.question.title
    }

    if (ans.answer === "No") {
      return "No Family Doctor"
    }

    return ans.question.title
  }

  return (
    <Box padding={6}>
      <Box padding={6}>
        <Typography variant="h6" component="div">
          Congratulations! Based on your responses, it appears that you are
          likely to qualify for the Disability Tax Credit.
        </Typography>
        <Typography variant="h6" component="div">
          To proceed, please confirm your impairment is:
        </Typography>
        <List dense>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="h6" component="div">
                  {"* Severe and prolonged"}
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="h6" component="div">
                  {"* Causes difficulties generally 90% of the time"}
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="h6" component="div">
                  {
                    "* Has lasted or is expected to last for a minimum of one year"
                  }
                </Typography>
              }
            />
          </ListItem>
        </List>
        <Box>
          <Typography
            fontWeight="bold"
            variant="h6"
            gutterBottom
            component="div"
          >
            You selected
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={2}>
            {answers.map((a) => {
              return (
                <Grid key={a.question.id} item>
                  <QuestionTitleAndLogo
                    title={setTitle(a)}
                    imageSrc={setImageUri(a.question.type)}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Box>
        <Box>
          <Typography
            fontWeight="bold"
            variant="h6"
            gutterBottom
            component="div"
          >
            Which means:
          </Typography>
        </Box>
        <Box>
          {answers.map((a, i) => {
            return (
              <div key={`sum_${a.question.id}`}>
                <Typography
                  fontWeight="bold"
                  variant="h6"
                  gutterBottom
                  component="div"
                  sx={{
                    padding: "6px",
                    background: "#E5E5E5",
                    minHeight: "30px",
                    borderRadius: "12px",
                    alignItems: "center",
                    display: "flex"
                  }}
                >
                  {setSummary(a)}
                </Typography>
                {i + 1 !== answers.length && (
                  <Typography
                    fontWeight="bold"
                    variant="h6"
                    gutterBottom
                    component="div"
                    color="#6A75B9"
                    sx={{padding: "6px"}}
                  >
                    And
                  </Typography>
                )}
              </div>
            )
          })}
        </Box>
      </Box>
      <Box
        display={"flex"}
        alignItems="center"
        justifyContent="flex-end"
        marginTop={4}
        padding={4}
      >
        <Button onClick={onBack}>Back</Button>
        <AppButton
          onClick={onConfirmClick}
          sx={{
            backgroundColor: "#6A75B9",
            minWidth: "150px",
            minHeight: "44px"
          }}
          variant="contained"
        >
          Next
        </AppButton>
      </Box>
    </Box>
  )
}

export default EligibilitySummary
