import {Box, Typography} from "@mui/material"
import React from "react"
import {InfoModal} from "components/InfoModal"
import {YearDatePicker} from "components/YearDatePicker"
import {IQuestion} from "models/IQuestion"

interface SelectDropdownProps {
  question: IQuestion
  answer: string
  onSelectAnswer: (answer: string, subAnswer?: string, year?: string) => void
}

export const SelectYearDropdown: React.FC<SelectDropdownProps> = (
  props: SelectDropdownProps
) => {
  const {question, answer, onSelectAnswer} = props
  const {info} = question

  const onAnswerClicked = (answer: string) => {
    onSelectAnswer(answer, "", "")
  }

  return (
    <Box>
      <Box marginBottom="20px">
        <Typography
          fontSize={"28px"}
          fontWeight="600"
          variant="h6"
          gutterBottom
          component="div"
        >
          {question.text}
          {info && <InfoModal content={info.label} />}
        </Typography>
      </Box>
      <YearDatePicker onChange={onAnswerClicked} year={answer} />
    </Box>
  )
}
