import {CardExpiryElement} from "@stripe/react-stripe-js"
import React from "react"
import {StripeTextFieldProps, StripeTextField} from "./StripeTextField"

export const StripeTextFieldExpiry = (
  props: StripeTextFieldProps<typeof CardExpiryElement>
) => {
  return (
    <StripeTextField
      label="Expires"
      stripeElement={CardExpiryElement}
      {...props}
    />
  )
}
