import {Box, Chip, styled} from "@mui/material"
import React from "react"

const Item = styled(Chip)(({theme}) => ({
  height: "50px",
  minWidth: "115px",
  fontSize: "24px",
  color: "#707070",
  fontWeight: "700"
}))

interface QuestionTitleAndLogoProps {
  title: string
  imageSrc: string
}

export const QuestionTitleAndLogo: React.FC<QuestionTitleAndLogoProps> = (
  props: QuestionTitleAndLogoProps
) => {
  const {title, imageSrc} = props

  return (
    <Box display="flex" alignItems="center">
      <Item label={title} />
      {imageSrc === "" ? (
        <div style={{height: "75px", width: "75px"}}></div>
      ) : (
        <img src={imageSrc} alt={title} />
      )}
    </Box>
  )
}

export default QuestionTitleAndLogo
