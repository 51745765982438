import ApiClient, {IApiClient} from "./ApiClient"
import {IDtcApplication} from "models/IDtcApplication"

const urlSubPath = "/users"
export default class UserApi {
  private apiClient: IApiClient

  public constructor() {
    this.apiClient = new ApiClient(process.env.REACT_APP_API_BASE_URL || "")
  }

  public getUserApplications = async (
    userId: string
  ): Promise<IDtcApplication[]> => {
    const data = await this.apiClient.get<IDtcApplication[]>(
      `${urlSubPath}/${userId}/applications`
    )
    return data
  }
}
