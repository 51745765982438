import {Box, Typography, Stack, TextField} from "@mui/material"
import React, {useState} from "react"
import {IQuestion} from "models/IQuestion"

interface VisualAcuityProps {
  question: IQuestion
  answer: string
  onSelectAnswer: (answer: string) => void
}

interface AcuitySectionProps {
  title: string
  txtBox1: string
  txtBox2: string
  onTxtBox1Change: (answer: string) => void
  onTxtBox2Change: (answer: string) => void
}

interface AcuityAnswer {
  left1: string
  left2: string
  right1: string
  right2: string
}
const getAnswer = (answer: string): AcuityAnswer => {
  const aa = {
    left1: "",
    left2: "",
    right1: "",
    right2: ""
  }
  const answerArr = answer.split(";")

  if (answerArr.length > 0) {
    const left = answerArr[0]
    const right = answerArr[1]

    if (left) {
      const leftArr = left.split(":")
      aa.left1 = leftArr[0] || ""
      aa.left2 = leftArr[1] || ""
    }

    if (right) {
      const rightArr = right.split(":")
      aa.right1 = rightArr[0] || ""
      aa.right2 = rightArr[1] || ""
    }
  }
  return aa
}

const AcuitySection: React.FC<AcuitySectionProps> = (
  props: AcuitySectionProps
) => {
  const {title, txtBox1, txtBox2, onTxtBox1Change, onTxtBox2Change} = props

  return (
    <Box sx={{marginBottom: "20px"}}>
      <Typography
        fontSize={"16px"}
        fontWeight="600"
        variant="h6"
        gutterBottom
        component="div"
      >
        {title}
      </Typography>
      <Stack direction={"row"} spacing={2}>
        <TextField
          id={`AcuitySection-outlined-number-${title
            .trim()
            .replaceAll(" ", "")}`}
          type="number"
          InputLabelProps={{
            shrink: true
          }}
          onChange={(e) => onTxtBox1Change(e.target.value)}
          value={txtBox1}
        />
        <Typography
          fontSize={"28px"}
          fontWeight="800"
          variant="h4"
          gutterBottom
          component="div"
        >
          {"/"}
        </Typography>
        <TextField
          id={`AcuitySection-outlined-number-${title
            .trim()
            .replaceAll(" ", "")}`}
          type="number"
          InputLabelProps={{
            shrink: true
          }}
          onChange={(e) => onTxtBox2Change(e.target.value)}
          value={txtBox2}
        />
      </Stack>
    </Box>
  )
}

const VisualAcuity: React.FC<VisualAcuityProps> = (
  props: VisualAcuityProps
) => {
  const {question, answer, onSelectAnswer} = props
  const [acuityAnswer, setAcuityAnswer] = useState<AcuityAnswer>(
    getAnswer(answer)
  )
  // const inputRef  = useRef();

  const onLeft1Change = (val: string) => {
    onSelectAnswer(
      `${val}:${acuityAnswer.left2};${acuityAnswer.right1}:${acuityAnswer.right2}`
    )
    setAcuityAnswer({
      ...acuityAnswer,
      left1: val
    })
  }

  const onLeft2Change = (val: string) => {
    onSelectAnswer(
      `${acuityAnswer.left1}:${val};${acuityAnswer.right1}:${acuityAnswer.right2}`
    )
    setAcuityAnswer({
      ...acuityAnswer,
      left2: val
    })
  }

  const onRight1Change = (val: string) => {
    onSelectAnswer(
      `${acuityAnswer.left1}:${acuityAnswer.left2};${val}:${acuityAnswer.right2}`
    )
    setAcuityAnswer({
      ...acuityAnswer,
      right1: val
    })
  }

  const onRight2Change = (val: string) => {
    onSelectAnswer(
      `${acuityAnswer.left1}:${acuityAnswer.left2};${acuityAnswer.right1}:${val}`
    )
    setAcuityAnswer({
      ...acuityAnswer,
      right2: val
    })
  }

  return (
    <Box>
      <Box marginBottom="20px">
        <Typography
          fontSize={"28px"}
          fontWeight="600"
          variant="h6"
          gutterBottom
          component="div"
        >
          {question.text}
        </Typography>
      </Box>
      <AcuitySection
        title="Left Eye"
        txtBox1={acuityAnswer.left1}
        txtBox2={acuityAnswer.left2}
        onTxtBox1Change={onLeft1Change}
        onTxtBox2Change={onLeft2Change}
      />
      <AcuitySection
        title="Right Eye"
        txtBox1={acuityAnswer.right1}
        txtBox2={acuityAnswer.right2}
        onTxtBox1Change={onRight1Change}
        onTxtBox2Change={onRight2Change}
      />
    </Box>
  )
}

export default VisualAcuity
