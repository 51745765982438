import styled from "@emotion/styled"
import {Box, Hidden, Stack, SwipeableDrawer} from "@mui/material"
import React, {useState} from "react"
import {useNavigate} from "react-router-dom"
import {AppButton} from "@app-core/AppButton"
import {UserDropdown} from "components/UserDropdown"
import {IAppUser} from "models/IAppUser"
import MobilePanel from "./MobilePanel"
import MobileToggleButton from "./MobileToggleButton"

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({theme}) => ({
  "& > .MuiDrawer-paper": {
    height: "100%",
    flexDirection: "column",
    flex: "1 1 auto",
    width: 280,
    minWidth: 280
  }
}))

interface HeaderWrapperProps {
  authenticated: boolean
  user?: IAppUser
  signIn: () => void
  signOut: () => void
  goToPrevApps: () => void
  physicianSignIn: () => void
  walkThrough: () => void
  isPhysicianLoggedIn: boolean
}
export const HeaderWrapper: React.FC<HeaderWrapperProps> = (
  props: HeaderWrapperProps
) => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const navigate = useNavigate()
  const {
    authenticated,
    user,
    signIn,
    signOut,
    physicianSignIn,
    goToPrevApps,
    walkThrough,
    isPhysicianLoggedIn
  } = props

  const onLogoClick = () => {
    navigate("/")
  }

  const onLoginClick = () => {
    if (mobileOpen) {
      setMobileOpen(false)
    }
    signIn()
  }

  const onWalkThroughClick = () => {
    walkThrough()
  }

  const onSignOutClick = () => {
    if (mobileOpen) {
      setMobileOpen(false)
    }
    signOut()
  }

  const onPhysicianAccessClick = () => {
    if (mobileOpen) {
      setMobileOpen(false)
    }
    physicianSignIn()
  }

  const mobileOnGoToPrevApps = () => {
    if (mobileOpen) {
      setMobileOpen(false)
    }
    goToPrevApps()
  }

  return (
    <>
      <Hidden smDown>
        <div className="w-full mb-20 h-64 min-h-64 max-h-64">
          <div className="flex flex-auto justify-between items-center w-full h-full container p-0 z-20">
            <div className="flex shrink-0 items-center px-8">
              <img
                onClick={onLogoClick}
                style={{cursor: "pointer", width: "300px"}}
                src="/static/images/logo.png"
                alt="Logo"
              />
            </div>

            {!isPhysicianLoggedIn && (
              <Box justifyContent="flex-end" flexGrow={"1"} display="flex">
                {authenticated && user ? (
                  <Box display="flex">
                    {user.roles?.includes("WalkThroughAdmin") && (
                      <AppButton
                        onClick={onWalkThroughClick}
                        sx={{
                          backgroundColor: "#FF9100",
                          minWidth: "213px",
                          marginRight: "6px"
                        }}
                        variant="contained"
                      >
                        Walk-Through
                      </AppButton>
                    )}
                    <UserDropdown
                      onHomeClicked={onLogoClick}
                      onSignOut={onSignOutClick}
                      onPrevApps={goToPrevApps}
                      title={`${user.lastName}, ${user.firstName}`}
                    />
                  </Box>
                ) : (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <AppButton
                      onClick={onLoginClick}
                      sx={{backgroundColor: "#FF9100", minWidth: "213px"}}
                      variant="contained"
                    >
                      Login
                    </AppButton>
                    <AppButton
                      onClick={onPhysicianAccessClick}
                      sx={{backgroundColor: "#6A75B9", minWidth: "213px"}}
                      variant="contained"
                    >
                      Physician Access
                    </AppButton>
                    {/* <Chip icon={<LanguageIcon />} label="English" /> */}
                  </Stack>
                )}
              </Box>
            )}
          </div>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className="flex flex-col overflow-hidden">
          <div className="flex container p-0 lg:px-24 min-h-48 md:min-h-64">
            <div className="flex flex-1 pr-8">
              <div className="flex items-center">
                <img
                  onClick={onLogoClick}
                  style={{cursor: "pointer", width: "300px"}}
                  src="/static/images/logo.png"
                  alt="Logo"
                />
              </div>
            </div>

            {!isPhysicianLoggedIn && (
              <div className="w-40 h-40 p-0">
                <MobileToggleButton onClick={() => setMobileOpen(true)} />
              </div>
            )}
          </div>
        </div>

        <StyledSwipeableDrawer
          anchor="left"
          variant="temporary"
          open={mobileOpen}
          onClose={() => setMobileOpen(false)}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onOpen={() => {}}
          disableSwipeToOpen
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {authenticated && user ? (
            <MobilePanel
              onSignOut={onSignOutClick}
              onPrevApps={mobileOnGoToPrevApps}
              title={`${user.lastName}, ${user.firstName}`}
            />
          ) : (
            <Stack
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              sx={{marginTop: "24px"}}
            >
              <AppButton
                onClick={onLoginClick}
                sx={{backgroundColor: "#FF9100", minWidth: "213px"}}
                variant="contained"
              >
                Login
              </AppButton>
              <AppButton
                onClick={onPhysicianAccessClick}
                sx={{backgroundColor: "#6A75B9", minWidth: "213px"}}
                variant="contained"
              >
                Physician Access
              </AppButton>
            </Stack>
          )}
        </StyledSwipeableDrawer>
      </Hidden>
    </>
  )
}
