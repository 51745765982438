import HelpIcon from "@mui/icons-material/Help"
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Tooltip,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  createFilterOptions
} from "@mui/material"
import React from "react"
import {diagnoses} from "data/constants/diagnoses"
import {IQuestion} from "models/IQuestion"

interface SelectAllDiagnosesThatApplyProps {
  question: IQuestion
  answer: string
  onSelectAnswer: (answer: string) => void
}

interface DiagnosisOptionType {
  inputValue?: string
  title: string
}
const filter = createFilterOptions<DiagnosisOptionType>()

export const SelectAllDiagnosesThatApply: React.FC<
  SelectAllDiagnosesThatApplyProps
> = (props: SelectAllDiagnosesThatApplyProps) => {
  const {question, answer, onSelectAnswer} = props
  const selectedAnswers = !answer ? [] : answer.split(",").map((a) => a.trim())
  const options: DiagnosisOptionType[] = diagnoses.map((d) => ({
    title: d
  }))
  const [open, toggleOpen] = React.useState(false)
  const [dialogValue, setDialogValue] = React.useState({
    diagnosis: ""
  })

  const handleClose = () => {
    setDialogValue({
      diagnosis: ""
    })
    toggleOpen(false)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onSelectAnswer([...selectedAnswers, dialogValue.diagnosis].join(", "))
    handleClose()
  }

  return (
    <Box>
      <Box marginBottom="20px" sx={{display: "flex", alignItems: "center"}}>
        <Typography
          fontSize={"28px"}
          fontWeight="600"
          variant="h6"
          gutterBottom
          component="div"
        >
          {question.text}
          <Tooltip title="More than one diagnosis may be selected if applicable.">
            <IconButton>
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </Typography>
      </Box>
      <Box>
        <Autocomplete
          multiple
          id="diagnoses-items"
          options={options}
          getOptionLabel={(option) => option.title}
          fullWidth
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          sx={{height: "250px"}}
          value={selectedAnswers.map(
            (d) =>
              ({
                title: d
              } as DiagnosisOptionType)
          )}
          onChange={(event, newValue, reason, details) => {
            if (
              reason === "selectOption" &&
              details &&
              details.option.inputValue
            ) {
              toggleOpen(true)
              setDialogValue({
                diagnosis: details.option.inputValue
              })
            } else {
              onSelectAnswer(newValue.map((d) => d.title).join(", "))
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params)

            if (params.inputValue !== "") {
              filtered.push({
                inputValue: params.inputValue,
                title: `Add "${params.inputValue}"`
              })
            }
            return filtered
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Diagnosis"
              placeholder="Diagnosis "
            />
          )}
        />
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add a diagnosis</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.diagnosis}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  diagnosis: event.target.value
                })
              }
              type="text"
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  )
}
