import {Box, Grid, Typography} from "@mui/material"
import React from "react"
import {AppButton} from "@app-core/AppButton"
import DtcAppImage from "assets/images/dtc_app.svg"
import {IProduct} from "models/IProduct"

interface PaymentOptionProps {
  onSelectPaymentOption: (paymentOption: string) => void
  products: IProduct[]
}

export const PaymentOption: React.FC<PaymentOptionProps> = (
  props: PaymentOptionProps
) => {
  const {onSelectPaymentOption, products} = props

  const onDtcAppClick = (plan: string) => {
    onSelectPaymentOption(plan)
  }

  return (
    <Box padding={3}>
      <Box display={"flex"} alignItems="center" justifyContent="flex-start">
        <Box
          fontSize="h6.fontSize"
          fontWeight={900}
          color="#6A75B9"
          sx={{
            width: "90px",
            height: "40px",
            alignItems: "center",
            display: "flex",
            justifyContent: "center"
          }}
        ></Box>
      </Box>
      <Box
        display={"flex"}
        alignItems="center"
        justifyContent={"center"}
        flexDirection="column"
      ></Box>
      <Box paddingLeft={4} paddingRight={4}>
        <Grid container spacing={2} justifyContent="center">
          {products.map((p) => {
            return (
              <Grid key={p.planName} item xs={12} sm={12} md={4} lg={4}>
                <Box
                  sx={{
                    border: "1px solid #707070",
                    borderRadius: "24px",
                    height: "100%"
                  }}
                  padding={3}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box>
                    <img src={DtcAppImage} alt={p.name} />
                  </Box>
                  <Typography
                    sx={{fontSize: "24px"}}
                    variant="h6"
                    gutterBottom
                    component="div"
                  >
                    {p.name}
                  </Typography>
                  <Typography
                    sx={{fontSize: "12px"}}
                    variant="h6"
                    gutterBottom
                    component="div"
                  >
                    {p.description}
                  </Typography>
                  <div>
                    <Typography
                      sx={{fontSize: "54px", color: "#FF9100"}}
                      variant="h6"
                      gutterBottom
                      component="div"
                    >
                      {`$${p.price}`}
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "black",
                          textAlign: "center",
                          position: "relative",
                          top: "-16px"
                        }}
                        variant="h6"
                        gutterBottom
                        component="div"
                      >
                        {`plus tax*`}
                      </Typography>
                    </Typography>
                  </div>
                  <AppButton
                    onClick={() => onDtcAppClick(p.planName)}
                    sx={{backgroundColor: "#6A75B9"}}
                    variant="contained"
                    fullWidth
                  >
                    Select and Pay
                  </AppButton>
                </Box>
              </Grid>
            )
          })}
          {/* <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              sx={{border: "1px solid #707070", borderRadius: "24px"}}
              padding={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Box>
                <img src={DtcAppImage} alt="DTC Application" />
              </Box>
              <Typography
                sx={{fontSize: "24px"}}
                variant="h6"
                gutterBottom
                component="div"
              >
                DTC Application
              </Typography>
              <Typography
                sx={{fontSize: "54px", color: "#FF9100"}}
                variant="h6"
                gutterBottom
                component="div"
              >
                $99.00
              </Typography>
              <AppButton
                onClick={onDtcAppClick}
                sx={{backgroundColor: "#6A75B9"}}
                variant="contained"
                fullWidth
              >
                Select and Pay
              </AppButton>
            </Box>
          </Grid> */}
          {/* <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              sx={{border: "1px solid #707070", borderRadius: "24px"}}
              padding={3}
            >
              <Box>
                <img src={DtcAppWithSupportImage} alt="DTC Application" />
              </Box>
              <Typography
                sx={{fontSize: "24px"}}
                variant="h6"
                gutterBottom
                component="div"
              >
                DTC Application with Tax Credit Support
              </Typography>
              <Typography
                sx={{fontSize: "54px", color: "#FF9100"}}
                variant="h6"
                gutterBottom
                component="div"
              >
                $79.95
              </Typography>
              <AppButton
                onClick={onDtcAppWithSupportClick}
                sx={{backgroundColor: "#6A75B9"}}
                variant="contained"
                fullWidth
              >
                Select and Pay
              </AppButton>
            </Box>
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  )
}
