import MenuIcon from "@mui/icons-material/Menu"
import IconButton from "@mui/material/IconButton"
import React from "react"

interface MobileToggleButtonProps {
  onClick: () => void
}

const MobileToggleButton: React.FC<MobileToggleButtonProps> = (
  props: MobileToggleButtonProps
) => {
  const {onClick} = props

  return (
    <IconButton
      className="w-40 h-40 p-0 mx-0 sm:mx-8"
      color="inherit"
      size="small"
      onClick={(ev) => {
        onClick()
      }}
    >
      <MenuIcon />
    </IconButton>
  )
}

export default MobileToggleButton
