import {yupResolver} from "@hookform/resolvers/yup"
import {Paper, Typography, TextField, Button} from "@mui/material"
import _ from "lodash"
import React, {useState} from "react"
import {Controller, useForm} from "react-hook-form"
import {Link, useLocation, useNavigate} from "react-router-dom"
import {LoadingScreen} from "@app-core/LoadingScreen"
import * as yup from "yup"
import jwtService from "auth/JwtService"
import {useMessages} from "hooks/useMessages"

interface SignInState {
  email: string
  password: string
}
const schema = yup.object().shape({
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter a email"),
  password: yup
    .string()
    .required("Please enter your password.")
    .min(8, "Password is too short - must be at least 8 chars.")
})

const defaultValues: SignInState = {
  email: "",
  password: ""
}

export const SignInView: React.FC = () => {
  const location = useLocation()
  const redirectUrl = location.state?.redirectUrl
  const [loading, setLoading] = useState(false)
  const {addMessage} = useMessages()
  const navigate = useNavigate()
  const {control, formState, handleSubmit, reset} = useForm<SignInState>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema)
  })
  const {isValid, dirtyFields, errors} = formState

  const onSubmit = async ({email, password}: SignInState) => {
    try {
      setLoading(true)
      await jwtService.signInWithEmailAndPassword(
        {email, password},
        redirectUrl
      )
    } catch {
      setLoading(false)
      addMessage({
        content: "Error during login attempt. Please try again",
        severity: "error"
      })
      reset(defaultValues)
    }
  }

  const onImageClick = () => {
    navigate("/")
  }

  if (loading) {
    return <LoadingScreen isLoading={true} />
  }

  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
      <Paper className="w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img
            style={{width: "375px", cursor: "pointer"}}
            src="/static/images/logo.png"
            alt="logo"
            onClick={onImageClick}
          />

          <Typography className="mt-28 text-2xl font-extrabold tracking-tight leading-tight">
            Sign in
          </Typography>
          <div className="flex items-baseline mt-2 font-medium">
            <Typography>{`Don't have an account?`}</Typography>
            <Link className="ml-4" to="/sign-up" state={{redirectUrl}}>
              Sign up
            </Link>
          </div>

          <form
            name="loginForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="email"
              control={control}
              render={({field}) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Email"
                  autoFocus
                  type="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              render={({field}) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Password"
                  type="password"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <div className="flex flex-col sm:flex-row items-center justify-end">
              <Link className="text-sm font-medium" to="/forgot-password">
                Forgot password?
              </Link>
            </div>

            <Button
              variant="contained"
              color="secondary"
              className=" w-full mt-16"
              aria-label="Sign in"
              disabled={_.isEmpty(dirtyFields) || !isValid}
              type="submit"
              size="large"
            >
              Login
            </Button>
          </form>
        </div>
      </Paper>
    </div>
  )
}

export default SignInView
