import CloseIcon from "@mui/icons-material/Close"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import React from "react"

export const TermsAndConditionsModal: React.FC = () => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const descriptionElementRef = React.useRef<HTMLElement>(null)
  React.useEffect(() => {
    if (open) {
      const {current: descriptionElement} = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  return (
    <div>
      <Button onClick={handleClickOpen} variant="text">
        Terms of Service
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" sx={{m: 0, p: 2}}>
          Terms & Conditions
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Typography variant="h5" gutterBottom>
              ACCEPTANCE OF TERMS
            </Typography>
            <Typography variant="body1" gutterBottom>
              The following terms and conditions govern all use of (i) the{" "}
              <a href="www.benefits2.ca">www.benefits2.ca</a> website (the
              Site), and (ii) the services available at or through the Site (the
              Service). The Service is owned and operated by Benefits2. The
              Service is offered subject to your acceptance without modification
              of all of the terms and conditions contained herein and all other
              operating rules, policies and procedures that may be published
              from time to time on this Site by Benefits2 (collectively, the
              Terms of Use). PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE
              ACCESSING THE SITE OR OTHERWISE USING THE SERVICE. BY ACCESSING
              THE SITE OR USING ANY PART OF THE SERVICE, YOU (EITHER AN
              INDIVIDUAL OR A LEGAL ENTITY THAT YOU REPRESENT AS AN AUTHORIZED
              EMPLOYEE OR AGENT) (YOU) AGREE THAT YOU ARE BOUND BY THE TERMS AND
              CONDITIONS OF THESE TERMS OF USE. IF YOU DO NOT AGREE TO ALL THE
              TERMS AND CONDITIONS OF THESE TERMS OF USE, THEN YOU MAY NOT
              ACCESS THE SITE, SERVICE, OR ANY PART THEREOF. BENEFITS2
              ACCEPTANCE IS EXPRESSLY CONDITIONED UPON YOUR ASSENT TO ALL OF THE
              TERMS AND CONDITIONS OF THESE TERMS OF USE, TO THE EXCLUSION OF
              ALL OTHER TERMS; IF THESE TERMS OF USE ARE CONSIDERED AN OFFER BY
              BENEFITS2, YOUR ACCEPTANCE IS EXPRESSLY LIMITED TO THESE TERMS.
              The Service is available only to individuals who are at least 18
              years. If you do not so qualify, do not attempt to use the
              Service. BENEFITS2 may refuse to offer the Service to any person
              or entity at any time and may change its eligibility criteria, at
              any time, in its sole discretion.
            </Typography>
            <Typography variant="h5" gutterBottom>
              MODIFICATION OF TERMS OF USE
            </Typography>
            <Typography variant="body1" gutterBottom>
              Benefits2 reserves the right, at its sole discretion, to modify or
              replace any of these Terms of Use at any time. Benefits2 will
              notify you of changes on the Site and through the benefits2.ca.com
              and we may send an email to registered users to notify them of the
              change. Your continued use of the Service following the posting of
              any changes to the Terms of Use constitutes acceptance of those
              changes.
            </Typography>
            <Typography variant="h5" gutterBottom>
              NEW FEATURES
            </Typography>
            <Typography variant="body1" gutterBottom>
              Benefits2 may, in the future, offer new services and/or features
              through the Service (including, the release of new tools and
              resources). Such new features and/or services shall be subject to
              these Terms of Use. Your continued use of the Service after the
              introduction of new features and/or services constitutes your
              acceptance of the Terms of Use.
            </Typography>
            <Typography variant="h5" gutterBottom>
              PRIVACY
            </Typography>
            <Typography variant="body1" gutterBottom>
              Benefits2 current Site Privacy Statement is available at{" "}
              <a href="www.benefits2.ca">www.benefits2.ca</a> (Privacy Policy),
              which is incorporated by this reference. For inquiries about
              Benefits2 Privacy Policy, or to report a privacy related problem,
              please contact{" "}
              <a href="mailto:privacy_officer@benefits2.ca">
                privacy_officer@benefits2.ca
              </a>
              .
            </Typography>
            <Typography variant="h5" gutterBottom>
              RULES AND CONDUCT
            </Typography>
            <Typography variant="body1" gutterBottom>
              The Site and the Service and any Content (as defined below) made
              available on or through the Service is provided only for (a) your
              own personal use, (b) your internal business use, use in the
              support of your customers that use the Service for their own
              personal or internal business use, all in compliance with all
              applicable laws, rules and regulations. If you desire to make any
              other commercial use of the Site or the Service, including but not
              limited to customizing, selling or distributing the Service
              (whether as a stand-alone service or bundled with your services)
              for consideration of any kind or for no consideration, you must
              first obtain Benefits2 specific written approval in advance and/or
              enter into a written agreement with Benefits2. The Service is
              intended for use described above only and may not be used in
              connection with any other commercial endeavours except those that
              are specifically endorsed or approved by Benefits2 in writing.
              Except for distributions for the use described above to your
              employees or independent contractors for your internal business
              use and in compliance with these Terms of Use, you may not
              distribute or otherwise commercially exploit or make available to
              any third party the Service, or any part thereof, in any way. Any
              unauthorized use of the Service is expressly prohibited. For
              purposes of these Terms of Use, “Content” includes, without
              limitation, any information, or data (including, information or
              data generated or otherwise provided by Benefits2 or its partners
              through the Service or related services, or otherwise available
              through the Site that are not Submissions (as defined below)),
              text, software, scripts, graphics, photos, documents and
              interactive features. You promise not to use the Service for any
              purpose that is unlawful or prohibited by these Terms of Use. You
              agree to abide by all applicable local, provincial, and federal
              laws and regulations within Canada. By way of example, and not as
              a limitation, you agree not to (or permit others to) : ( a) take
              any action or ( b) upload, download, post, submit or otherwise
              distribute or facilitate distribution of any “Submissions” such
              as, without limitation, postings to the Benefits2 Community,
              comments, text, communications, software, images, sounds, data or
              other materials or information) using any communications service
              or other service available on or through the Service, that:
              infringes any patent, trademark, trade secret, copyright, right of
              publicity or other right of any other person or entity; is
              unlawful, threatening, abusive, harassing, defamatory, libellous,
              deceptive, fraudulent, invasive of another’s privacy, tortious,
              obscene, offensive, profane or which otherwise violates the Terms
              of Use; constitutes unauthorized or unsolicited advertising, junk
              or bulk e-mail (“spamming”); imposes an unreasonable or
              disproportionately (in the sole judgment of Benefits2) large load
              on Benefits2 servers, storage or communications infrastructure, or
              attempts to gain unauthorized access to the Service, other
              accounts, computer systems or networks connected to the Service,
              through password mining or otherwise; contains software viruses or
              any other computer codes, files, or programs that are designed or
              intended to disrupt, damage, limit or interfere with the proper
              function of any software, hardware, or telecommunications
              equipment or to damage or obtain unauthorized access to any
              system, data or other information of Benefits2 or any third party;
              or impersonates any person or entity, including, without
              limitation, any employee or representative of Benefits2. You will
              not use any robot, spider, scraper or other automated means to
              access the Service for any purpose without Benefits2 express
              written permission. Additionally, you will not: (i) interfere or
              attempt to interfere with the proper working of the Service or any
              activities conducted on the Service; or (ii) bypass any measures
              Benefits2 may use to prevent or restrict access to the Service.
              You shall not (and you shall not permit others to), directly or
              indirectly, modify, translate, decompile, disassemble, or reverse
              engineer any part of the Service or any Content available through
              the Site (except to the limited extent applicable laws
              specifically prohibit such restriction); or copy, rent, lease,
              distribute, or otherwise transfer any or the rights that you
              receive hereunder. In addition, you shall not remove any
              proprietary notices or labels. You may not harvest or collect
              information, including, without limitation, screen names, about
              other members or users of the Service. The use of any information
              learned through the Service or while in the Site is limited to the
              express purposes set forth in these Terms of Use; all other uses,
              including, without limitation, sending unsolicited bulk e-mail,
              are strictly prohibited. Benefits2 may remove any Content and
              Submissions at any time for any reason (including, but not limited
              to, upon receipt of claims or allegations from third parties or
              authorities relating to such Content or Submissions), or for no
              reason at all. Benefits2 may, at its sole discretion, immediately
              terminate your access to the Service should your conduct fail to
              conform strictly and terms or conditions of these Terms of Use,
              including, without limitation, with any provision of this section.
              Please report any violation of these Terms of Use by contacting
              Benefits2 at{" "}
              <a href="mailto:privacy_officer@benefits2.ca">
                privacy_officer@benefits2.ca
              </a>
              .
            </Typography>
            <Typography variant="h5" gutterBottom>
              REGISTRATION
            </Typography>
            <Typography variant="body1" gutterBottom>
              As a condition to using the Service, you may be required to
              register with Benefits2 by providing information to be specified
              during the registration process and entering an email address and
              selecting a password and account name (“benefits2 User ID”). You
              shall provide Benefits2 with accurate, complete, and updated
              registration information. Failure to do so shall constitute a
              breach of the Terms of Use, which may result in immediate
              termination of your benefits2.ca account. In addition, you agree
              to maintain and update your registration information to ensure
              that it is accurate and complete at all times. You may not (i)
              select or use as a benefits2 User ID a name of another person with
              the intent to impersonate that person; (ii) use as a benefits2
              User ID a name subject to any rights of a person other than you
              without appropriate authorization; or (iii) use as an benefits2.ca
              User ID a name that is otherwise offensive, vulgar or obscene.
              Benefits2 reserves the right to refuse registration of or cancel a
              Benefits2 User ID in its sole discretion. You are solely
              responsible for activity that occurs on your account and shall be
              responsible for maintaining the confidentiality of your
              Benefits2.ca password. You may never use another user’s account
              without such other user’s express permission. You will immediately
              notify Benefits2 in writing of any unauthorized use of your
              account, or other account related security breach of which you are
              aware.
            </Typography>
            <Typography variant="h5" gutterBottom>
              PAYMENT
            </Typography>
            <Typography variant="body1" gutterBottom>
              If you have purchased a paid Service, you are expressly agreeing
              that Benefits2 is permitted to bill you the applicable fees, any
              applicable tax and any other charges you may incur with Benefits2
              in connection with your use of the Site and/or Service and that
              the fees will be billed to the credit card you provide in
              accordance with the billing terms in effect at the time a fee or
              charge is due and payable. If payment is not received or cannot be
              charged to your credit card for any reason in advance of the
              applicable purchase, Benefits2 reserves the right to either
              suspend or terminate your access to the Site and/or any Service
              and terminate these Terms of Use. Purchase fees are payable in
              Canadian Dollars and based on Services purchased and not on actual
              usage.{" "}
              <b>
                All fees are non-refundable. The Service is billed in advance as
                a one-time purchase and is non-refundable. There will be no
                refunds or credits for an unused purchase of the service. You
                acknowledge and agree that Benefits2 will automatically charge
                your credit card at time of purchase of the service.
              </b>
            </Typography>
            <Typography variant="h5" gutterBottom>
              ELECTRONIC COMMUNICATIONS
            </Typography>
            <Typography variant="body1" gutterBottom>
              By using the Site, you consent to receiving electronic
              communications from Benefits2. These electronic communications may
              include notices about applicable fees and charges, transactional
              information and other information concerning or related to our
              Site and Service. These electronic communications are part of your
              relationship with Benefits2 and you receive them as part of your
              purchase of the service. You agree that any notices, agreements,
              disclosures, or other communications that we send you
              electronically will satisfy any legal communication requirements,
              including that such communications be in writing.
            </Typography>
            <Typography variant="h5" gutterBottom>
              CANCELLATION AND TERMINATION
            </Typography>
            <Typography variant="body1" gutterBottom>
              Your personal information will be deleted out of our system after
              30 days. You have the option to delete your account before the
              30-day window by logging into the website with your registered
              account and choosing to delete your account. All of your personal
              information and purchased service will be immediately deleted from
              our system upon cancellation except demographic information such
              as diagnosis, gender, age, and city location. The demographic
              information will be used solely for correlating information to
              identify potential patterns of diagnosis in certain areas of
              Canada. Deleted account information cannot be recovered once your
              account is cancelled. If you cancel the Service before the end of
              30 days, your cancellation will take effect immediately.
              Benefits2, in its sole discretion, has the right to suspend or
              terminate your account and refuse any and all current or future
              use of the Service for any reason at any time. Such termination of
              the Service will result deletion of your Account or your access to
              your Account, and the forfeiture and relinquishment of the paid
              service in your Account. Benefits2 reserves the right to refuse
              service to anyone for any reason at any time.
            </Typography>
            <Typography variant="h5" gutterBottom>
              THIRD PARTY SITES
            </Typography>
            <Typography variant="body1" gutterBottom>
              The Service may permit you to link to other websites or resources
              on the Internet, and other websites or resources may contain links
              to the Site. These other websites are not under Benefits2 control,
              and you acknowledge that Benefits2 is not responsible or liable
              for the content, functions, accuracy, legality, appropriateness or
              any other aspect of such websites or resources. The inclusion of
              any such link does not imply endorsement by Benefits2 or any
              association with its operators. You further acknowledge and agree
              that Benefits2 shall not be responsible or liable, directly, or
              indirectly, for any damage or loss caused or alleged to be caused
              by or in connection with the use of or reliance on any such
              Content, goods or services available on or through any such
              website or resource.
            </Typography>
            <Typography variant="h5" gutterBottom>
              CONTENT
            </Typography>
            <Typography variant="body1" gutterBottom>
              Benefits2 Content Generally. You agree that the Site and Service
              contains Content specifically provided by Benefits2 and that such
              Content is protected by copyrights, trademarks, service marks,
              patents, trade secrets or other proprietary rights and laws.
              Except as expressly authorized by Benefits2 in writing, you agree
              not to sell, license, rent, modify, distribute, copy, reproduce,
              transmit, publicly display, publicly perform, publish, adapt,
              edit, or create derivative works from such Content. Use of the
              Content for any purpose not expressly permitted in these Terms of
              Use is prohibited. Any rights not expressly granted herein are
              reserved.
            </Typography>
            <Typography variant="h5" gutterBottom>
              NO WARRANTIES
            </Typography>
            <Typography variant="body1" gutterBottom>
              THE SERVICE, SITE AND ALL CONTENT, AND SERVICES INCLUDED IN OR
              AVAILABLE THROUGH THE SITE, ARE PROVIDED “AS IS” AND “AS
              AVAILABLE.” THE SERVICE, SITE AND CONTENT, AND SERVICES ARE
              PROVIDED WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED,
              INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE,
              NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR
              PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE
              OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED.
              BENEFITS2, AND ITS AGENTS, PARTNERS, SUPPLIERS AND CONTENT
              PROVIDERS DO NOT WARRANT THAT: (A) ANY CONTENT OR SUBMISSIONS,
              INCLUDING, WITHOUT LIMITATION, ANY RESULTS, DATA, OR INFORMATION
              OBTAINED THROUGH THE SERVICE WILL BE ACCURATE, RELIABLE, OR
              COMPLETE; (B) THE SERVICE WILL BE SECURE OR AVAILABLE AT ANY
              PARTICULAR TIME OR LOCATION; (C) ANY DEFECTS OR ERRORS WILL BE
              CORRECTED; (D) THE CONTENT OR SUBMISSIONS ARE FREE OF VIRUSES OR
              OTHER HARMFUL COMPONENTS; OR (E) THE RESULTS OF USING THE SERVICE,
              OR OBTAINING PRODUCTS THROUGH THE SERVICE, WILL MEET YOUR
              REQUIREMENTS. YOUR USE OF THE SERVICE IS SOLELY AT YOUR OWN RISK.
              NEITHER BENEFITS2 NOR ITS AGENTS, PARTNERS, SUPPLIERS OR CONTENT
              PROVIDERS MAKES ANY WARRANTIES OF ANY KIND IN REGARD TO ANY
              CONTENT, SUBMISSIONS, OPINIONS, ADVICE AND ALL OTHER INFORMATION
              EXPRESSED OR POSTED BY USERS OF THIS SITE.
            </Typography>
            <Typography variant="h5" gutterBottom>
              LIMITATION OF LIABILITY
            </Typography>
            <Typography variant="body1" gutterBottom>
              IN NO EVENT SHALL BENEFITS2, ITS OFFICERS, DIRECTORS, EMPLOYEES,
              AGENTS, OR SUPPLIERS BE LIABLE UNDER CONTRACT, TORT, STRICT
              LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL THEORY WITH RESPECT TO
              THE SITE, THE SERVICES OR ANY CONTENT OR PRODUCTS ACCESSIBLE
              THROUGH THE SITE AND SERVICE (I) FOR ANY LOST PROFITS OR SPECIAL,
              INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY
              KIND WHATSOEVER, SUBSTITUTE GOODS OR SERVICES (HOWEVER ARISING),
              (II) FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE (REGARDLESS
              OF THE SOURCE OF ORIGINATION), (III) FOR ANY ERRORS, OMISSIONS, OR
              INACCURACIES IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND
              INCURRED AS A RESULT OF YOUR USE OF ANY CONTENT OR SUBMISSIONS
              POSTED, EMAILED, TRANSMITTED OR OTHERWISE MADE AVAILABLE AT OR
              THROUGH THE SITE AND SERVICE. IN NO EVENT SHALL BENEFITS2
              AGGREGATE LIABILITY TO YOU OR ANY THIRD PARTY ARISING OUT OF THESE
              TERMS OF USE, THE SITE OR THE SERVICE EXCEED THE AMOUNT OF ONE
              HUNDRED CANADIAN DOLLARS. SOME JURISDICTIONS DO NOT ALLOW THE
              EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO
              THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU. YOU
              SPECIFICALLY ACKNOWLEDGE THAT BENEFITS2 SHALL NOT BE LIABLE FOR
              ANY SUBMISSIONS OR USER CONDUCT.
            </Typography>
            <Typography variant="h5" gutterBottom>
              INDEMNIFICATION
            </Typography>
            <Typography variant="body1" gutterBottom>
              You agree to defend, indemnify, and hold harmless Benefits2, its
              affiliates and their employees, contractors, officers, directors
              and representatives from all liabilities, claims, and expenses,
              including reasonable attorneys’ fees, that arise from your (i) use
              or misuse of the Service; (ii) your access to the Site, use of the
              Service, violation of the Terms of Use by you; or, (iii) the
              infringement by you, or any third party using your account, of any
              intellectual property or other right of any person or entity.
              Benefits2 reserves the right, at its own expense, to assume the
              exclusive defence and control of any matter otherwise subject to
              indemnification by you, in which event you will assist and
              cooperate with Benefits2 in asserting any available defences.
            </Typography>
            <Typography variant="h5" gutterBottom>
              DISPUTE RESOLUTION
            </Typography>
            <Typography variant="body1" gutterBottom>
              A printed version of these Terms of Use and of any notice given in
              electronic form shall be admissible in judicial or administrative
              proceedings based upon or relating to these Terms of Use to the
              same extent and subject to the same conditions as other business
              documents and records originally generated and maintained in
              printed form. You and Benefits2 agree that any cause of action
              arising out of or related to the Service must commence within one
              (1) year after the cause of action arose; otherwise, such cause of
              action is permanently barred. These Terms of Use shall be governed
              by and construed in accordance with the laws of Ontario, Canada.
              You expressly agree that the exclusive jurisdiction for any claim
              or action arising out of or relating to these Terms of Use or your
              use of this Site or Service shall be filed only in Ontario, Canada
              and you further agree and attorn to the exercise of personal
              jurisdiction of such courts for the purpose of litigating any such
              claim or action. Use of the Service is not authorized in any
              jurisdiction that does not give effect to all provisions of these
              Terms of Use, including without limitation, this section.
            </Typography>
            <Typography variant="h5" gutterBottom>
              INTEGRATION AND SEVERABILITY
            </Typography>
            <Typography variant="body1" gutterBottom>
              These Terms of Use are the entire agreement between you and
              Benefits2 with respect to the Service and use of this Site and
              supersede all prior or contemporaneous communications and
              proposals (whether oral, written, or electronic) between you and
              Benefits2 with respect to this Site. If any provision of the Terms
              of Use is found to be unenforceable or invalid, that provision
              will be limited or eliminated to the minimum extent necessary so
              that the Terms of Use will otherwise remain in full force and
              effect and enforceable.
            </Typography>
            <Typography variant="h5" gutterBottom>
              MISCELLANEOUS
            </Typography>
            <Typography variant="body1" gutterBottom>
              These Terms of Use and the Service contemplated hereunder are
              personal to you, and are not assignable, transferable, or
              sublicensable by you except with Benefits2 prior written consent.
              Benefits2 may assign, transfer or delegate any of its rights and
              obligations hereunder without consent. All waivers and
              modifications must be in a writing signed by both parties, except
              as otherwise provided herein. No agency, partnership, joint
              venture, or employment relationship is created because of these
              Terms of Use and neither party has any authority of any kind to
              bind the other in any respect. In any action or proceeding to
              enforce rights under these Terms of Use, the prevailing party will
              be entitled to recover costs and attorneys’ fees. All notices
              under these Terms of Use will be in writing and will be deemed to
              have been duly given when received, if personally delivered or
              sent by certified or registered mail, return receipt requested;
              when receipt is electronically confirmed, if transmitted by
              facsimile or e-mail; or the day after it is sent, if sent for next
              day delivery by recognized overnight delivery service.
            </Typography>
            <Typography variant="h5" gutterBottom>
              CONTACT
            </Typography>
            <Typography variant="body1" gutterBottom>
              Benefits2: 4521 Spruce Ave Burlington, Ontario L7L 1M5
            </Typography>
            <Typography variant="body1" gutterBottom>
              Email:{" "}
              <a href="mailto:privacy_officer@benefits2.ca">
                privacy_officer@benefits2.ca
              </a>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default TermsAndConditionsModal
