import AppPageCarded from "@app-core/AppPageCarded"
import React, {useEffect, useState} from "react"
import {ErrorModal} from "@app-core/ErrorModal"
import {useNavigate} from "react-router-dom"
import {LoadingScreen} from "@app-core/LoadingScreen"
import {useAuth} from "auth/AuthContext"
import {PaymentOption} from "components/PaymentOption"
import {paymentApi, productApi} from "data/api"
import {useEligibilityApplication} from "hooks/useEligibilityApplication"
import {useMessages} from "hooks/useMessages"
import {IProduct} from "models/IProduct"

export const ChoosePlanView: React.FC = () => {
  const {user} = useAuth()
  const {eligibilityApplication} = useEligibilityApplication()
  const navigate = useNavigate()
  const {addMessage} = useMessages()
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState<boolean>(false)
  const [products, setProducts] = useState<IProduct[]>([])

  if (!user || !eligibilityApplication) {
    navigate("/")
  }

  useEffect(() => {
    const loadProducts = async () => {
      try {
        const results = await productApi.getProducts()
        setProducts(results)
        setIsLoading(false)
      } catch (error) {
        setHasError(true)
      }
    }
    if (!user || !eligibilityApplication) {
      navigate("/")
    } else {
      loadProducts()
    }
  }, [eligibilityApplication, navigate, user])

  const onSelectPlan = async (plan: string) => {
    if (eligibilityApplication) {
      try {
        setIsLoading(true)

        const url = await paymentApi.createCheckoutSession({
          receipt_email: user?.email || "",
          selectedSections: eligibilityApplication?.diagnoses || [],
          applicationProcess: eligibilityApplication.applicationProcess,
          allowRepresentativeContact:
            eligibilityApplication.allowRepresentativeContact,
          plan,
          priceId: products.find((p) => p.planName === plan)?.priceId || ""
        })

        window.location.href = url
      } catch (error) {
        addMessage({
          content: "Something went wrong. Please try again.",
          severity: "error"
        })
        setIsLoading(false)
      }
    }
  }

  if (hasError) return <ErrorModal error="Issue Loading Plan Options" />
  if (isLoading) return <LoadingScreen isLoading={isLoading} />

  return (
    <AppPageCarded scroll="page">
      <PaymentOption onSelectPaymentOption={onSelectPlan} products={products} />
    </AppPageCarded>
  )
}

export default ChoosePlanView
