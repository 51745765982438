import {yupResolver} from "@hookform/resolvers/yup"
import {
  Paper,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material"
import _ from "lodash"
import React, {useEffect, useState} from "react"
import {LoadingScreen} from "@app-core/LoadingScreen"
import {Controller, useForm} from "react-hook-form"
import {useAuth} from "auth/AuthContext"
import {useNavigate} from "react-router-dom"
import jwtService from "auth/JwtService"
import * as yup from "yup"
import {authApi} from "data/api"
import {useMessages} from "hooks/useMessages"

interface PhysicianAccessState {
  appCode: string
  sendRequestBy: string
}

interface AccessCodeState {
  accessCode: string
}

const defaultValues: PhysicianAccessState = {
  appCode: "",
  sendRequestBy: "text"
}

const accessCodeDefaultValues: AccessCodeState = {
  accessCode: ""
}

const schema = yup.object().shape({
  appCode: yup.string().required("You must enter the application code")
})

const accessCodeSchema = yup.object().shape({
  accessCode: yup.string().required("You must enter the access code")
})

export const PhysicianAccessView: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [codeRequested, setCodeRequested] = useState(false)
  const {addMessage} = useMessages()
  const navigate = useNavigate()
  const {isAuthenticated} = useAuth()

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/")
    }
  }, [isAuthenticated, navigate])

  const {control, formState, handleSubmit, reset, getValues} =
    useForm<PhysicianAccessState>({
      mode: "onChange",
      defaultValues,
      resolver: yupResolver(schema)
    })

  const {
    control: accessCodeControl,
    formState: accessCodeFormState,
    handleSubmit: accessCodeHandleSubmit,
    reset: accessCodeReset
  } = useForm<AccessCodeState>({
    mode: "onChange",
    defaultValues: accessCodeDefaultValues,
    resolver: yupResolver(accessCodeSchema)
  })

  const {isValid, dirtyFields, errors} = formState
  const {
    isValid: accessCodeIsValid,
    dirtyFields: accessCodeDirtyFields,
    errors: accessCodeErrors
  } = accessCodeFormState

  const onSubmit = async ({appCode, sendRequestBy}: PhysicianAccessState) => {
    try {
      setLoading(true)
      await authApi.sendPhysicianAccessCode({code: appCode, by: sendRequestBy})
      setLoading(false)
      setCodeRequested(true)
      addMessage({
        content: "We've sent the a code for you to access the application",
        severity: "success"
      })
    } catch (error) {
      setLoading(false)
      addMessage({
        content: "Error during access request. Please try again",
        severity: "error"
      })
      reset(defaultValues)
    }
  }

  const onSubmitAccessCode = async ({accessCode}: AccessCodeState) => {
    try {
      setLoading(true)
      await jwtService.signInWithPhysicianAccessCode(
        accessCode,
        getValues().appCode
      )
    } catch (error) {
      setLoading(false)
      addMessage({
        content: "Error during request. Please try again",
        severity: "error"
      })
      accessCodeReset()
    }
  }

  const onImageClick = () => {
    navigate("/")
  }

  if (loading) {
    return <LoadingScreen isLoading={true} />
  }

  if (codeRequested) {
    return (
      <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
        <Paper className="w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
          <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
            <img
              style={{width: "375px", cursor: "pointer"}}
              src="/static/images/logo.png"
              alt="logo"
              onClick={onImageClick}
            />
            <Typography className="mt-32 text-3xl font-extrabold tracking-tight leading-tight">
              Access Code
            </Typography>
            <div className="flex items-baseline mt-2 font-medium">
              <Typography>
                Enter access code to view your patient&apos;s application
              </Typography>
            </div>

            <form
              name="registerForm"
              noValidate
              className="flex flex-col justify-center w-full mt-32"
              onSubmit={accessCodeHandleSubmit(onSubmitAccessCode)}
            >
              <Controller
                name="accessCode"
                control={accessCodeControl}
                render={({field}) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Access Code"
                    type="text"
                    error={!!accessCodeErrors.accessCode}
                    helperText={accessCodeErrors?.accessCode?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />

              <Button
                variant="contained"
                color="secondary"
                className=" w-full mt-4"
                aria-label="Register"
                disabled={
                  _.isEmpty(accessCodeDirtyFields) || !accessCodeIsValid
                }
                type="submit"
                size="large"
              >
                Login
              </Button>
            </form>
          </div>
        </Paper>
      </div>
    )
  }
  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
      <Paper className="w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img
            style={{width: "375px", cursor: "pointer"}}
            src="/static/images/logo.png"
            alt="logo"
            onClick={onImageClick}
          />
          <Typography className="mt-32 text-3xl font-extrabold tracking-tight leading-tight">
            View Patient Application
          </Typography>
          <div className="flex items-baseline mt-2 font-medium">
            <Typography>
              Fill the form to request access your patient&apos;s application
            </Typography>
          </div>

          <form
            name="registerForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="appCode"
              control={control}
              render={({field}) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Application Code"
                  type="text"
                  error={!!errors.appCode}
                  helperText={errors?.appCode?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <Controller
              name="sendRequestBy"
              control={control}
              render={({field}) => (
                <FormControl className="mb-24" fullWidth>
                  <InputLabel id="send-request-by-label">
                    Send request by:
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="send-request-by-label"
                    id="send-request-by"
                    label="Send request by:"
                  >
                    <MenuItem value="text">Text</MenuItem>
                    <MenuItem value="email">Email</MenuItem>
                    <MenuItem value="both">Text and Email</MenuItem>
                  </Select>
                </FormControl>
              )}
            />

            <Button
              variant="contained"
              color="secondary"
              className=" w-full mt-4"
              aria-label="Register"
              disabled={_.isEmpty(dirtyFields) || !isValid}
              type="submit"
              size="large"
            >
              Send request
            </Button>
          </form>
        </div>
      </Paper>
    </div>
  )
}

export default PhysicianAccessView
