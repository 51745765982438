import {Paper, Stack, Typography} from "@mui/material"
import Box from "@mui/material/Box"
import {styled} from "@mui/material/styles"
import React from "react"

const Item = styled(Paper)(({theme}) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  //   padding: theme.spacing(3),
  textAlign: "center",
  color: theme.palette.text.secondary,
  borderRadius: "27px",
  height: "100%"
}))

interface IStackWithLink {
  title: string
  description: string
  iconSrc: string
  iconAlt: string
  onClick: () => void
}
export const StackWithLink: React.FC<IStackWithLink> = (
  props: IStackWithLink
) => {
  const {title, description, iconSrc, iconAlt, onClick} = props

  return (
    <Item>
      <Stack
        direction="row"
        spacing={2}
        sx={{padding: "16px", cursor: "pointer"}}
        onClick={onClick}
      >
        <Box alignItems="flex-start">
          <img src={iconSrc} alt={iconAlt} />
        </Box>
        <div>
          <Typography
            sx={{
              textAlign: "left",
              color: "#6A75B9",
              fontSize: "20px"
            }}
            variant="h6"
            gutterBottom
            component="div"
          >
            {title} {">"}
          </Typography>
          {/* <LinkButton endIcon={<ChevronRightIcon />}>{title}</LinkButton> */}
          <Typography
            sx={{textAlign: "left", fontSize: "14px"}}
            variant="subtitle2"
            gutterBottom
            component="div"
          >
            {description}
          </Typography>
        </div>
      </Stack>
    </Item>
  )
}

export default StackWithLink
