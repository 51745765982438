import {Box, Typography} from "@mui/material"
import React from "react"
import {AppButton} from "@app-core/AppButton"

interface EligibilityStartViewProps {
  onStart: () => void
}

export const EligibilityStartView: React.FC<EligibilityStartViewProps> = (
  props: EligibilityStartViewProps
) => {
  const {onStart} = props

  return (
    <Box minHeight="600px" padding={4} display={"flex"} alignItems="center">
      <Box>
        <Box paddingX={6}>
          <Typography
            fontWeight="bold"
            variant="h4"
            gutterBottom
            component="div"
          >
            The following questions should take 2-3 minutes and will determine
            if you are likely to qualify for the Disability Tax Credit
          </Typography>
        </Box>
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent="center"
          marginTop={4}
          padding={4}
        >
          <AppButton
            onClick={onStart}
            sx={{
              backgroundColor: "#6A75B9",
              minWidth: "350px",
              minHeight: "44px"
            }}
            variant="contained"
          >
            Start
          </AppButton>
        </Box>
      </Box>
    </Box>
  )
}

export default EligibilityStartView
