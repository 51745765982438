import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  AxiosRequestConfig,
  AxiosHeaders
} from "axios"

type HttpHeaders = {
  [key: string]: string
}

type RequestConfig = {
  headers?: HttpHeaders
  timeout?: number
}

export interface IApiClient {
  post<TRequest, TResponse>(
    path: string,
    object: TRequest,
    config?: RequestConfig
  ): Promise<TResponse>
  patch<TRequest, TResponse>(
    path: string,
    object: TRequest,
    config?: RequestConfig
  ): Promise<TResponse>
  put<TRequest, TResponse>(
    path: string,
    object: TRequest,
    config?: RequestConfig
  ): Promise<TResponse>
  get<TResponse>(path: string): Promise<TResponse>
  getWithBodyFilter<TRequest, TResponse>(
    path: string,
    object: TRequest,
    config?: RequestConfig
  ): Promise<TResponse>
}

export interface IApiClientError {
  message: string
  name: string | undefined
  stack: string | undefined
}

export default class ApiClient implements IApiClient {
  private client: AxiosInstance

  protected createAxiosClient(baseURL: string): AxiosInstance {
    return Axios.create({
      baseURL: baseURL,
      responseType: "json" as const,
      headers: {
        "Content-Type": "application/json"
        // ...(apiConfiguration.accessToken && {
        //   Authorization: `Bearer  ${apiConfiguration.accessToken}`
        // })
      },
      timeout: 10 * 1000
    })
  }

  constructor(baseURL: string) {
    this.client = this.createAxiosClient(baseURL)
    this._initializeResponseInterceptor()
    this._initializeRequestInterceptor()
  }

  private _initializeResponseInterceptor = () => {
    this.client.interceptors.response.use(
      this._handleResponse,
      this._handleError
    )
  }

  private _initializeRequestInterceptor = () => {
    this.client.interceptors.request.use(this._handleRequest)
  }

  private _handleRequest = (config: AxiosRequestConfig) => {
    const token = sessionStorage.getItem("dtc_access_token")
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: token
      } as unknown as AxiosHeaders
    }

    return config
  }

  private _handleResponse = (response: AxiosResponse) => response

  protected _handleError = (error: AxiosError): Promise<IApiClientError> => {
    const errorObj = {
      message: error.message,
      name: error.code,
      stack: error.stack
    }
    return Promise.reject(errorObj)
  }

  async post<TRequest, TResponse>(
    path: string,
    payload: TRequest,
    config?: RequestConfig
  ): Promise<TResponse> {
    const response = config
      ? await this.client.post<TResponse>(path, payload, config)
      : await this.client.post<TResponse>(path, payload)
    return response.data
  }

  async patch<TRequest, TResponse>(
    path: string,
    payload: TRequest,
    config?: RequestConfig
  ): Promise<TResponse> {
    const response = await this.client.patch<TResponse>(path, payload)
    return response.data
  }

  async put<TRequest, TResponse>(
    path: string,
    payload: TRequest,
    config?: RequestConfig
  ): Promise<TResponse> {
    const response = config
      ? await this.client.put<TResponse>(path, payload, config)
      : await this.client.put<TResponse>(path, payload)
    return response.data
  }

  async get<TResponse>(path: string): Promise<TResponse> {
    const response = await this.client.get<TResponse>(path)
    return response.data
  }

  async getWithBodyFilter<TRequest, TResponse>(
    path: string,
    payload: TRequest
  ): Promise<TResponse> {
    const response = await this.client.get<TResponse>(path, {
      data: payload
    })
    return response.data
  }
}
