import {yupResolver} from "@hookform/resolvers/yup"
import ArrowLeftAltIcon from "@mui/icons-material/KeyboardBackspace"
import {Button, Paper, Typography, TextField} from "@mui/material"
import _ from "lodash"
import React from "react"
import {useAuth} from "auth/AuthContext"
import {useForm, Controller} from "react-hook-form"
import {supportApi} from "data/api"
import {Link} from "react-router-dom"
import {useMessages} from "hooks/useMessages"
import * as yup from "yup"
import {ISupportMsg} from "models/ISupportMsg"

const defaultValues: ISupportMsg = {
  name: "",
  email: "",
  subject: "",
  message: ""
}
const schema = yup.object().shape({
  name: yup.string().required("You must enter a name"),
  subject: yup.string().required("You must enter a subject"),
  message: yup.string().required("You must enter a message"),
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter a email")
})

export const SupportView: React.FC = () => {
  const {user} = useAuth()
  const {control, handleSubmit, watch, formState, reset} = useForm({
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
      email: user && user.email ? user.email : ""
    },
    resolver: yupResolver(schema)
  })
  const {isValid, dirtyFields, errors} = formState
  const form = watch()
  const {addMessage} = useMessages()

  const onSubmit = async (data: ISupportMsg) => {
    try {
      await supportApi.createSupportRequest(data)
      addMessage({
        severity: "success",
        content:
          "Support request submitted successfully. Your request will be processed and our support staff will get back to you in 2-3 business days"
      })
    } catch {
      addMessage({
        severity: "error",
        content: "Error sending support request. Please try again"
      })
    }
    reset()
  }

  if (_.isEmpty(form)) {
    return null
  }
  return (
    <div className="flex flex-col items-center sm:p-40 container">
      <div className="flex flex-col w-full max-w-4xl">
        <div className="sm:mt-32">
          <Button
            component={Link}
            to="/help-center"
            color="secondary"
            startIcon={<ArrowLeftAltIcon fontSize="small" color="secondary" />}
          >
            Back to Help Center
          </Button>
        </div>
        <div className="text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight">
          Contact support
        </div>

        <Paper className="sm:mt-48 p-24 pb-28 sm:p-40 sm:pb-28 rounded-2xl">
          <form onSubmit={handleSubmit(onSubmit)} className="px-0 sm:px-24">
            <div className="mb-24">
              <Typography className="text-2xl font-bold tracking-tight">
                Submit your request
              </Typography>
              <Typography color="text.secondary">
                Your request will be processed and our support staff will get
                back to you in 2-3 business days.
              </Typography>
            </div>
            <div className="space-y-32">
              <Controller
                control={control}
                name="name"
                render={({field}) => (
                  <TextField
                    className="w-full"
                    {...field}
                    label="Name"
                    placeholder="Name"
                    id="name"
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />

              <Controller
                control={control}
                name="email"
                render={({field}) => (
                  <TextField
                    {...field}
                    disabled={user ? true : false}
                    className="mt-16 w-full"
                    label="Email"
                    placeholder="Email"
                    variant="outlined"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    required
                  />
                )}
              />

              <Controller
                control={control}
                name="subject"
                render={({field}) => (
                  <TextField
                    {...field}
                    className="mt-16 w-full"
                    label="Subject"
                    placeholder="Subject"
                    variant="outlined"
                    fullWidth
                    error={!!errors.subject}
                    helperText={errors?.subject?.message}
                    required
                  />
                )}
              />

              <Controller
                name="message"
                control={control}
                render={({field}) => (
                  <TextField
                    {...field}
                    label="Message"
                    className="mt-16 w-full"
                    margin="normal"
                    multiline
                    minRows={4}
                    variant="outlined"
                    error={!!errors.message}
                    helperText={errors?.message?.message}
                    required
                  />
                )}
              />
            </div>
          </form>
          <div className="flex items-center justify-end mt-32">
            <Button component={Link} to="/help-center" className="mx-8">
              Cancel
            </Button>
            <Button
              className="mx-8"
              variant="contained"
              color="secondary"
              disabled={_.isEmpty(dirtyFields) || !isValid}
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </div>
        </Paper>
      </div>
    </div>
  )
}

export default SupportView
