import HelpIcon from "@mui/icons-material/Help"
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@mui/material"
import React, {useState} from "react"

export interface InfoModalProps {
  content: string
}

export const InfoModal: React.FC<InfoModalProps> = (props: InfoModalProps) => {
  const {content} = props
  const [open, setOpen] = useState<boolean>(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <HelpIcon sx={{color: "deepskyblue"}} />
      </IconButton>

      <Dialog fullWidth onClose={handleClose} open={open}>
        <DialogContent>
          <DialogContentText sx={{whiteSpace: "pre-wrap", lineHeight: "2.5"}}>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
