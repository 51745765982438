import {KeyboardArrowRight, KeyboardArrowLeft} from "@mui/icons-material"
import DownloadIcon from "@mui/icons-material/Download"
import EditIcon from "@mui/icons-material/Edit"
import FirstPageIcon from "@mui/icons-material/FirstPage"
import LastPageIcon from "@mui/icons-material/LastPage"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  Paper,
  Box,
  IconButton,
  useTheme
} from "@mui/material"
import {TablePaginationActionsProps} from "@mui/material/TablePagination/TablePaginationActions"
import {IDtcApplication} from "models/IDtcApplication"
import dayjs from "dayjs"
import React, {useState} from "react"

export interface IApplicationsTable {
  applications: IDtcApplication[]
  onEdit: (applicationCode: string) => void
  onViewPdf: (applicationCode: string) => void
  onDownloadPdf: (applicationCode: string) => void
}

const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const theme = useTheme()
  const {count, page, rowsPerPage, onPageChange} = props

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{flexShrink: 0, ml: 2.5}}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}
export const ApplicationsTable: React.FC<IApplicationsTable> = (
  props: IApplicationsTable
) => {
  const {applications, onEdit, onViewPdf, onDownloadPdf} = props
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - applications.length) : 0

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getDaysRemaining = (createdDate: string, status: string) => {
    if (status === "Completed") {
      return "-"
    }
    const now = dayjs()
    const expireAt = dayjs(createdDate).add(31, "day")
    const days = expireAt.diff(now, "day")

    if (days < 0) {
      return 0
    }

    return days
  }

  const getActionItems = (app: IDtcApplication) => {
    switch (app.status) {
      case "Completed":
        return (
          <div>
            <IconButton
              aria-label="download application pdf"
              onClick={() => onDownloadPdf(app.applicationCode || "")}
            >
              <DownloadIcon />
            </IconButton>
            <IconButton
              aria-label="view application pdf"
              onClick={() => onViewPdf(app.applicationCode || "")}
            >
              <PictureAsPdfIcon />
            </IconButton>
          </div>
        )
      case "In Progress":
      case "Generating Application PDF":
        if (getDaysRemaining(app.createdAt, app.status) === 0) {
          return ""
        }

        return (
          <IconButton
            aria-label="edit application"
            onClick={() => onEdit(app.applicationCode || "")}
          >
            <EditIcon />
          </IconButton>
        )
      default:
        break
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{minWidth: 500}} aria-label="applications pagination table">
        <TableHead>
          <TableRow>
            <TableCell variant="head">Patient Name</TableCell>
            <TableCell variant="head">Application Status</TableCell>
            <TableCell variant="head">Days Remaining to complete app</TableCell>
            <TableCell variant="head"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? applications.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : applications
          ).map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {`${row.patient?.firstName || "-"} ${
                  row.patient?.lastName || "-"
                }`}
              </TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>
                {getDaysRemaining(row.createdAt, row.status)}
              </TableCell>
              <TableCell>{getActionItems(row)}</TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{height: 53 * emptyRows}}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={3}
              count={applications.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page"
                },
                native: true
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export default ApplicationsTable
