import TextField from "@mui/material/TextField"
import {Box} from "@mui/system"
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import dayjs, {Dayjs} from "dayjs"
import React from "react"

export interface IYearDateRangePicker {
  onChange: (year: string) => void
  years: string
  disabled?: boolean
  error?: boolean
  errorText?: string
}

export const YearDateRangePicker: React.FC<IYearDateRangePicker> = (
  props: IYearDateRangePicker
) => {
  const {
    onChange,
    years,
    disabled = false,
    error = false,
    errorText = ""
  } = props
  const yearsArr = years.split(";")
  const fromValue: Dayjs | null = yearsArr[0]
    ? dayjs(`${yearsArr[0] || dayjs().year()}-01-01`)
    : null
  const toValue: Dayjs | null = yearsArr[1]
    ? dayjs(`${yearsArr[1] || dayjs().year()}-01-01`)
    : null

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display={"flex"} alignItems="baseline">
        <DatePicker
          disabled={disabled}
          views={["year"]}
          value={disabled ? null : fromValue}
          maxDate={toValue}
          onChange={(newValue) => {
            if (newValue && !newValue.isValid()) {
              return
            }
            if (newValue && newValue.isValid()) {
              const fromYear = dayjs(newValue).year().toString()
              if (toValue) {
                const toYear = toValue.year().toString()
                onChange(`${fromYear};${toYear}`)
                return
              }
              onChange(`${fromYear};`)
            } else {
              if (toValue) {
                const toYear = toValue.year().toString()
                onChange(`;${toYear}`)
                return
              }
              onChange("")
            }
          }}
          renderInput={(params) => (
            <TextField
              error={error}
              {...params}
              helperText={errorText}
              FormHelperTextProps={{error: error}}
            />
          )}
        />
        <Box marginX={"16px"}>to</Box>
        <DatePicker
          disabled={disabled}
          views={["year"]}
          value={disabled ? null : toValue}
          minDate={fromValue}
          onChange={(newValue) => {
            if (newValue && !newValue.isValid()) {
              return
            }
            if (newValue && newValue.isValid()) {
              const endYear = dayjs(newValue).year().toString()
              if (fromValue) {
                const fromYear = fromValue.year().toString()
                onChange(`${fromYear};${endYear}`)
                return
              }
              onChange(`;${endYear}`)
            } else {
              if (fromValue) {
                const fromYear = fromValue.year().toString()
                onChange(`${fromYear};`)
                return
              }
              onChange("")
            }
          }}
          renderInput={(params) => <TextField {...params} helperText={""} />}
        />
      </Box>
    </LocalizationProvider>
  )
}

export default YearDateRangePicker
