import {CardCvcElement} from "@stripe/react-stripe-js"
import React from "react"
import {StripeTextFieldProps, StripeTextField} from "./StripeTextField"

export const StripeTextFieldCVC = (
  props: StripeTextFieldProps<typeof CardCvcElement>
) => {
  return (
    <StripeTextField
      label="CVC Code"
      stripeElement={CardCvcElement}
      {...props}
    />
  )
}
