import {AppButton} from "@app-core/AppButton"
import BathroomImage from "assets/images/bathroom_small.svg"
import DressingImage from "assets/images/dressing_small.svg"
import EatingPreparingFoodImage from "assets/images/eatingPreparingFood_small.svg"
import {Box, Button, Typography} from "@mui/material"
import HearingImage from "assets/images/hearing_small.svg"
import React from "react"
import LifeSustainingTherapyImage from "assets/images/lifeSustainingTherapy_small.svg"
import MentalFunctionsImage from "assets/images/mentalFunctions_small.svg"
import ModerateImpairmentImage from "assets/images/moderateImpairment_small.svg"
import SpeakingImage from "assets/images/speaking_small.svg"
import VisionImage from "assets/images/vision_small.svg"
import WalkingImage from "assets/images/walking_small.svg"
import {camelCaseToTitleCase} from "utils/stripeHelper"

interface ApplicationSectionStartViewProps {
  onStart: () => void
  onCancel: () => void
  section: string
  numQuestions: number
}

export const ApplicationSectionStartView: React.FC<
  ApplicationSectionStartViewProps
> = (props: ApplicationSectionStartViewProps) => {
  const {onStart, section, numQuestions, onCancel} = props

  const setImageUri = () => {
    switch (section) {
      case "Hearing":
        return HearingImage
      case "Speaking":
        return SpeakingImage
      case "Vision":
        return VisionImage
      case "Walking":
        return WalkingImage
      case "Eliminating":
        return BathroomImage
      case "EatingPreparingFood":
        return EatingPreparingFoodImage
      case "Dressing":
        return DressingImage
      case "MentalFunctions":
        return MentalFunctionsImage
      case "LifeSustainingTherapy":
        return LifeSustainingTherapyImage
      case "ModerateImpairment":
        return ModerateImpairmentImage
      default:
        return ""
    }
  }
  return (
    <Box
      justifyContent={"center"}
      minHeight="600px"
      padding={4}
      display={"flex"}
      alignItems="center"
    >
      <Box>
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"center"}
          flexDirection="column"
        >
          <Box>
            <img
              width={"200px"}
              height={"200px"}
              src={setImageUri()}
              alt={section}
            />
          </Box>
        </Box>
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"center"}
          flexDirection="column"
          paddingX={6}
        >
          <Typography
            fontWeight="bold"
            variant="h6"
            gutterBottom
            component="div"
            fontSize="28px"
          >
            {`Let's discuss your ${camelCaseToTitleCase(section)}`}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"center"}
          flexDirection="column"
          paddingX={6}
        >
          <Typography
            fontWeight="bold"
            variant="h4"
            gutterBottom
            component="div"
            color="#6A75B9"
          >
            {`${numQuestions} Questions`}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent="center"
          marginTop={4}
          padding={4}
          flexDirection="column"
        >
          <AppButton
            onClick={onStart}
            sx={{
              backgroundColor: "#6A75B9",
              minWidth: "350px",
              minHeight: "44px"
            }}
            variant="contained"
          >
            Start
          </AppButton>
          <Button onClick={onCancel}>Back</Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ApplicationSectionStartView
