export const relationships = [
  "Spouse",
  "Common-law Partner",
  "Parent",
  "Grandparent",
  "Child",
  "Grandchild",
  "Brother",
  "Sister",
  "Uncle",
  "Aunt",
  "Nephew",
  "Niece"
]
