import {Box, GlobalStyles, Grid} from "@mui/material"
import MobileDetect from "mobile-detect"
import React from "react"
import {useNavigate} from "react-router-dom"
import ApplyImage from "assets/images/apply.svg"
import DataImage from "assets/images/data.svg"
import GroupImage from "assets/images/group.svg"
import PreviousImage from "assets/images/previous.svg"
import {StackWithLink} from "components/StackWithLink"

const md = new MobileDetect(window.navigator.userAgent)
const isMobile = md.mobile()

export const HomeView: React.FC = () => {
  const navigate = useNavigate()

  const onPreviousAppClick = () => {
    navigate("/previous-applications")
  }

  const onResourcesAppClick = () => {
    navigate("/help-center")
  }

  const onApplyClick = () => {
    navigate("/eligibility")
  }

  return (
    <div>
      <GlobalStyles
        styles={(theme) => ({
          ...{
            "#dtc-toolbar": {
              position: "static"
            },
            "#dtc-footer": {
              position: "static"
            }
          }
        })}
      />
      <Box sx={{flexGrow: 1, marginBottom: "60px"}}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <StackWithLink
              title="Begin Assessment"
              description="Take our free quick and easy pre-qualification test of “Yes” and “No” answers to see if you should Apply today!"
              iconSrc={ApplyImage}
              iconAlt="Begin Assessment"
              onClick={onApplyClick}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <StackWithLink
              title="Previous Applications"
              description="If you have already registered with us, search for your application here."
              iconSrc={PreviousImage}
              iconAlt="Previous Applications"
              onClick={onPreviousAppClick}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <StackWithLink
              title="Help Center"
              description="If you need more information about our work, you can continue here"
              iconSrc={DataImage}
              iconAlt="Resources"
              onClick={onResourcesAppClick}
            />
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" justifyContent="center">
        <img className="max-w-full h-auto" src={GroupImage} alt="Group" />
      </Box>
    </div>
  )
}

export default HomeView
