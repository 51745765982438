import React, {Suspense} from "react"
import {LoadingScreen} from "@app-core/LoadingScreen"

interface IAppSuspense {
  children: React.ReactNode
}

export const AppSuspense: React.FC<IAppSuspense> = (props: IAppSuspense) => {
  return <Suspense fallback={<LoadingScreen />}>{props.children}</Suspense>
}

export default AppSuspense
