import AppPageCarded from "@app-core/AppPageCarded"
import {ErrorModal} from "@app-core/ErrorModal"
import {LoadingScreen} from "@app-core/LoadingScreen"
import {useAuth} from "auth/AuthContext"
import {ApplicationCompleted} from "components/ApplicationCompleted"
import {ApplicationIndividualForm} from "components/ApplicationIndividualForm"
import {ApplicationQuestions} from "components/ApplicationQuestions"
import React, {useEffect, useState} from "react"
import {applicationApi, questionApi} from "data/api"
import {useLocation, useNavigate} from "react-router-dom"
import {useMessages} from "hooks/useMessages"
import {IDtcApplication} from "models/IDtcApplication"
import {IErrorObj} from "models/IErrorObj"
import {IFlier} from "models/IFlier"
import {IPatient} from "models/IPatient"
import {IQuestion} from "models/IQuestion"
import {IQuestionAnswer} from "models/IQuestionAnswer"

export const ApplicationView: React.FC = () => {
  const {user, isPhysicianUser} = useAuth()
  const navigate = useNavigate()
  const {addMessage} = useMessages()
  const [appSections, setAppSections] = useState<string[]>(["PatientInfo"])
  const [currentSection, setCurrentSection] = useState<string>(appSections[0])
  const [dtcApplication, setDtcApplication] = useState<IDtcApplication | null>()
  const [appQuestions, setAppQuestions] = useState<IQuestion[] | null>()
  const [errorObj, setErrorObj] = useState({hasError: false, message: ""})
  const [isCompleted, setIsCompleted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [appGenerated, setAppGenerated] = useState<boolean>(false)
  const [submitErrorMsg, setSubmitErrorMsg] = useState<string>("")
  const location = useLocation()

  useEffect(() => {
    const loadApplication = async () => {
      if (dtcApplication) {
        return
      }
      const code = new URLSearchParams(location.search).get("code")

      try {
        if (code && !dtcApplication) {
          const dtcApp = await applicationApi.getApplication(code)

          if (dtcApp) {
            const questions = await questionApi.getQuestions({
              sections: dtcApp.selectedSections
            })

            setAppSections([...appSections, ...dtcApp.selectedSections])
            setAppQuestions(questions)
            setDtcApplication(dtcApp)
          } else {
            setErrorObj({
              hasError: true,
              message: "Issue Loading DTC Application"
            })
          }
        } else {
          setErrorObj({
            hasError: true,
            message: "Issue Loading DTC Application"
          })
        }
      } catch (e) {
        setErrorObj({
          hasError: true,
          message: "Issue Loading DTC Application"
        })
      }
    }

    if (!user && !isPhysicianUser) {
      navigate("/")
    }

    loadApplication()
  }, [appSections, dtcApplication, location, navigate, user, isPhysicianUser])

  if (errorObj.hasError) return <ErrorModal error={errorObj.message} />
  if (dtcApplication === undefined || loading)
    return <LoadingScreen isLoading={true} label="Please Wait..." />

  const updateCurrentSection = (index: number) => {
    if (index === appSections.length) {
      setIsCompleted(true)
      return
    }

    if (index < 0) {
      return
    }

    setCurrentSection(appSections[index])
  }
  const onIndividualFormCompleted = (patient: IPatient, flier: IFlier) => {
    if (!dtcApplication) {
      return
    }
    setDtcApplication({
      ...dtcApplication,
      patient: patient,
      flier: flier
    })
    const currentIndex = appSections.findIndex((q) => q === currentSection)
    updateCurrentSection(currentIndex + 1)
  }
  const saveSectionAnswers = async (answers: IQuestionAnswer[]) => {
    try {
      if (dtcApplication) {
        setLoading(true)

        const currentAnswers = (dtcApplication && dtcApplication.answers) || []

        const withOutCurrentSection = currentAnswers.filter(
          (qa) => qa.question.section !== currentSection
        )

        dtcApplication.answers = [...withOutCurrentSection, ...answers]

        const res = await applicationApi.updateDtcApplication({dtcApplication})

        if (res) {
          addMessage({
            severity: "success",
            content: "Application section saved successfully"
          })

          // const currentAnswers =
          //   (dtcApplication && dtcApplication.answers) || []

          // const withOutCurrentSection = currentAnswers.filter(
          //   (qa) => qa.question.section !== currentSection
          // )

          // dtcApplication.answers = [...withOutCurrentSection, ...answers]

          setDtcApplication(dtcApplication)
          const currentIndex = appSections.findIndex(
            (q) => q === currentSection
          )
          updateCurrentSection(currentIndex + 1)
          setLoading(false)
        } else {
          setSubmitErrorMsg(
            "Error saving DTC application section. Please try again"
          )
          setLoading(false)
        }
      }
    } catch (error: unknown) {
      const r = error as IErrorObj
      setSubmitErrorMsg(
        r.message || "Error saving DTC application section. Please try again"
      )
      setLoading(false)
    }
  }

  const onBackClick = () => {
    if (!currentSection) {
      return
    }
    const currentIndex = appSections.findIndex((q) => q === currentSection)

    updateCurrentSection(currentIndex - 1)
  }

  const onSubmitApplication = async () => {
    try {
      if (dtcApplication) {
        setLoading(true)
        const res = await applicationApi.completeDtcApplication({
          applicationCode: dtcApplication.applicationCode || "",
          physicianEdit: isPhysicianUser
        })

        if (res) {
          if (dtcApplication.plan !== "AssistanceRequired") {
            setTimeout(() => {
              navigate(`/application-viewer/${dtcApplication.applicationCode}`)
            }, 2500)
          } else {
            setAppGenerated(true)
            setLoading(false)
          }
        } else {
          setSubmitErrorMsg(
            "Error generating DTC application. Please try again"
          )
          setLoading(false)
        }
      }
    } catch (error: unknown) {
      setSubmitErrorMsg("Error generating DTC application. Please try again")
      setLoading(false)
    }
  }

  const onEmailApp = async () => {
    try {
      if (dtcApplication && dtcApplication.plan === "AssistanceRequired") {
        setLoading(true)
        await applicationApi.emailDtcApplication({
          applicationCode: dtcApplication.applicationCode || ""
        })
        setLoading(false)
        addMessage({
          severity: "success",
          content: "Application has been emailed successfully"
        })
      }
    } catch (error: unknown) {
      setSubmitErrorMsg("Error emailing DTC application. Please try again")
      setLoading(false)
    }
  }

  const onSignOut = () => {
    navigate("/sign-out")
  }

  const onPrevSectionClick = () => {
    const currentIndex = appSections.findIndex((q) => q === currentSection)
    updateCurrentSection(currentIndex - 1)
  }

  const onNextSectionClick = () => {
    const currentIndex = appSections.findIndex((q) => q === currentSection)
    updateCurrentSection(currentIndex + 1)
  }

  const canClickPrevSection = () => {
    const currentIndex = appSections.findIndex((q) => q === currentSection)
    if (currentIndex - 1 === 0) {
      return false
    }
    return true
  }

  const canClickNextSection = () => {
    const currentIndex = appSections.findIndex((q) => q === currentSection)
    if (currentIndex + 1 === appSections.length) {
      return false
    }
    return true
  }
  const SectionContent = ({step}: {step: string}) => {
    switch (step) {
      case "PatientInfo":
        return (
          <ApplicationIndividualForm
            onNext={onIndividualFormCompleted}
            patient={dtcApplication?.patient}
            flier={dtcApplication?.flier}
          />
        )
      default:
        return (
          <ApplicationQuestions
            questions={appQuestions?.filter((aq) => aq.section === step) || []}
            onNext={saveSectionAnswers}
            onBack={onBackClick}
            questionAnswers={
              dtcApplication?.answers?.filter(
                (qa) => qa.question.section === step
              ) || []
            }
            onPrevSection={onPrevSectionClick}
            onNextSection={onNextSectionClick}
            canPrevSection={canClickPrevSection()}
            canNextSection={canClickNextSection()}
          />
        )
    }
  }

  return (
    <AppPageCarded scroll="page">
      {isCompleted && (
        <ApplicationCompleted
          onSubmitApplication={onSubmitApplication}
          onCancel={() => setIsCompleted(false)}
          errorMsg={submitErrorMsg}
          plan={dtcApplication?.plan || ""}
          onEmailApp={onEmailApp}
          appGenerated={appGenerated}
          walkthroughUser={
            user?.roles?.some((r) => r === "WalkThroughAdmin") || false
          }
          onSignOut={onSignOut}
        />
      )}
      {!isCompleted && <SectionContent step={currentSection} />}
    </AppPageCarded>
  )
}

export default ApplicationView
